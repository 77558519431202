/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  DataGrid,
  DataGridFilter,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  DataGridStatusToggleSortableBehavior,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
  IDataGridStatusToggleSortableBehaviorProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { GridClickEvent } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { Overlay } from '@seeeverything/ui.primitives/src/components/Overlay/Overlay.tsx';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import moment from 'moment';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';
import { distributionListSlice } from '../../redux/distributionList/index.ts';
import { DistributionListItem } from '../../redux/distributionList/types.ts';
import { editDistributionListSlice } from '../../redux/editDistributionList/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { DistributionListEditPanelContainer } from './components/DistributionListEditPanelContainer.tsx';
import { DistributionListStatusBarType } from './components/DistributionListStatusBar.tsx';

const COLUMNS: IDataGridColumn[] = [
  {
    id: 'createdAt',
    label: 'Created',
    width: 100,
    isFilterable: false,
    isSortable: true,
  },
  {
    id: 'name',
    label: 'List Name',
    width: '*',
    isFilterable: false,
    isSortable: true,
  },
  {
    id: 'details',
    label: 'Details',
    width: '*',
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    width: 160,
    isFilterable: true,
    isSortable: false,
    filterToggles: [
      {
        id: 'FILTER_SHOW_INACTIVE',
        columnId: 'status',
        label: 'Include Inactive',
        isToggled: false,
      },
    ],
  },
  {
    id: 'lastUpdated',
    label: 'Last Updated By',
    width: 200,
    isFilterable: false,
    isSortable: false,
  },
];

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridStatusToggleSortableBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridInfiniteScrollBehaviorProps &
    IDataGridStatusToggleSortableBehaviorProps &
    IDataGridProps
>;

export const DistributionListGridContainer: React.FC = () => {
  const dispatch = useFormsDispatch();

  const draft = useFormsSelector(
    (state) => state.formEditDistributionList.draft,
  );
  const orderBy = useFormsSelector(
    (state) => state.formDistributionList.orderBy,
  );

  const showAll = useFormsSelector(
    (state) => state.formDistributionList.showAll,
  );

  const globalError = useFormsSelector(
    (state) => state.formEditDistributionList.errors?.globalError,
  );
  const hasNextPage = useFormsSelector(
    (state) => state.formDistributionList.hasNextPage,
  );
  const isLoading = useFormsSelector(
    (state) => state.formDistributionList.isLoading,
  );
  const isLoadingMore = useFormsSelector(
    (state) => state.formDistributionList.isLoadingMore,
  );
  const loadedPage = useFormsSelector(
    (state) => state.formDistributionList.currentPage,
  );
  const original = useFormsSelector(
    (state) => state.formEditDistributionList.original,
  );

  const draftSaved = useFormsSelector(
    (state) => state.formEditDistributionList.saved,
  );

  const distributionLists = useFormsSelector(
    (state) => state.formDistributionList.listItems ?? [],
  );

  const isDistributionListSaving = useFormsSelector(
    (state) => state.formEditDistributionList.isSaving,
  );

  const filters = useMemo<DataGridFilter[]>(
    () => [
      {
        columnId: orderBy.fieldName,
        sort: orderBy.direction === 'Ascending' ? 'ASC' : 'DESC',
      },
      {
        columnId: 'status',
        toggles: [
          {
            id: 'FILTER_SHOW_INACTIVE',
            columnId: 'status',
            label: 'Show Inactive',
            isToggled: showAll,
          },
        ],
      },
    ],
    [showAll, orderBy],
  );

  const gridRowData = useMemo(
    () =>
      distributionLists.map((listItem) => ({
        id: listItem.id,
        data: [
          listItem.created
            ? moment(listItem.created).format('D MMM YY').toString()
            : '-',
          listItem.listName,
          getGridRowDetail(listItem),
          listItem.status,
          listItem.lastUpdatedBy,
          listItem.lastUpdatedOn,
        ],
      })),
    [distributionLists],
  );

  const status = useMemo((): DistributionListStatusBarType => {
    if (isDistributionListSaving) return 'SAVING';
    if (globalError) return 'ERROR';
    return 'INFO';
  }, [globalError, isDistributionListSaving]);

  const statusMessage = useMemo(() => {
    if (status === 'ERROR') return globalError;
    if (status === 'SAVING') return 'Saving...';

    if (draftSaved) return 'Save Successful.';

    if (original?.id === undefined) return undefined;
    if (original.canSave) return 'This distribution list is active.';

    return 'This distribution list is Inactive.';
  }, [draftSaved, status, original, globalError]);

  const handleEditDistributionList = useCallback(
    ({ rowId }: GridClickEvent) => {
      if (!distributionLists) return;

      const list = distributionLists.find((item) => item.id === rowId);
      if (!list) return;

      dispatch(editDistributionListSlice.editDistributionList(list));
    },
    [dispatch, distributionLists],
  );

  const handleEditPanelClickedOutside = useCallback(() => {
    dispatch(
      editDistributionListSlice.showAlertDialogOnClickOutsideDistributionList(),
    );
  }, [dispatch]);

  const handleColumnSort = useCallback(
    (columnId: string, direction: 'ASC' | 'DESC') =>
      dispatch(
        distributionListSlice.sortDistributionLists({
          fieldName: columnId,
          direction: direction === 'ASC' ? 'Ascending' : 'Descending',
        }),
      ),
    [dispatch],
  );

  const handleToggleShowInactive = useCallback(
    (columnId: string, toggleId: string, to: boolean) => {
      if (columnId !== 'status') return;
      if (toggleId !== 'FILTER_SHOW_INACTIVE') return;

      dispatch(
        distributionListSlice.showInactiveDistributionLists({
          showAll: to,
        }),
      );
    },
    [dispatch],
  );

  const handleLoadMore = useCallback(
    () =>
      dispatch(
        distributionListSlice.loadDistributionLists({
          loadNextPage: true,
        }),
      ),
    [dispatch],
  );

  const elLoadingOverlay = isLoading && (
    <div css={styles.loadingOverlay}>
      <Spinner />
    </div>
  );

  const elOverlay = draft && (
    <OutsideAlerter
      onClickedOutside={handleEditPanelClickedOutside}
      style={styles.outsideAlerter}
    >
      <DistributionListEditPanelContainer
        isSaving={isDistributionListSaving}
        status={status}
        statusMessage={statusMessage}
      />
    </OutsideAlerter>
  );

  return (
    <Overlay overlay={elOverlay} isVisible={Boolean(elOverlay)}>
      {elLoadingOverlay}
      <Grid
        id={'DistributionLists'}
        columns={COLUMNS}
        data={gridRowData}
        filters={filters}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isLoadingPage={isLoadingMore}
        isScrollable={true}
        isVirtualized={true}
        loadedPage={loadedPage}
        onClick={handleEditDistributionList}
        onColumnStatusToggled={handleToggleShowInactive}
        onColumnSort={handleColumnSort}
        onLoadPage={handleLoadMore}
      />
    </Overlay>
  );
};

const getGridRowDetail = (listItem: DistributionListItem) => {
  const teamRuleDetails = getTeamRuleDetails(listItem).trim();
  const positionTitleRuleDetails = getPositionTitleRuleDetail(listItem).trim();

  return listItem.type === 'Team'
    ? teamRuleDetails
    : `${teamRuleDetails}${
        teamRuleDetails.length && positionTitleRuleDetails ? ';' : ''
      } ${positionTitleRuleDetails}`;
};

const getPositionTitleRuleDetail = ({
  ruleExcludePositionTitles,
  ruleIncludePositionTitles,
  ruleIncludePositionTitlesContaining,
  ruleIncludePositionTitlesNotContaining,
}: DistributionListItem) => {
  const excludePositionTitlesDetail = ruleExcludePositionTitles?.join(', ');
  const includePositionTitlesDetail = ruleIncludePositionTitles?.join(', ');
  const includePositionTitlesContainingDetail =
    ruleIncludePositionTitlesContaining?.join(', ');
  const includePositionTitlesNotContainingDetail =
    ruleIncludePositionTitlesNotContaining?.join(', ');

  const excludePositionTitlesDetailFull = excludePositionTitlesDetail
    ? `Position title is not ${excludePositionTitlesDetail} ${
        includePositionTitlesDetail ||
        includePositionTitlesContainingDetail ||
        includePositionTitlesNotContainingDetail
          ? ';'
          : ''
      }`
    : '';
  const includePositionTitlesDetailFull = includePositionTitlesDetail
    ? `Position title is ${includePositionTitlesDetail} ${
        includePositionTitlesContainingDetail ||
        includePositionTitlesNotContainingDetail
          ? ';'
          : ''
      }`
    : '';
  const includePositionTitlesContainingDetailFull =
    includePositionTitlesContainingDetail
      ? `Position title contains ${includePositionTitlesContainingDetail} ${
          includePositionTitlesNotContainingDetail ? ';' : ''
        }`
      : '';
  const includePositionTitlesNotContainingDetailFull =
    includePositionTitlesNotContainingDetail
      ? `Position title does not contain ${includePositionTitlesNotContainingDetail}`
      : '';

  return `${excludePositionTitlesDetailFull} ${includePositionTitlesDetailFull} ${includePositionTitlesContainingDetailFull} ${includePositionTitlesNotContainingDetailFull}`;
};

const getTeamRuleDetails = ({
  ruleExcludeTeams,
  ruleIncludeTeams,
  ruleIncludeTeamsBelow,
  type,
}: DistributionListItem) => {
  const excludeTeamsDetail = ruleExcludeTeams
    ?.map((team) => team.name)
    .join(' and ');
  const includeTeamsDetail = ruleIncludeTeams
    ?.map((team) => team.name)
    .join(' or ');
  const includeTeamsBelowDetail = ruleIncludeTeamsBelow
    ?.map((team) => `${team.name} at Level ${team.hierarchyLevel}`)
    .join(' and ');

  const includeTeamsDetailFull = includeTeamsDetail
    ? `${type === 'Person' ? 'People in ' : ''} ${includeTeamsDetail} ${
        excludeTeamsDetail || includeTeamsBelowDetail ? ';' : ''
      }`
    : '';
  const excludeTeamDetailsFull = excludeTeamsDetail
    ? `Exclude ${excludeTeamsDetail} ${includeTeamsBelowDetail ? ';' : ''}`
    : '';
  const includeTeamsBelowDetailFull = includeTeamsBelowDetail
    ? `Teams below ${includeTeamsBelowDetail}`
    : '';

  return `${includeTeamsDetailFull} ${excludeTeamDetailsFull} ${includeTeamsBelowDetailFull}`;
};

const styles = {
  loadingOverlay: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: color.format(0.8),
    zIndex: 1,
  }),
  outsideAlerter: css({
    overflow: 'hidden',
    position: 'absolute',
    inset: 0,
  }),
};
