/** @jsxImportSource @emotion/react */
import { viewEntity } from '@seeeverything/ui.dashboards/src/redux/actions.ts';
import { BulkUploadColumns } from '@seeeverything/ui.forms/src/components/BulkUploadJobsGrid/types.ts';
import {
  loadClickThrough,
  loadMore,
  orderByChanged,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/click-through/actions.ts';
import { IBulkUploadCreatedInstance } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/click-through/types.ts';
import { FormsBulkUploadValidateFileUploadRow } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { BulkUploadFileResults } from '../BulkUploadFileResults/BulkUploadFileResults.tsx';
import { SheetError } from '../SheetError/SheetError.tsx';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';
import { SucceededClickThroughGrid } from './components/SucceededClickThroughGrid.tsx';

export const SheetBulkUploadClickThroughGridContainer: React.FC = () => {
  const dispatch = useShellDispatch();
  const hasNextPage = useShellSelector(
    (state) => state.formBulkUpload.clickThrough?.pagination?.hasNextPage,
  );
  const createdInstances = useShellSelector((state) => {
    const type = state.formBulkUpload.clickThrough?.type;
    const instances = state.formBulkUpload.clickThrough?.instances;
    return type === BulkUploadColumns.SUCCEEDED
      ? (instances as IBulkUploadCreatedInstance[])
      : undefined;
  });

  const failedRows = useShellSelector((state) => {
    const type = state.formBulkUpload.clickThrough?.type;
    const instances = state.formBulkUpload.clickThrough?.instances;

    if (type === BulkUploadColumns.SUCCEEDED) return [];
    return (instances as FormsBulkUploadValidateFileUploadRow[]) ?? [];
  });

  const isLoading = useShellSelector(
    (state) => state.formBulkUpload.clickThrough?.isLoading ?? false,
  );
  const isLoadingMore = useShellSelector(
    (state) => state.formBulkUpload.clickThrough?.isLoadingMore,
  );
  const jobId = useShellSelector(
    (state) => state.formBulkUpload.clickThrough?.jobId ?? '',
  );
  const loadedPage = useShellSelector(
    (state) => state.formBulkUpload.clickThrough?.pagination?.currentPage ?? 1,
  );
  const loadError = useShellSelector(
    (state) => state.formBulkUpload.clickThrough?.errors?.load,
  );
  const loadMoreText = useShellSelector(
    (state) =>
      state.formBulkUpload.clickThrough?.errors?.loadMore ?? 'Load more',
  );
  const orderBy = useShellSelector(
    (state) => state.formBulkUpload.clickThrough?.orderBy,
  );
  const type = useShellSelector(
    (state) =>
      state.formBulkUpload.clickThrough?.type ?? BulkUploadColumns.SUCCEEDED,
  );

  const chips = useShellSelector((state) => state.query.query.chips);
  const title = useMemo(() => {
    if (!chips?.length) return;
    return chips[chips.length - 1]?.label;
  }, [chips]);

  const locale = useShellSelector((state) => state.tenantState.tenant?.locale);

  const handleColumnSort = useCallback(
    (columnId: string, direction: 'ASC' | 'DESC') => {
      dispatch(
        orderByChanged({
          fieldName: columnId,
          direction: direction === 'ASC' ? 'Ascending' : 'Descending',
        }),
      );
    },
    [dispatch],
  );

  const handleLoadMore = useCallback(() => dispatch(loadMore()), [dispatch]);

  const handleInstanceClick = useCallback(
    (instanceId: string) => {
      const instanceName = createdInstances?.find(
        (instance) => instanceId === instance.id,
      )?.templateName;

      dispatch(viewEntity('', instanceId, 'Form', instanceName));
    },
    [createdInstances, dispatch],
  );

  const handleErrorClicked = useCallback(
    () => dispatch(loadClickThrough(jobId, type)),
    [dispatch, jobId, type],
  );

  if (loadError)
    return (
      <SheetError
        icon={'refresh'}
        message={loadError}
        onClick={handleErrorClicked}
        showIcon={true}
      />
    );

  return (
    <SheetLayout
      title={title}
      rightTools={
        type === BulkUploadColumns.FAILED
          ? [
              {
                id: 'DOWNLOAD_BULK_UPLOAD_JOB_FAILURES',
                icon: Icons.fileDownload,
                label: 'Download Failures',
              },
            ]
          : undefined
      }
    >
      {type === BulkUploadColumns.SUCCEEDED ? (
        <SucceededClickThroughGrid
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          loadedPage={loadedPage}
          loadMoreText={loadMoreText}
          onColumnSort={handleColumnSort}
          onLoadMore={handleLoadMore}
          orderBy={orderBy}
          createdInstances={createdInstances}
          onInstanceNameClick={handleInstanceClick}
        />
      ) : (
        <BulkUploadFileResults
          showFilters={false}
          rows={failedRows}
          orderBy={orderBy}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          loadedPage={loadedPage}
          loadMoreText={loadMoreText}
          onColumnSort={handleColumnSort}
          onLoadMore={handleLoadMore}
          locale={locale}
        />
      )}
    </SheetLayout>
  );
};
