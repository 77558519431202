import { Observable, mergeMap, filter, map } from 'rxjs';
import { isAnyOf } from '@reduxjs/toolkit';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { GlobalAppState, GlobalAppEpicDependencies } from '../../../types.ts';
import { formInstanceAuditLog } from '../../../data/data.formInstanceAuditLog.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ISheetToolbarClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { auditSlice } from '@seeeverything/ui.shell/src/redux/audit/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(loadAuditLogEpic, refreshAuditLogEpic);

function loadAuditLogEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalAppState>,
  { client }: GlobalAppEpicDependencies,
) {
  return action$.pipe(
    filter(isAnyOf(auditSlice.load.match, auditSlice.reload.match)),
    mergeMap(async (action) => {
      const { instanceId } = action.payload;
      const locale = state$.value.tenantState.tenant?.locale;

      try {
        const logEntries = await formInstanceAuditLog(
          client,
          instanceId,
          locale,
        );

        return auditSlice.loaded({ instanceId, logEntries });
      } catch (err) {
        log.error(err);
        return auditSlice.loadError({ instanceId });
      }
    }),
  );
}

function refreshAuditLogEpic(
  action$: Observable<ISheetToolbarClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'AUDIT_LOG_REFRESH'),
    map(() => auditSlice.reload({ instanceId: state$.value.audit.instanceId })),
  );
}
