import {
  IQueryData,
  IEntityDictionary,
  IQueryRoutes,
  IChipFormatters,
  QueryDropdownFactory,
  IQueryDropdownProps,
  QueryDataChangeEvent,
  FormatQueryChipEvent,
  IQuery,
  IQueryRoute,
  IQueryDropdownElement,
  IQueryResponseChange,
  IQueryRequest,
  IQueryResponse,
  IDropdown,
  IChipFormatter,
  IChipDisplayOptions,
  IFormatChipRequest,
  IFormatChipResponse,
} from '../types.ts';
import { isPathMatch } from './util/url.ts';

/**
 * The API for accessing the QueryBuilder data model.
 */
export class QueryData implements IQueryData {
  public entities: IEntityDictionary = {};
  public routes: IQueryRoutes = {};
  public chipFormatters: IChipFormatters = {};
  public dropdownFactories: QueryDropdownFactory[] = [];

  /**
   * Retrieves a dropdown component from the registered factories.
   */
  public dropdownElement = (props: IQueryDropdownProps) =>
    createDropdownElement(props, this.dropdownFactories);

  /**
   * Requests a dropdown for the given query.
   */
  public change(event: QueryDataChangeEvent) {
    const routes = this.findRoutes(event.query);
    return routes ? onChange(event, routes, this.entities) : undefined;
  }

  /**
   * Requests formatting to be applied to the given chip type.
   */
  public formatChip(event: FormatQueryChipEvent) {
    const formatter = this.chipFormatters[event.chip.type];
    return formatter
      ? onFormatChip(event, formatter, this.entities)
      : undefined;
  }

  /**
   * Find the first route handler that matches the given query.
   */
  public findRoute = (query: IQuery): IQueryRoute | undefined =>
    Object.keys(this.routes)
      .map((key) => this.routes[key])
      .find((route) => isPathMatch(route.pathPattern, query));

  /**
   * Find all route handlers that match the given query.
   */
  public findRoutes = (query: IQuery): IQueryRoute[] =>
    Object.keys(this.routes)
      .map((key) => ({ ...this.routes[key], key }))
      .filter((route) => isPathMatch(route.pathPattern, query));
}

function createDropdownElement(
  props: IQueryDropdownProps,
  factories: QueryDropdownFactory[],
) {
  for (const factory of factories) {
    const el = factory(props);
    if (el) {
      return el as IQueryDropdownElement;
    }
  }
  return undefined;
}

function onChange(
  event: QueryDataChangeEvent,
  routes: IQueryRoute[],
  entities: IEntityDictionary,
) {
  const { query, selection, action, context } = event;
  const result: IQueryResponseChange = {
    isCancelled: false,
    routes: [],
  };
  const request: IQueryRequest = {
    action,
    isHandled: false,
    query,
    selection,
    entities,
    context,
  };
  const handled = () => (request.isHandled = true);
  const response: IQueryResponse = {
    cancel() {
      result.isCancelled = true;
      handled();
      return response;
    },
    dropdown(dropdown?: IDropdown) {
      if (dropdown) {
        result.dropdown = dropdown;
        handled();
      }
      return response;
    },
    query(queryState?: IQuery) {
      if (queryState) {
        result.query = { ...queryState };
        handled();
      }
      return response;
    },
    editingChip(index: number) {
      if (index >= 0) {
        result.editingChip = { index };
        handled();
      }
      return response;
    },
  };

  // Invoke the handler(s) for each route.
  let count = 0;
  routes.forEach(({ key, handlers$ }) => {
    result.routes.push(key);
    handlers$.forEach((handler$) => {
      handler$.next({ req: { ...request }, res: { ...response } });
      count += 1;
    });
  });

  return count > 0 ? result : undefined;
}

function onFormatChip(
  event: FormatQueryChipEvent,
  formatter: IChipFormatter,
  entities: IEntityDictionary,
): IChipDisplayOptions | undefined {
  let result: IChipDisplayOptions | undefined;
  const request: IFormatChipRequest = {
    ...event,
    entities,
  };
  const response: IFormatChipResponse = {
    format(options: IChipDisplayOptions) {
      result = options;
    },
  };

  // Invoke the handler(s) for each formatter.
  formatter.handlers$.forEach((handler$) => {
    handler$.next({ req: { ...request }, res: { ...response } });
  });

  return result;
}
