/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Image } from '@seeeverything/ui.primitives/src/components/Image/Image.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import React from 'react';
import { Input } from './components/Input.tsx';
import { Next } from './components/Next.tsx';

export interface ITenantSelectionProps {
  tenants?: string[];
  input?: ISelectionListItem;

  rememberToggleChecked?: boolean;
  configs: Record<string, TenantConfiguration>;

  onInputChanged?: (to: ISelectionListItem) => void;
  onToggleChanged?: (to: boolean) => void;
  onNextClicked?: () => void;
}

const DEFAULT_CONFIG = {
  logo: {
    'logo@2x': `/ui.shell/images/Logo/logo-WeCU@2x.png`,
    logo: `/ui.shell/images/Logo/logo-WeCU.png`,
    opacity: 0.4,
    height: 36,
    width: 100.8,
    logoTop: 10,
    logoRight: 22,
  },
  displayName: 'Invalid Configuration',
  isDisabled: true,
};

function configFromInput(
  configs: Record<string, TenantConfiguration>,
  input?: ISelectionListItem,
) {
  return input ? configs[input.id] ?? DEFAULT_CONFIG : DEFAULT_CONFIG;
}

export const TenantSelection: React.FC<ITenantSelectionProps> = ({
  rememberToggleChecked = true,
  tenants = [],
  configs,
  input,
  onInputChanged,
  onNextClicked,
  onToggleChanged,
}) => {
  const handleNextClicked = () => (input ? onNextClicked?.() : undefined);

  const config = configFromInput(configs, input);

  const toInputOption = (id: string) => {
    const isDisabled = !configs[id];
    const inputConfig = configs[id] || DEFAULT_CONFIG;
    const inputLogo = inputConfig.logo;

    return {
      id,
      label: inputConfig.displayName,
      description: id,
      isDisabled,
      icon: function TenantSelectionIcon() {
        return (
          <div css={styles.selectionIconOuter}>
            <Image
              style={styles.selectionIconInner}
              path={inputLogo.logo}
              path2x={inputLogo['logo@2x']}
              width={50}
              height={(inputLogo.height / inputLogo.width) * 50}
            />
          </div>
        );
      },
    };
  };

  const tenantOptions = tenants.map(toInputOption);

  const tenantLogo = config?.logo ?? DEFAULT_CONFIG.logo;

  return (
    <div css={styles.base}>
      <div css={styles.logoOuter}>
        <Image
          path={tenantLogo.logo}
          path2x={tenantLogo['logo@2x']}
          width={tenantLogo.width}
          height={tenantLogo.height}
        />
      </div>
      <Input
        selectionOptions={tenantOptions}
        onInputChanged={onInputChanged}
        onRememberClick={onToggleChanged}
        input={input}
        isToggleChecked={rememberToggleChecked}
      />
      <Next onNextClicked={handleNextClicked} />
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    width: 380,
    display: 'flex',
    flexDirection: 'column',
  }),
  logoOuter: css({
    height: 118,
    backgroundColor: '#E4E4E4',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  selectionIconOuter: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  selectionIconInner: css({
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }),
};
