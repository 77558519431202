import { FormsUserPermissions } from '@se/data/forms/types.ts';
import {
  IAppErrorState,
  IAppInitializePermissions,
  IAppInitialized,
  IAppInitializing,
  IAppLoggingOut,
  IAppLogout,
  IAppReturnHome,
  IAppShowChangePasswordDialog,
} from './types.ts';

export function returnHome(): IAppReturnHome {
  return {
    type: 'ui.shell/app/RETURN_HOME',
    payload: {},
  };
}

export function loggingOut(): IAppLoggingOut {
  return {
    type: 'ui.shell/app/LOGGING_OUT',
    payload: {},
  };
}

export function logout(): IAppLogout {
  return {
    type: 'ui.shell/app/LOG_OUT',
    payload: {},
  };
}

export function errorState(): IAppErrorState {
  return {
    type: 'ui.shell/app/ERROR_STATE',
    payload: {},
  };
}

export function initializing(): IAppInitializing {
  return {
    type: 'ui.shell/app/INITIALIZING',
    payload: {},
  };
}

export function initialized(): IAppInitialized {
  return {
    type: 'ui.shell/app/INITIALIZED',
    payload: {},
  };
}

export function showChangePasswordDialog(): IAppShowChangePasswordDialog {
  return {
    type: 'ui.shell/app/SHOW_CHANGE_PASSWORD_DIALOG',
    payload: {},
  };
}

export function initializePermissions(
  permissions: FormsUserPermissions,
): IAppInitializePermissions {
  return {
    type: 'ui.shell/app/INITIALIZE_PERMISSIONS',
    payload: { permissions },
  };
}
