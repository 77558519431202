import { FormsBulkUploadJobType } from '../job-history/types.ts';
import { FormsBulkUploadWorkflowType } from '../select-workflow/types.ts';
import { rejectedFileMetadata } from './fileMetadata.ts';
import {
  FormsBulkUploadFileValidationResult,
  IFormsBulkUploadImportFileValidationState,
  ReduxFormsBulkUploadCancelDuplicateRowImport,
  ReduxFormsBulkUploadDialogTabChange,
  ReduxFormsBulkUploadDuplicateImportDialogShow,
  ReduxFormsBulkUploadFileErrorUploading,
  ReduxFormsBulkUploadFileImport,
  ReduxFormsBulkUploadFileRejected,
  ReduxFormsBulkUploadFileUpload,
  ReduxFormsBulkUploadFileUploaded,
  ReduxFormsBulkUploadImportValidationClear,
  ReduxFormsBulkUploadImportValidationStatusFilterChange,
  RowStatus,
} from './types.ts';

export const bulkUploadDialogTabChanged = (
  tabId: IFormsBulkUploadImportFileValidationState['selectedTabId'],
): ReduxFormsBulkUploadDialogTabChange => ({
  type: 'ui.forms/bulk-upload/import-file-validation/TAB_CHANGE',
  payload: { tabId },
});

export const cancelDuplicateRowImport = (
  toolId: string,
): ReduxFormsBulkUploadCancelDuplicateRowImport => ({
  type: 'ui.forms/bulk-upload/import-file-validation/CANCEL_DUPLICATE_IMPORT',
  payload: { toolId },
});

export const clearImportFileValidation =
  (): ReduxFormsBulkUploadImportValidationClear => ({
    type: 'ui.forms/bulk-upload/import-file-validation/CLEAR',
    payload: {},
  });

export const fileErrorUploading = (
  errorMessage: string,
): ReduxFormsBulkUploadFileErrorUploading => ({
  type: 'ui.forms/bulk-upload/import-file-validation/FILE_ERROR_UPLOADING',
  payload: { errorMessage },
});

export const fileRejected = (
  file: File,
  maxSize: number,
): ReduxFormsBulkUploadFileRejected => ({
  type: 'ui.forms/bulk-upload/import-file-validation/FILE_REJECTED',
  payload: {
    rejection: rejectedFileMetadata(maxSize)(file),
  },
});

export const fileUpload = (file: File): ReduxFormsBulkUploadFileUpload => ({
  type: 'ui.forms/bulk-upload/import-file-validation/FILE_UPLOAD',
  payload: { file },
});

export const fileUploaded = (
  validationResult: FormsBulkUploadFileValidationResult,
): ReduxFormsBulkUploadFileUploaded => ({
  type: 'ui.forms/bulk-upload/import-file-validation/FILE_UPLOADED',
  payload: { validationResult },
});

export const importFile = (
  file: File,
  jobType: FormsBulkUploadJobType,
  workflowType: FormsBulkUploadWorkflowType,
  createAutomatedActions: boolean,
): ReduxFormsBulkUploadFileImport => ({
  type: 'ui.forms/bulk-upload/import-file-validation/IMPORT',
  payload: { file, jobType, workflowType, createAutomatedActions },
});

export const showDuplicateRowImportDialog =
  (): ReduxFormsBulkUploadDuplicateImportDialogShow => ({
    type: 'ui.forms/bulk-upload/import-file-validation/CONFIRM_DUPLICATE_IMPORT',
    payload: {},
  });

export const statusFilterChange = (
  filterValue: RowStatus,
): ReduxFormsBulkUploadImportValidationStatusFilterChange => ({
  type: 'ui.forms/bulk-upload/import-file-validation/STATUS_FILTER_CHANGE',
  payload: { filterValue },
});
