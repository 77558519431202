import { FormDesignerReduxDesignerState } from './types.ts';
import { IFormsDesignerDigitalContentPackCreatedAction } from './types.digitalContent.ts';

export function copyDigitalContentPackIdToTemplate(
  state: FormDesignerReduxDesignerState,
  action: IFormsDesignerDigitalContentPackCreatedAction,
) {
  if (!state.draft?.designerLines || !state.draft.lines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      designerLines: {
        ...state.draft.designerLines,
        digitalContent: {
          ...state.draft.designerLines.digitalContent,
          packId: action.payload.id,
        },
      },
      lines: {
        ...state.draft.lines,
        digitalContent: {
          ...state.draft.lines?.digitalContent,
          packId: action.payload.id,
        },
      },
    },
  };
}
