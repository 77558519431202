/** @jsxImportSource @emotion/react */
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { LoadMore } from '@seeeverything/ui.primitives/src/components/LoadMore/LoadMore.tsx';
import {
  ISelectionListColumn,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { QueryRouter } from '@seeeverything/ui.shell/src/api/api.queryBuilder/QueryRouter.ts';
import { IQueryRequest } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import { dropdownLoaded } from '@seeeverything/ui.shell/src/redux/query/actions.dropdown.ts';
import * as R from 'ramda';
import { filter } from 'rxjs';
import { getInitializedApp } from '../../../../app.ts';
import { client } from '../../../../common/db.ts';
import {
  MAX_HEIGHT,
  businessUnitFromQuery,
  calculateDropdownHeight,
  lastChipFromQuery,
} from '../helpers.tsx';

export const router = new QueryRouter();

/**
 * DROPDOWN
 */
router
  .dropdown('team', /\/team[^/]*/, 'Team dropdown')
  .pipe(
    filter(({ req }) =>
      Boolean(
        !req.isHandled &&
          ((req.context && req.context.id === 'team') ||
            (req.selection &&
              req.selection.type === 'EDITING' &&
              req.selection.chip.type === 'team')),
      ),
    ),
  )
  .subscribe(({ req, res }) => {
    const chip = req.selection && req.selection.chip;
    const searchFilter = (chip && chip.label) || '';
    res.dropdown({
      component: 'LIST',
      width: 400,
      height: 50,
      props: teamsFromServer(req, searchFilter), // Promise.
    });
  });

/**
 * AUTOCOMPLETE
 */
router
  .autocomplete('team', /\/team[^/]*$/, 'Team autocomplete')
  .pipe(
    filter(({ req }) =>
      Boolean(
        !req.isHandled &&
          req.context.value &&
          req.selection &&
          req.selection.type === 'EDITING' &&
          req.selection.chip.type === 'team',
      ),
    ),
  )
  .subscribe(({ req, res }) => {
    const { query, context, selection } = req;
    const { id, label } = context;
    if (id && selection) {
      const chips = query.chips.slice(0, selection.chipIndex); // Remove anything beyond this current chip.
      res.query({
        chips: [
          ...chips,
          {
            type: 'team',
            value: id,
            label: label.text,
          },
        ],
        filter: '',
      });
    } else {
      // No ID for a team supplied, cancel the operation.
      res.cancel();
    }
  });

const loadMore =
  (
    request: IQueryRequest,
    searchFilter: string,
    existingItems: ISelectionListItem[],
    nextPageNum: number,
  ) =>
  async () => {
    const app = getInitializedApp();
    const next = await teamsFromServer(
      request,
      searchFilter,
      existingItems,
      nextPageNum,
    );

    app.store.dispatch(dropdownLoaded(next));
  };

/**
 * INTERNAL
 */
async function teamsFromServer(
  request: IQueryRequest,
  searchFilter: string,
  existingItems: ISelectionListItem[] = [],
  nextPageNum = 1,
) {
  const app = getInitializedApp();
  const state = app.store.getState();
  const tenantConfig = state.tenantState.tenant.configuration;

  const businessUnitKey = businessUnitFromQuery(request);
  const businessUnit =
    businessUnitKey && tenantConfig.businessUnits[businessUnitKey];

  const chip = lastChipFromQuery(request);

  const teamId = chip?.type === 'team' ? chip.value : undefined;
  const team =
    teamId && (await orgHierarchyQuery.getTeam(client, teamId, tenantConfig));

  const teamPathFilter = team?.isSuccess ? team.data.path : undefined;

  const response = await orgHierarchyQuery.getTeams(client, {
    pageNumber: nextPageNum,
    businessUnit: (businessUnit && businessUnit.label) || undefined,
    path: teamPathFilter ? `${teamPathFilter}/` : undefined,
    name: searchFilter,
    excludeNonManagerMemberships: true,
    tenantConfig,
  });

  if (!response.isSuccess)
    return {
      columnTotal: 1,
      column: {
        items: [{ id: 'load-error', content: 'Unable to load list.' }],
      },
      height: 50,
    };

  // Convert into list items.
  const teams = response.data.teams.map((t) => ({
    id: t.id,
    icon: Icons[t.businessUnit.icon],
    value: t.id,
    content: { text: t.name, description: t.path, maxWidth: 331 },
  }));

  const lastExistingItem = R.last(existingItems);
  if (lastExistingItem && lastExistingItem.id === 'load-more') {
    existingItems.pop();
  }

  const allItems = [...existingItems, ...teams];

  if (response.data.pageInfo.hasNextPage) {
    allItems.push({
      id: 'load-more',
      content: (
        <LoadMore
          onInView={loadMore(request, searchFilter, allItems, nextPageNum + 1)}
        />
      ),
    });
  }

  const height = calculateDropdownHeight(allItems);

  const column: ISelectionListColumn = {
    isScrollable: height > MAX_HEIGHT,
    items: allItems,
  };
  return {
    columnTotal: 1,
    column,
    height: Math.min(height, MAX_HEIGHT),
  };
}
