import {
  FormsBulkUploadJobType,
  IBulkUploadJob,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/job-history/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  PageInfoRequest,
  PageInfoResponse,
} from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';

export interface IBulkUploadJobsGridOptions {
  jobType: FormsBulkUploadJobType;
  pagination: PageInfoRequest;
  hasNextPage: boolean;
  isLoadingMore: boolean;
}

export interface IBulkUploadJobsHistoryResponse {
  pageInfo: PageInfoResponse;
  nodes: IBulkUploadJob[];
}

export async function getBulkUploadJobs(
  client: IGraphQLClient,
  options?: IBulkUploadJobsGridOptions,
) {
  if (!options) return;

  const {
    jobType,
    pagination: { pageNumber = 1, pageSize = 50 },
  } = options;
  try {
    const response =
      await client.query<GraphQLFormsBulkUploadJobHistoryResponse>({
        fetchPolicy: 'network-only', // Default to network-only.
        ...options,
        query: jobsLoadQuery,
        variables: {
          jobType,
          pageSize,
          pageNumber,
        },
      });

    return response.data.forms.formsBulkUploadJobHistory;
  } catch (err) {
    log.error(
      new Error(`GraphQL Error: Failed to fetch Bulk Upload jobs. ${err}`),
    );
    return undefined;
  }
}

const jobsLoadQuery = gql`
  query FormsBulkUploadJobHistory(
    $jobType: FormBulkUploadJobType!
    $pageSize: Int!
    $pageNumber: Int!
    $orderBy: [OrderByInput!]
  ) {
    forms {
      formsBulkUploadJobHistory(
        jobType: $jobType
        orderBy: $orderBy
        pagination: { size: $pageSize, pageNumber: $pageNumber }
      ) {
        pageInfo {
          hasNextPage
          currentPage
        }
        nodes {
          id
          failed
          jobStatus
          succeeded
          templateNames
          uploadedAt
          uploadedBy {
            id
            name
          }
        }
      }
    }
  }
`;

interface GraphQLFormsBulkUploadJobHistoryResponse {
  forms: {
    formsBulkUploadJobHistory: {
      pageInfo?: PageInfoResponse;
      nodes?: IBulkUploadJob[];
    };
  };
}
