/** @jsxImportSource @emotion/react */
import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { invalidateClientTokens } from '@seeeverything/ui.auth/src/api/loginService.ts';
import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import { formActionSlice } from '@seeeverything/ui.forms/src/redux/formAction/index.ts';
import {
  errorState,
  logout,
  showChangePasswordDialog,
} from '@seeeverything/ui.shell/src/redux/app/actions.ts';
import { chipAddedExternally } from '@seeeverything/ui.shell/src/redux/query/actions.ts';
import { clear } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { IS_BROWSER } from '@seeeverything/ui.util/src/constants/constants.ts';
import { prop } from '@seeeverything/ui.util/src/local-storage/prop.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { tenantSlice } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { Keys, storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { IInitializedApp } from '../../types.ts';

/**
 * An API exposed in the browser console.
 */
export function init(app: IInitializedApp) {
  const { dispatch } = app.store;

  const api = {
    debug: (show = true) => prop(Keys.Debug, show),
    clearSheets: () => dispatch(clear()),
    addPersonChip: (id: string) =>
      dispatch(
        chipAddedExternally(
          {
            type: 'people',
            label: 'Person from Console',
            value: id,
          },
          true,
        ),
      ),
    addTeamChip: (id: string) =>
      dispatch(
        chipAddedExternally(
          {
            type: 'team',
            label: 'Team from Console',
            value: id,
          },
          true,
        ),
      ),
    addFormChip: (instanceId: string) =>
      dispatch(
        chipAddedExternally(
          {
            type: 'forms',
            label: 'Form through Console',
            value: instanceId,
          },
          true,
        ),
      ),
    addAuditChip: () =>
      dispatch(
        chipAddedExternally(
          {
            type: 'auditLog',
            label: 'Audit Log',
            value: 'value',
          },
          true,
        ),
      ),
    form: async () => {
      const formInstanceId = app.store
        .getState()
        .query.query.chips.find((chip) => chip.type === 'forms')?.value;

      if (formInstanceId) {
        const tenant = storageApi.tenant.getSessionStorage();
        const module = storageApi.module.getSessionStorage();
        const token = await authApi.getAccessToken();
        const path = `/forms/${formInstanceId}?tenant=${encodeURIComponent(
          tenant,
        )}&module=${module}&token=${token}`;
        window.open(path);
      } else {
        return 'Try opening a form instance in the app first';
      }
    },
    changePassword: async () => {
      dispatch(showChangePasswordDialog());
    },
    logout: () => {
      dispatch(logout());
    },
    error: () => {
      dispatch(errorState());
    },
    at: async () => {
      log.debug(await authApi.getAccessToken());
    },
    goal: (id: string, instanceId?: string) =>
      dispatch(
        formEditGoalSlice.loadGoal({
          goalId: id,
          source: { type: 'FORM', instanceId },
        }),
      ),
    action: (actionId: string, instanceId?: string) =>
      dispatch(
        formActionSlice.load({
          actionId,
          source: { type: 'FORM', instanceId },
        }),
      ),
    gridFilters: (enable = true) => {
      if (enable === true) storageApi.temp.enableGridFilters();
      if (enable === false) storageApi.temp.disableGridFilters();
      if (enable === null) storageApi.temp.unsetGridFilters();
      dispatch(tenantSlice.tempOverrideGridFilters({ to: enable }));
    },
    invalidateClientTokens,
  };

  if (IS_BROWSER) {
    (window as any).s = api;
  }
  return api;
}
