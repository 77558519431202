import {
  FormsBulkUploadWorkflowType,
  ReduxFormsBulkSelectCreateAutomatedActions,
  ReduxFormsBulkSelectWorkflow,
} from './types.ts';

export function selectWorkflow(
  workflowType: FormsBulkUploadWorkflowType,
): ReduxFormsBulkSelectWorkflow {
  return {
    type: 'ui.forms/bulk-upload/select-workflow/SELECT_WORKFLOW_OPTION',
    payload: { workflowType },
  };
}

export function selectCreateAutomatedActions(
  createAutomatedActions: boolean,
): ReduxFormsBulkSelectCreateAutomatedActions {
  return {
    type: 'ui.forms/bulk-upload/select-workflow/SELECT_CREATE_AUTOMATED_ACTIONS',
    payload: { createAutomatedActions },
  };
}
