/** @jsxImportSource @emotion/react */
import { isAnyOf } from '@reduxjs/toolkit';
import {
  ClickThroughGridValuesType,
  toDateFilter,
} from '@seeeverything/ui.dashboards/src/data/index.ts';
import {
  setCustomDateRange,
  setDateFilter,
  setFinancialYearStartMonth,
} from '@seeeverything/ui.dashboards/src/redux/actions.ts';
import { dashboardGridsSlice } from '@seeeverything/ui.dashboards/src/redux/index.ts';
import {
  IDashboardActionComponentViewDetail,
  IDashboardActionComponentViewEntity,
  IDashboardActionGridToolButtonClicked,
  IDashboardActionHeaderPathClicked,
  IDashboardActionViewClickThroughGrid,
} from '@seeeverything/ui.dashboards/src/redux/types.ts';
import {
  IDashboardCommentsList,
  IDashboardGrid,
  IDashboardToolButton,
} from '@seeeverything/ui.dashboards/src/types.ts';
import { goalsActionsGridLabel } from '@seeeverything/ui.forms/src/components/Grid/util.ts';
import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import { formActionSlice } from '@seeeverything/ui.forms/src/redux/formAction/index.ts';
import { goalsActionsGridSlice } from '@seeeverything/ui.forms/src/redux/goalsActionsGrid/index.ts';
import { DateRangeDialogContainer } from '@seeeverything/ui.shell/src/components/DateSelector/DateRangeDialogContainer.tsx';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import {
  ISheetToolbarDropdownClickAction,
  ISheetsDateRangeCanceledAction,
  ISheetsDateRangeConfirmedAction,
} from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { tenantSlice } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { encodeBase64 } from '@seeeverything/ui.util/src/str/str.base64.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, from, map, of } from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';
import {
  IAddChipOptions,
  addChipAction,
  addCommentsListChipAction,
  addGridV2ChipAction,
} from '../../config.sheets/index.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  headerPathClickedEpic,
  initClickThroughGridOnGridLinkClicked,
  openFullSheetGoalsAndActionsGridEpic,
  openFullSheetGridOnToolbarClickEpic,
  openFullSheetGridOnViewDetailEpic,
  showStatusBarOnDataGridDownloadEpic,
  toolbarCustomDateFilterClickedEpic,
  toolbarCustomDateRangeCanceledEpic,
  toolbarCustomDateRangeConfirmedEpic,
  toolbarDateFilterClickedEpic,
  updateFinancialYearOnTenantConfigChangedEpic,
  viewDashboardActionEntityEpic,
  viewDashboardFormEntityEpic,
  viewDashboardGoalEntityEpic,
  viewDashboardPersonEntityEpic,
  viewDashboardTeamEntityEpic,
);

function updateFinancialYearOnTenantConfigChangedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(tenantSlice.setConfiguration.match),
    map(({ payload }) =>
      setFinancialYearStartMonth(
        payload.configuration.reportingDates?.financialYearStartMonth,
      ),
    ),
  );
}

/**
 * Epic to show status bar when grid download is initiated.
 */
function showStatusBarOnDataGridDownloadEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        dashboardGridsSlice.downloadToSpreadsheet.match,
        goalsActionsGridSlice.downloadGrid.match,
      ),
    ),
    map(() =>
      showStatusBar(
        'INFO',
        `A spreadsheet of this table will automatically be downloaded by your browser. Note this may take several minutes.`,
      ),
    ),
  );
}

function toolbarDateFilterClickedEpic(
  action$: Observable<ISheetToolbarDropdownClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(
      ({ payload: { toolId, itemId } }) =>
        toolId === 'DATE_FILTER_OPTIONS' && itemId !== 'CUSTOM_RANGE',
    ),
    map(({ payload: { itemId } }) => setDateFilter(itemId.toString())),
  );
}

function toolbarCustomDateFilterClickedEpic(
  action$: Observable<ISheetToolbarDropdownClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(
      ({ payload }) =>
        payload.toolId === 'DATE_FILTER_OPTIONS' &&
        payload.itemId === 'CUSTOM_RANGE',
    ),
    map(() =>
      showModalDialog({
        content: <DateRangeDialogContainer />,
        width: 475,
      }),
    ),
  );
}

function toolbarCustomDateRangeCanceledEpic(
  action$: Observable<ISheetsDateRangeCanceledAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/DATE_RANGE/CANCELED'),
    map(() => hideModalDialog()),
  );
}

function toolbarCustomDateRangeConfirmedEpic(
  action$: Observable<ISheetsDateRangeConfirmedAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/DATE_RANGE/CONFIRMED'),
    map(() => {
      const { start, end } = state$.value.sheets.dateRange;
      return from([
        hideModalDialog(),
        setDateFilter('CUSTOM_RANGE'),
        setCustomDateRange(start, end),
      ]);
    }),
    concatAll(),
  );
}

function openFullSheetGridOnToolbarClickEpic(
  action$: Observable<IDashboardActionGridToolButtonClicked>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/GRID_TOOLS/BUTTON_CLICKED'),
    filter(({ payload: { id, parentId } }) =>
      Boolean(id === 'OPEN_GRID' && parentId),
    ),
    map(({ payload: { parentId } }) => {
      const state = state$.value;
      const getSelectedId = (buttons: IDashboardToolButton[]) => {
        const selectedGridButton = buttons
          .filter((b) => b.id !== 'OPEN_GRID')
          .find((b) => Boolean(b.isSelected));

        return selectedGridButton?.toolId;
      };

      const tools = state.dashboardsV2.GRID_TOOLS[parentId];

      const grids = Object.values(state.dashboardsV2.GRID).filter(
        (grid) => grid.group === tools.group,
      );

      // When grid-tools reference multiple grids, examine toggle-button states.
      const gridId =
        grids.length === 1 ? grids[0].id : getSelectedId(tools.toolButtons);

      const grid = grids.find(({ id }) => id === gridId);
      const addGridSheetAction = tryAddGridSheet(state, grid);

      return addGridSheetAction ? of(addGridSheetAction) : EMPTY;
    }),
    concatAll(),
  );
}

function openFullSheetGridOnViewDetailEpic(
  action$: Observable<IDashboardActionComponentViewDetail>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_DETAIL'),
    map((action) => {
      const state = state$.value;
      const { dashboardsV2 } = state;

      switch (action.payload.componentType) {
        case 'GRID': {
          const grid = dashboardsV2.GRID[action.payload.id];
          return tryAddGridSheet(state, grid);
        }

        case 'COMMENTS_LIST': {
          const commentsList = dashboardsV2.COMMENTS_LIST[action.payload.id];
          return tryAddCommentsListSheet(state, commentsList);
        }

        default:
          throw new Error(
            `Cannot open detail view for component of type '${action.payload.componentType}'.`,
          );
      }
    }),
  );
}

function openFullSheetGoalsAndActionsGridEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    filter(goalsActionsGridSlice.loadMoreClicked.match),
    filter((action) => Boolean(action.payload.dashboardId)),
    map((action) => {
      const { dateRangeLabel, endDate, entityType, entityId, startDate } =
        action.payload;

      const label = goalsActionsGridLabel({
        module: state$.value.tenantState.tenant.module,
        dateRangeLabel,
        actionLabel: state$.value.tenantState.tenant.locale.label.action,
      });

      const id = [
        entityType,
        entityId,
        startDate,
        endDate,
        dateRangeLabel,
      ].join('|');

      return addChipAction({
        id,
        label,
        type: 'goalsActionsGrid',
      });
    }),
  );
}

function initClickThroughGridOnGridLinkClicked(
  action$: Observable<IDashboardActionViewClickThroughGrid>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/GRID/VIEW_CLICK_THROUGH_GRID'),
    map((action) => {
      const {
        clickThroughGridId,
        forEntity,
        sourceGrid,
        clickThroughDefinitionGridId,
      } = action.payload;

      const clickThroughGridName = `${sourceGrid.name} - ${forEntity.label} - ${sourceGrid.columnClicked.name}`;
      const template = state$.value.dashboardsV2.template;
      const gridData: ClickThroughGridValuesType = {
        clickThroughId: clickThroughGridId,
        sourceGridId: sourceGrid.id,
        sourceGridColId: sourceGrid.columnClicked.id,
        sourceGridRowId: forEntity.id,
        sourceDataSetId: sourceGrid.dataSetId,
        clickThroughDefinitionId: clickThroughDefinitionGridId,
        sourceGridType: sourceGrid.columnClicked.clickThroughType ?? 'SESSIONS',
        templateEntity: template?.kind ?? 'person',
        templateEntityId: template?.entityId ?? '',
        entityPath:
          state$.value.dashboardsV2?.HEADER?.dataState.data?.data.path,
      };

      const clickThroughGridChipOptions: IAddChipOptions = {
        label: getChipLabel(state$.value, clickThroughGridName),
        id: encodeBase64(JSON.stringify(gridData)),
        type: 'clickThroughGrid',
      };

      return addChipAction(clickThroughGridChipOptions);
    }),
  );
}

const tryAddGridSheet = (
  state: GlobalAppState,
  grid?: IDashboardGrid,
  shouldRunQuery?: boolean,
) => {
  const chip = grid && {
    label: getChipLabel(state, grid.name),
    value: grid.id,
    icon: grid.icon,
  };
  return chip ? addGridV2ChipAction(chip, shouldRunQuery) : undefined;
};

const tryAddCommentsListSheet = (
  state: GlobalAppState,
  commentsList?: IDashboardCommentsList,
) => {
  const chip = commentsList && {
    label: getChipLabel(state, commentsList.title),
    value: commentsList.id,
    icon: 'chatBubbleOutline',
  };
  return chip ? addCommentsListChipAction(chip) : undefined;
};

const getChipLabel = (state: GlobalAppState, baseLabel: string) => {
  const dateFilter = toDateFilter(state);

  return dateFilter
    ? `${baseLabel} - ${dateFilter.selection.toLabel({
        financialYearStartMonth: dateFilter.financialYearStartMonth,
        customOptions: dateFilter.selectedCustomDates,
      })}`
    : baseLabel;
};

function viewDashboardPersonEntityEpic(
  action$: Observable<IDashboardActionComponentViewEntity>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY'),
    filter((action) => action.payload.entity?.type === 'Person'),
    map(({ payload: { entity } }) =>
      addChipAction({
        id: entity.id,
        label: entity.label,
        type: 'people',
      }),
    ),
  );
}

function viewDashboardTeamEntityEpic(
  action$: Observable<IDashboardActionComponentViewEntity>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY'),
    filter((action) => action.payload.entity?.type === 'Team'),
    map(({ payload: { entity } }) =>
      addChipAction({
        id: entity.id,
        label: entity.label,
        type: 'team',
      }),
    ),
  );
}

function viewDashboardFormEntityEpic(
  action$: Observable<IDashboardActionComponentViewEntity>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY'),
    filter((action) => action.payload.entity?.type === 'Form'),
    map(({ payload: { entity } }) =>
      addChipAction({
        id: entity.id,
        label: entity.label,
        type: 'forms',
      }),
    ),
  );
}

function viewDashboardActionEntityEpic(
  action$: Observable<IDashboardActionComponentViewEntity>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY'),
    filter((action) => action.payload.entity?.type === 'Action'),
    map((action) => {
      const gridId = action.payload.componentId;
      const actionId = action.payload.entity.id;
      const clickThrough =
        state$.value.dashboardsV2.CLICK_THROUGH_GRID?.[gridId]?.clickThrough;

      const gridKey = dashboardGridsSlice.utils.createGridKey({
        module: state$.value.tenantState.tenant.module,
        gridId,
        entityId: state$.value.dashboardsV2.template.entityId,
        dataSetId: clickThrough?.dataSetId,
        rowId: clickThrough?.rowId,
        columnId: clickThrough?.columnId,
      });

      return formActionSlice.load({
        actionId,
        source: { type: 'DASHBOARD', gridKey },
      });
    }),
  );
}

function viewDashboardGoalEntityEpic(
  action$: Observable<IDashboardActionComponentViewEntity>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY'),
    filter((action) => action.payload.entity?.type === 'Goal'),
    map((action) => {
      const gridId = action.payload.componentId;
      const goalId = action.payload.entity.id;

      const clickThrough =
        state$.value.dashboardsV2.CLICK_THROUGH_GRID?.[gridId]?.clickThrough;

      const gridKey = dashboardGridsSlice.utils.createGridKey({
        module: state$.value.tenantState.tenant.module,
        gridId,
        entityId: state$.value.dashboardsV2.template.entityId,
        dataSetId: clickThrough?.dataSetId,
        rowId: clickThrough?.rowId,
        columnId: clickThrough?.columnId,
      });

      return formEditGoalSlice.loadGoal({
        goalId,
        source: { type: 'DASHBOARD', gridKey },
      });
    }),
  );
}

export function headerPathClickedEpic(
  action$: Observable<IDashboardActionHeaderPathClicked>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/HEADER/PATH_CLICKED'),
    map((action) => {
      const { label, value } = action.payload;
      return addChipAction({
        id: value,
        label: label,
        type: 'team',
      });
    }),
  );
}
