import gql from 'graphql-tag';
import * as R from 'ramda';
import { file as fileUtil } from '@seeeverything/ui.util/src/file/index.ts';
import { IDesignerDigitalContent } from '../model/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';

type LoadDigitalContentPackResponse = {
  digitalContent: {
    contentPack: LoadDigitalContentPackResponseNode;
  };
};

type LoadDigitalContentPackResponseNode = {
  id: string;
  commentsEnabled: boolean;
  commentGuidance: string;
  description: string;
  files: [
    {
      id: string;
      name: string;
      size: number;
      downloadLink: string;
      type: string;
    },
  ];
  publishDate: Date;
  summary: string;
};

export async function loadDigitalContentPack(
  client: IGraphQLClient,
  id: string,
): Promise<IDesignerDigitalContent> {
  try {
    const response = await client.query<LoadDigitalContentPackResponse>({
      query,
      fetchPolicy: 'network-only',
      variables: { id },
    });

    const data = response.data.digitalContent.contentPack;
    const contentPack = data && toDigitalContent(data);
    if (R.isNil(contentPack)) {
      throw new Error('Digital content pack not found');
    }

    return contentPack;
  } catch (e) {
    log.error('Unable to fetch Digital Content Pack', e);
    throw e;
  }
}

function toDigitalContent(
  node: LoadDigitalContentPackResponseNode,
): IDesignerDigitalContent {
  return {
    id: node.id,
    commentsEnabled: node.commentsEnabled,
    commentGuidance: node.commentGuidance,
    description: node.description,
    files: node.files?.map(({ id, name, size }) => ({
      id,
      displaySize: fileUtil.getReadableSize(size),
      extension: fileUtil.getExtension(name),
      name,
      size,
      canDelete: true,
      uploadedByName: '',
    })),
    isActive: false,
    publishDate: node.publishDate,
    summary: node.summary,
  };
}

const query = gql`
  query DigitalContentPack($id: ID!) {
    digitalContent {
      contentPack(id: $id) {
        id
        commentsEnabled
        commentGuidance
        description
        files {
          id
          name
          size
          downloadLink
          type
        }
        summary
      }
    }
  }
`;
