import { of, Observable, mergeMap, map, concatAll, filter } from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import {
  FormLineComponent,
  IDigitalContentLine,
  ISectionLine,
  ISignoffLine,
} from '@seeeverything/ui.forms/src/types/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import * as actions from './actions.ts';
import {
  activateDigitalContentPack,
  archiveDigitalContentPackFile,
  createDigitalContentPack,
  deactivateDigitalContentPack,
  loadDigitalContentPack,
  updateDigitalContentPack,
  uploadFile,
} from './epics.digitalContent.ts';
import {
  IFormsDesignerDesignerLoadDraftAction,
  IFormsDesignerDesignerEditDefinitionAction,
  IFormsDesignerDesignerSaveDefinitionAction,
  IFormsDesignerDesignerDeleteComponent,
  IFormsDesignerDesignerAddComponent,
  IFormsDesignerDesignerChangeComponentProperty,
  IFormsDesignerDesignerOptionsDeleteExistingOption,
  IFormsDesignerDesignerOptionsAddNewOption,
  IFormsDesignerDesignerOptionsChangeOptionText,
  IFormsDesignerIntegrationAddComponentToTemplate,
  IFormsDesignerIntegrationDeleteComponentFromTemplate,
  IFormsDesignerDesignerValidateDefinitionAction,
} from './types.ts';
import { convertLinesToDefinition } from './util.ts';
import * as data from '../../data/index.ts';
import { toDesignerLines } from '../../data/data.editableDraft.designerLines.ts';
import { IDesignerEditableDraft } from '../../model/types.ts';
import {
  GlobalFormsDesignerEpicDependencies,
  GlobalFormsDesignerState,
} from '../store.ts';
import { clearFormsDesignerTrackedDataIds } from './epics.clearTrackedDataIds.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsDesignerState
>(
  activateDigitalContentPack,
  addComponentToTemplate,
  applyDesignerChangeToTemplateLines,
  archiveDigitalContentPackFile,
  clearFormsDesignerTrackedDataIds,
  createDigitalContentPack,
  deactivateDigitalContentPack,
  deleteComponentFromTemplate,
  loadDigitalContentPack,
  loadDraftEpic,
  saveDraftDefinitionEpic,
  saveDraftDefinitionOnEditEpic,
  updateDesignerLinesOnTemplateChange,
  updateDigitalContentPack,
  uploadFile,
  validateDraftDefinition,
);

function loadDraftEpic(
  action$: Observable<IFormsDesignerDesignerLoadDraftAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/LOAD_DRAFT'),
    mergeMap(async (action) => {
      const designerConfig =
        state$.value.tenantState.tenant?.configuration?.modules?.[
          state$.value.tenantState.tenant?.module
        ]?.formTemplateDesigner;
      try {
        const draft = await data.editableDraft(
          client,
          action.payload.id,
          designerConfig,
        );
        return of(actions.loaded(draft));
      } catch (error) {
        return of(actions.loadFailed());
      }
    }),
    concatAll(),
  );
}

/**
 * Triggers saving the draft definition, currently on any edit.
 */
function saveDraftDefinitionOnEditEpic(
  action$: Observable<IFormsDesignerDesignerEditDefinitionAction>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/EDIT_DEFINITION'),
    mergeMap(async (action) => {
      const { id } = action.payload;
      return of(actions.saveDefinition(id, 'save'));
    }),
    concatAll(),
  );
}
/**
 * Carries out saving the draft definition and reports on result.
 */
function saveDraftDefinitionEpic(
  action$: Observable<IFormsDesignerDesignerSaveDefinitionAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/SAVE_DEFINITION'),
    filter(() => {
      const draft = state$.value.formsDesignerEditor.draft;
      return Boolean(draft?.lines);
    }),
    mergeMap(async (action) => {
      const { id, source } = action.payload;
      const draft = state$.value.formsDesignerEditor
        .draft as IDesignerEditableDraft;

      const definition = convertLinesToDefinition(draft.lines as FormLineById);

      const { success } = await data.saveDraftDefinition(
        client,
        id,
        definition,
      );
      return success
        ? of(actions.savedDefinition(id, definition, source))
        : of(actions.saveDefinitionFailed());
    }),
    concatAll(),
  );
}

/**
 * Saves the component label change to template lines.
 */
function applyDesignerChangeToTemplateLines(
  action$: Observable<
    | IFormsDesignerDesignerChangeComponentProperty
    | IFormsDesignerDesignerOptionsDeleteExistingOption
    | IFormsDesignerDesignerOptionsAddNewOption
    | IFormsDesignerDesignerOptionsChangeOptionText
  >,
) {
  return action$.pipe(
    ofType(
      'ui.forms.designer/designer/component/CHANGE_COMPONENT_PROPERTY',
      'ui.forms.designer/designer/component/OPTIONS/ADD_OPTION',
      'ui.forms.designer/designer/component/OPTIONS/DELETE_OPTION',
      'ui.forms.designer/designer/component/OPTIONS/CHANGE_TEXT',
    ),
    map((action) =>
      actions.copyComponentFromDesignerToTemplate(action.payload.id),
    ),
  );
}

/**
 * Deletes the designer component from template lines.
 */
function deleteComponentFromTemplate(
  action$: Observable<IFormsDesignerDesignerDeleteComponent>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/component/DELETE_COMPONENT'),
    map((action) => actions.deleteComponentFromTemplate(action.payload.id)),
  );
}

/**
 * Adds the designer component to template lines.
 */
function addComponentToTemplate(
  action$: Observable<IFormsDesignerDesignerAddComponent>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/component/ADD_COMPONENT'),
    map((action) =>
      actions.addComponentToTemplate(
        action.payload.id,
        action.payload.kind,
        action.payload.insertBeforeId,
      ),
    ),
  );
}

function updateDesignerLinesOnTemplateChange(
  action$: Observable<
    | IFormsDesignerIntegrationAddComponentToTemplate
    | IFormsDesignerIntegrationDeleteComponentFromTemplate
  >,
  state$: StateObservable<GlobalFormsDesignerState>,
) {
  return action$.pipe(
    ofType(
      'ui.forms.designer/integration/ADD_COMPONENT_TO_TEMPLATE',
      'ui.forms.designer/integration/DELETE_COMPONENT_FROM_TEMPLATE',
      'ui.forms.designer/integration/DELETE_COMPONENT_FROM_TEMPLATE',
    ),
    map(() => {
      const formDesignerState = state$.value.formsDesignerEditor;
      const lines = formDesignerState.draft?.lines || {};
      const config =
        state$.value.tenantState.tenant?.configuration?.modules?.[
          state$.value.tenantState.tenant?.module
        ].formTemplateDesigner;
      return actions.designerLinesChanged(toDesignerLines(lines, config));
    }),
  );
}

function validateDraftDefinition(
  action$: Observable<IFormsDesignerDesignerValidateDefinitionAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/VALIDATE_DEFINITION'),
    map((action) => {
      const designerLines =
        state$.value.formsDesignerEditor.draft?.designerLines || {};

      const validatedLines = Object.keys(designerLines).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...designerLines[key],
            validationError: validateLine(designerLines[key]),
          },
        }),
        {},
      );

      const hasValidationError = Object.values(validatedLines).some(
        ({ validationError }) =>
          validationError?.error && validationError?.fields?.length > 0,
      );

      return hasValidationError
        ? actions.validateDefinitionFailed(action.payload.id, validatedLines)
        : actions.validateDefinitionSuccess(action.payload.id);
    }),
  );
}

const validateLine = (line: any) => {
  switch (line.type) {
    case 'attendance':
    case 'actionPlan':
    case 'fileUpload':
    case 'speechBlock':
    case 'textBlock':
    case 'textField':
    case 'switchAnswer':
    case 'section':
      return validateTitle(line);
    case 'digitalContent':
      return validateDigitalContent(line);
    case 'standardHeaders':
      return validateStandardHeader(line);
    case 'signoff':
      return validateSignOff(line);
    case 'options':
      return validateOptions(line);
  }
  return {};
};

const validateFieldsBase = (
  line: FormLineComponent,
  fieldsToValidate: string[],
) => {
  const invalidFields = fieldsToValidate.filter(
    (field) =>
      (line as any)[field] === '' || (line as any)[field]?.label === '',
  );
  return invalidFields.length > 0
    ? { error: true, fields: invalidFields }
    : { error: false, fields: [] };
};

const validateStandardHeader = (line: ISectionLine) =>
  validateFieldsBase(line, [
    'sectionTitle',
    'reviewerLabel',
    'reportingDateLabel',
    'reviewer',
    'subject',
  ]);

const validateTitle = (line: any) => {
  return !(line?.index?.title || line?.index?.[0]?.title || line?.title)
    ? { error: true, fields: ['title'] }
    : { error: false, fields: [] };
};

const validateDigitalContent = (line: IDigitalContentLine) => {
  if (!line.isEnabled) return { error: false, fields: [] };

  const invalidFields: string[] = [];

  if (line.title === '') invalidFields.push('title');
  if (line.description === '') invalidFields.push('description');
  if (line.commentsEnabled && line.commentsGuidance === '')
    invalidFields.push('commentsGuidance');

  return invalidFields.length > 0
    ? { error: true, fields: invalidFields }
    : { error: false, fields: [] };
};

const validateSignOff = (line: ISignoffLine) =>
  validateFieldsBase(line, ['title', 'reviewerLabel', 'subject']);

const validateOptions = ({ optionItems }: any) => {
  const fields: string[] = [];
  if (optionItems?.title === '') {
    fields.push('title');
  }

  Object.keys(optionItems?.options).forEach((option) => {
    if (optionItems?.options[option]?.label === '') {
      fields.push(optionItems?.options[option]?.id as string);
    }
  });
  return fields.length > 0
    ? { error: true, fields }
    : { error: false, fields: [] };
};
