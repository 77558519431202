/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { LoadingFullScreen } from '@seeeverything/ui.auth/src/components/LoadingFullScreen/LoadingFullScreen.tsx';
import {
  setCustomDateRange,
  setDateFilter,
} from '@seeeverything/ui.dashboards/src/redux/actions.ts';
import { dashboardGridsSlice } from '@seeeverything/ui.dashboards/src/redux/index.ts';
import { formActionSlice } from '@seeeverything/ui.forms/src/redux/formAction/index.ts';
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { IChipData } from '@seeeverything/ui.shell/src/api/types.ts';
import { Debug } from '@seeeverything/ui.shell/src/components/Debug/Debug.tsx';
import { hideModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import {
  moduleChangedFromUrl,
  replaceChips,
} from '@seeeverything/ui.shell/src/redux/query/actions.ts';
import {
  changeDateRange,
  clearDateRange,
} from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  DateContext,
  IDateContext,
} from '@seeeverything/ui.util/src/react-context/index.ts';
import { Keys } from '@seeeverything/ui.util/src/storage/api.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useRouter } from 'next/router.js';
import { last } from 'ramda';
import { useEffect, useState } from 'react';
import { init as consoleInit } from '../../config/config.console/api.tsx';
import { env } from '../../env.ts';
import { dispatchGridColumnFiltersAndSorts, init } from '../../init.tsx';
import { useAppDispatch, useAppSelector } from '../../redux/configureStore.ts';
import { DateFilterType, EnhancedStore, IInitializedApp } from '../../types.ts';
import { App } from './App.tsx';

export interface IAppDebugProps {
  initialColumnFilters?: Record<string, string[]>;
  initialColumnSorts?: Record<string, string>;
  initialDateFilter?: DateFilterType;
  initialModule: ModuleType;
  initialPath?: string;
  initialTenant: string;
  store: EnhancedStore;
}

/**
 * A debug container for the app.
 */
export const DebuggableApp: React.FC<IAppDebugProps> = ({
  initialColumnFilters,
  initialColumnSorts,
  initialDateFilter,
  initialModule,
  initialPath,
  initialTenant,
  store,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const module = useAppSelector((state) => state.tenantState.tenant.module);
  const tenantId = useAppSelector((state) => state.tenantState.tenant.id);
  const tenantConfig = useAppSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const [app, setApp] = useState<IInitializedApp>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastTrackedPath, setLastTrackedPath] = useState('');
  const [isNavigationEvent, setIsNavigationEvent] = useState(false);
  const [isDebug] = useLocalStorage(Keys.Debug, false);

  useEffect(() => {
    (async () => {
      if (app) return;

      const initApp = await init({
        initialPath,
        initialDateFilter,
        initialModule,
        initialTenant,
        initialColumnSorts,
        initialColumnFilters,
        store,
      });

      try {
        consoleInit(initApp);
      } catch (err) {
        log.error('An error occurred during start-up', err);
      }

      setApp(initApp);
      setIsLoaded(true);
    })();
  }, [
    app,
    initialColumnFilters,
    initialColumnSorts,
    initialDateFilter,
    initialModule,
    initialPath,
    initialTenant,
    store,
  ]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    // Back/forward button navigation support
    window.onpopstate = () => {
      if (!app) return;

      const queryParams = urlDeepLinkUtil.getUrlQueryParameters(
        window.location.search,
      );

      if (!queryParams) return;

      const isTenantChanged = queryParams.tenant !== tenantId;
      if (isTenantChanged) {
        router.push(window.location.search, undefined, {
          shallow: false,
        });
        return;
      }

      const isPathValid = urlDeepLinkUtil.isUrlPathValid(queryParams.path);
      if (!isPathValid) return;

      const moduleConfig = tenantConfig.modules[queryParams.module];
      if (!moduleConfig) return;

      setIsNavigationEvent(true);

      const chips = queryParams.chips;

      dispatch(hideModalDialog());
      dispatch(formActionSlice.hide());

      if (module !== queryParams.module) {
        dispatch(
          moduleChangedFromUrl({ chips, filter: '' }, queryParams.module),
        );
      } else {
        dispatch(replaceChips(chips));
      }

      const { columnFilters, columnSorts } = queryParams;
      dispatchGridColumnFiltersAndSorts(
        dispatch,
        module,
        chips,
        columnFilters,
        columnSorts,
      );

      const dateFilterId = queryParams.dateFilter;
      if (!dateFilterId) return;

      const { dateStart, dateEnd } = queryParams;

      dispatch(setDateFilter(dateFilterId));

      if (dateFilterId !== 'CUSTOM_RANGE') {
        dispatch(clearDateRange('DASHBOARDS'));
        return;
      }

      if (!dateStart || !dateEnd) return;

      dispatch(setCustomDateRange(dateStart, dateEnd));
      dispatch(changeDateRange('START', dateStart, 'DASHBOARDS'));
      dispatch(changeDateRange('END', dateEnd, 'DASHBOARDS'));
    };
  }, [app, dispatch, module, router, tenantId, tenantConfig]);

  useEffect(() => {
    if (!app) return;

    /**
     * App has been re-created (usually module changed causing re-init).
     * Updates stateful reference.
     */
    const appChangedSubscription = app.updatedApp$.subscribe((to) =>
      setApp(to),
    );

    /**
     * Track url changes in address bar.
     */
    const changeUrlSubscription = app.store$.subscribe(async (nextState) => {
      const dateFilterId =
        nextState.dashboardsV2.settings?.selectedDateFilterId;

      if (!dateFilterId) return;

      const isCustomDateRange = dateFilterId === 'CUSTOM_RANGE';
      const customDateRange =
        nextState.dashboardsV2.settings?.selectedCustomDates;

      if (isCustomDateRange && !customDateRange) return;

      const nextTenant = nextState.tenantState.tenant?.id;
      const nextModule = nextState.tenantState.tenant?.module;
      const chips = nextState.query.query.chips as IChipData[];

      const lastChip = last(chips);

      const dashboardGridParameters =
        dashboardGridsSlice.utils.getUrlLinkParameters(
          chips,
          nextState.dashboardGrids,
          nextModule,
          nextState.dashboardsV2.template?.entityId,
        );

      const urlPath = urlDeepLinkUtil.generateUrlPath({
        tenant: nextTenant,
        module: nextModule,
        chips,
        dateFilterId,
        dateFilterCustomStartDate: isCustomDateRange
          ? customDateRange.startDateISO
          : undefined,
        dateFilterCustomEndDate: isCustomDateRange
          ? customDateRange.endDateISO
          : undefined,
        columnFilters: dashboardGridParameters?.columnFilters,
        columnSorts: dashboardGridParameters?.columnSorts,
      });

      const isPathUnchanged =
        urlPath === lastTrackedPath || urlPath === window.location.search;
      if (isPathUnchanged) return;

      setIsNavigationEvent(false);
      setLastTrackedPath(urlPath);

      if (isNavigationEvent) return;
      if (!lastChip) return;
      if (lastChip.type === 'bu') return;

      router.push(urlPath);
    });

    return () => {
      appChangedSubscription.unsubscribe();
      changeUrlSubscription.unsubscribe();
    };
  }, [app, isNavigationEvent, lastTrackedPath, router, tenantConfig]);

  if (!isLoaded) return <LoadingFullScreen />;

  const dateFormat = tenantConfig?.dateFormat || 'MM/dd/yyyy';
  const timezone = tenantConfig?.timezone || 'Etc/UTC';
  const dateContext: IDateContext = {
    format: dateFormat,
    tenantTimezone: timezone,
  };

  const styles = {
    base: css({
      position: 'absolute',
      inset: 0,
      display: 'flex',
      flexDirection: 'row-reverse',
      boxSizing: 'border-box',
    }),
    appOuter: css({
      position: 'relative',
      flex: '1 1 auto',
      display: 'flex',
    }),
  };

  const elDebug = isDebug && (
    <Debug
      graphqlEndpoint={env.GRAPHQL_ENDPOINT()}
      version={env.VERSION()}
      tenant={tenantId}
    />
  );

  return (
    <DateContext.Provider value={dateContext}>
      <div css={styles.base}>
        {elDebug}
        <div css={styles.appOuter}>
          <App app={app} />
        </div>
      </div>
    </DateContext.Provider>
  );
};
