import {
  IQueryData,
  IQuery,
  IQuerySelection,
} from '../../api/api.queryBuilder/types.ts';
import {
  IRequestQueryDropdown,
  IQueryDropdownState,
  IShowQueryDropdown,
  IHideQueryDropdown,
  IQueryDropdownLoaded,
  IQueryAutocompleteDropdown,
  IQueryDropdownInlineFilterClicked,
} from './types.ts';

export function requestDropdown(
  data: IQueryData,
  query: IQuery,
  selection?: IQuerySelection,
): IRequestQueryDropdown {
  return {
    type: 'ui.shell/query/DROPDOWN_REQUEST',
    payload: { data, query, selection },
  };
}
export function showDropdown(
  dropdown: IQueryDropdownState,
): IShowQueryDropdown {
  return {
    type: 'ui.shell/query/DROPDOWN_SHOW',
    payload: {
      dropdown,
    },
  };
}

export function hideDropdown(): IHideQueryDropdown {
  return {
    type: 'ui.shell/query/DROPDOWN_HIDE',
    payload: {},
  };
}

export function dropdownLoaded(data: any): IQueryDropdownLoaded {
  return {
    type: 'ui.shell/query/DROPDOWN_LOADED',
    payload: { data },
  };
}

export function autocomplete(
  query: IQuery,
  editingChip?: { index: number },
): IQueryAutocompleteDropdown {
  return {
    type: 'ui.shell/query/DROPDOWN_AUTOCOMPLETE',
    payload: {
      query,
      editingChip,
    },
  };
}

export function inlineFilterClicked(
  id: string,
  data: IQueryData,
  query: IQuery,
  selection?: IQuerySelection,
): IQueryDropdownInlineFilterClicked {
  return {
    type: 'ui.shell/query/DROPDOWN_INLINE_FILTER_CLICKED',
    payload: { id, data, query, selection },
  };
}
