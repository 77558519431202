/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { statusFilterChange } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/actions.ts';
import {
  FormsBulkUploadValidateFileError,
  FormsBulkUploadValidateFileUploadRow,
  RowStatus,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/types.ts';
import {
  DataGrid,
  DataGridFilter,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  DataGridStatusToggleSortableBehavior,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
  IDataGridStatusToggleSortableBehaviorProps,
  RenderDataGridCell,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import {
  IGridRow,
  IRenderGridCellArgs,
} from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useShellDispatch } from '../../redux/store.ts';
import { toFormatArgs, tryStringFormat } from '../../utils/utils.ts';
import {
  StatusFilterCounts,
  StatusFilters,
} from './components/StatusFilters.tsx';

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridStatusToggleSortableBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridInfiniteScrollBehaviorProps &
    IDataGridStatusToggleSortableBehaviorProps &
    IDataGridProps
>;

export interface IBulkUploadFileResultsProps {
  hasNextPage?: boolean;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadedPage?: number;
  loadMoreText?: string;
  locale: TenantLocale;
  onColumnSort?: (columnId: string, direction: 'ASC' | 'DESC') => void;
  onLoadMore?: () => void;
  orderBy?: OrderBy[];
  rows?: FormsBulkUploadValidateFileUploadRow[];
  showFilters: boolean;
  statusCounts?: StatusFilterCounts;
  statusFilters?: RowStatus[];
}

/**
 * A grid view for results from a file validation or failed rows from an import run.
 */
export const BulkUploadFileResults: React.FC<IBulkUploadFileResultsProps> = ({
  hasNextPage,
  isLoading = false,
  isLoadingMore,
  loadedPage,
  loadMoreText,
  locale,
  onColumnSort,
  onLoadMore,
  orderBy,
  rows = [],
  showFilters,
  statusCounts,
  statusFilters = [],
}) => {
  const dispatch = useShellDispatch();
  const columns = useMemo(() => createColumns(locale), [locale]);

  const filters = useMemo<DataGridFilter[]>(
    () =>
      (orderBy ?? []).map(({ fieldName, direction }) => ({
        columnId: fieldName,
        sort: direction === 'Ascending' ? 'ASC' : 'DESC',
      })),
    [orderBy],
  );

  const handleStatusChange = useCallback(
    (to: RowStatus) => {
      dispatch(statusFilterChange(to));
    },
    [dispatch],
  );

  const elFilters = showFilters ? (
    <StatusFilters
      onFilterChange={handleStatusChange}
      statusCounts={statusCounts}
      statusFilters={statusFilters}
    />
  ) : undefined;

  const data = useMemo(() => rows.map(toGridRow), [rows]);

  const renderCell = useMemo(
    () => withStatusLabel(locale, rows),
    [locale, rows],
  );

  const cellTheme = useCallback(
    ({ columnIndex, row }: IRenderGridCellArgs) => {
      const column = columns[columnIndex];
      if (!rows.some(({ rowNumber }) => rowNumber === row.id)) return {};

      return column.id === 'status'
        ? {
            baseStyle: styles.statusBase,
            baseStyle_active: styles.statusBase,
          }
        : {};
    },
    [columns, rows],
  );

  return (
    <div css={styles.base}>
      {elFilters}
      <div css={styles.grid}>
        <Grid
          id={'BulkUploadFailedGrid'}
          columns={columns}
          data={data}
          filters={filters}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isLoadingPage={isLoadingMore}
          isScrollable={true}
          isVirtualized={false}
          loadedPage={loadedPage}
          loadMoreText={loadMoreText}
          onColumnSort={onColumnSort}
          onLoadPage={onLoadMore}
          renderCellContents={renderCell}
          cellThemeOverrides={cellTheme}
        />
      </div>
    </div>
  );
};

const toGridRow = (row: FormsBulkUploadValidateFileUploadRow): IGridRow => ({
  id: row.rowNumber,
  data: [
    row.rowNumber,
    row.associatedEntities.subject,
    row.associatedEntities.assignedTo,
    row.status,
  ],
});

const withStatusLabel =
  (
    locale: TenantLocale,
    rows?: FormsBulkUploadValidateFileUploadRow[],
  ): RenderDataGridCell =>
  (rowProps) => {
    if (rows && rowProps.columnIndex === COLUMN_INDEX_STATUS) {
      const row = rows.find(({ rowNumber }) => rowNumber === rowProps.row.id);
      return row ? toStatusItem(locale, row) : undefined;
    }
    return undefined;
  };

const toStatusItem = (
  locale: TenantLocale,
  row: FormsBulkUploadValidateFileUploadRow,
): string => {
  if (row.status === 'Success') {
    if (row.associatedEntities.automatedActionIds.length > 0)
      return `${str.humanize(row.status)}. ${row.associatedEntities.automatedActionIds.length} 
           ${str.plural('Smart Action', row.associatedEntities.automatedActionIds.length)} 
          will be automatically created.`;

    return str.humanize(row.status);
  }

  const rowOutcomes = row.errors.map((error) => {
    const localizedOutcome = locale.forms.bulkUpload[error.code];
    const args = toFormatArgs(locale, error.payload);

    return (
      (localizedOutcome &&
        tryStringFormat(error.code, localizedOutcome, args)) ??
      str.humanize(error.code)
    );
  });

  const withRowOutcomes = (text: string) =>
    rowOutcomes.length ? `${text} - ${rowOutcomes.join(' ')}` : text;

  const withLocalizedAction = (text: string) => {
    const errorCodeKey =
      `${row.status.toUpperCase()}_OUTCOME` as FormsBulkUploadValidateFileError['code'];

    const localizedAction = locale.forms.bulkUpload[errorCodeKey];
    return `${text} ${localizedAction}`;
  };

  const outcomeText = pipe(withRowOutcomes, withLocalizedAction)(row.status);

  return outcomeText ?? str.humanize(row.status);
};

const createColumns = (locale: TenantLocale): IDataGridColumn[] => [
  {
    id: 'rowNumber',
    label: 'Row',
    width: '*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'subject',
    label: locale.label.formSubject,
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'assignedTo',
    label: locale.label.formAssignedTo,
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    width: '5*',
    borderRight: true,
    isSortable: false,
  },
];
const COLUMN_INDEX_STATUS = 3;

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  statusBase: css({
    padding: '0 8px',
  }),
  grid: css({
    position: 'relative',
    height: 400,
  }),
};
