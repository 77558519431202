import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { GlobalReduxEpicDependenciesType } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, map, switchMap } from 'rxjs';
import {
  DashboardV2Template,
  template as templateData,
} from '../../data/index.ts';
import * as actions from '../actions.ts';
import * as types from '../types.ts';

export function clearTrackedDataIdsEpic(
  action$: Observable<types.IDashboardActionInitialize>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/INITIALIZE'),
    map(() => scrollSlice.clearTrackedDataIds()),
  );
}

export function initTemplateEpic(
  action$: Observable<types.IDashboardActionInitialize>,
  state$: StateObservable<types.DashboardV2ReduxState>,
  { client }: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/INITIALIZE'),
    switchMap(async (action) => {
      const {
        module,
        entity,
        entityId,
        templateId,
        loadSingleComponentId,
        clickThroughGrid,
      } = action.payload;

      const cachedTemplate =
        state$.value.dashboardsV2.cachedComponents[templateId]?.template;

      const isSameDashboardFromCache =
        !loadSingleComponentId &&
        cachedTemplate &&
        entityId === cachedTemplate.entityId;
      if (isSameDashboardFromCache) {
        return actions.templateLoaded(
          cachedTemplate as DashboardV2Template,
          undefined,
          true,
        );
      }

      try {
        const template = await templateData(
          client,
          module,
          entity,
          entityId,
          templateId,
        );

        const isCached =
          !loadSingleComponentId &&
          cachedTemplate?.definition === template.definition;
        return actions.templateLoaded(
          template,
          loadSingleComponentId,
          isCached,
          clickThroughGrid,
        );
      } catch (err) {
        log.error(err);
        return actions.errorMessage(err.toString());
      }
    }),
  );
}
