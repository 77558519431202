/** @jsxImportSource @emotion/react */
import { FormTemplate } from '@se/data/forms/types.ts';
import {
  exportTemplateCancel,
  exportTemplateClick,
  selectQuestion,
  selectSectionHeading,
  templateChange,
  templateQuestionsLoad,
  toggleShowHiddenFields,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/download-template/actions.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionButtons/ActionButtons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { DownloadFormTemplateDialog } from './DownloadFormTemplateDialog.tsx';
import { DialogOuter } from './components/DialogOuter.tsx';
import { QuestionSelectionEvent } from './types.ts';

export const DownloadFormTemplateDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();
  const exportableQuestions = useShellSelector(
    (state) => state.formBulkUpload.downloadTemplate.exportableQuestions,
  );

  const formLabel = useShellSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const isLoading = useShellSelector(
    (state) => state.formBulkUpload.downloadTemplate.isLoading,
  );

  const isError = useShellSelector(
    (state) => state.formBulkUpload.downloadTemplate.isError,
  );

  const selectedTemplate = useShellSelector(
    (state) => state.formBulkUpload.downloadTemplate.selectedTemplate,
  );

  const handleChangeSelectedTemplate = useCallback(
    (template: ISelectionListItem<IListItemLabel, FormTemplate>) =>
      dispatch(templateChange(template)),
    [dispatch],
  );

  const handleRetryLoadTemplate = useCallback(
    () => dispatch(templateQuestionsLoad()),
    [dispatch],
  );

  const handleSectionHeadingSelected = useCallback(
    ({ id, isSelected }: QuestionSelectionEvent) =>
      dispatch(selectSectionHeading(id, isSelected)),
    [dispatch],
  );

  const handleQuestionSelected = useCallback(
    ({ id, isSelected }: QuestionSelectionEvent) =>
      dispatch(selectQuestion(id, isSelected)),
    [dispatch],
  );

  const handleExportClick = useCallback(() => {
    if (!selectedTemplate || !exportableQuestions.keys) return;

    const selectedQuestions = exportableQuestions.filter(
      (key) => key.isSelected,
    );

    const template = {
      id: selectedTemplate.value.id,
      name: selectedTemplate.value.name,
      category: selectedTemplate.value.category.name,
    };

    dispatch(exportTemplateClick(template, selectedQuestions));
  }, [dispatch, exportableQuestions, selectedTemplate]);

  const handleShowHiddenFieldsSwitch = useCallback(
    () => dispatch(toggleShowHiddenFields()),
    [dispatch],
  );

  const actionButtons: IActionBarButton[] = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        isEnabled: true,
        onAction: () => dispatch(exportTemplateCancel()),
      },
      {
        id: 'Export',
        label: 'Export',
        isEnabled: Boolean(selectedTemplate && !isLoading),
        onAction: handleExportClick,
      },
    ],
    [dispatch, handleExportClick, isLoading, selectedTemplate],
  );

  return (
    <DialogOuter actions={actionButtons}>
      <DownloadFormTemplateDialog
        exportableQuestions={exportableQuestions}
        formLabel={formLabel}
        isError={isError}
        isTemplateLoading={isLoading}
        onQuestionSelected={handleQuestionSelected}
        onRetryLoadTemplate={handleRetryLoadTemplate}
        onSectionHeadingSelected={handleSectionHeadingSelected}
        onSelectedTemplateChange={handleChangeSelectedTemplate}
        onShowHiddenFieldsSwitch={handleShowHiddenFieldsSwitch}
        selectedTemplate={selectedTemplate}
      />
    </DialogOuter>
  );
};
