/** @jsxImportSource @emotion/react */
import { IBulkUploadCreatedInstance } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/click-through/types.ts';
import {
  DataGrid,
  DataGridClickThroughBehavior,
  DataGridFilter,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  DataGridStatusToggleSortableBehavior,
  IDataGridClickThroughProps,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
  IDataGridStatusToggleSortableBehaviorProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import moment from 'moment';
import { pipe } from 'ramda';
import { useMemo } from 'react';
import { BulkUploadClickThroughColumns } from '../types.ts';

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridStatusToggleSortableBehavior,
  DataGridClickThroughBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridInfiniteScrollBehaviorProps &
    IDataGridStatusToggleSortableBehaviorProps &
    IDataGridClickThroughProps &
    IDataGridProps
>;

export interface ISucceededClickThroughGridProps {
  createdInstances?: IBulkUploadCreatedInstance[];
  hasNextPage?: boolean;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadedPage?: number;
  loadMoreText?: string;
  onColumnSort: (columnId: string, direction: 'ASC' | 'DESC') => void;
  onInstanceNameClick?: (id: string) => void;
  onLoadMore?: () => void;
  orderBy?: OrderBy[];
}

/**
 * Displays successfully created instances from a bulk upload job.
 */
export const SucceededClickThroughGrid: React.FC<
  ISucceededClickThroughGridProps
> = ({
  createdInstances,
  hasNextPage,
  isLoading,
  isLoadingMore,
  loadedPage,
  loadMoreText,
  onColumnSort,
  onInstanceNameClick,
  onLoadMore,
  orderBy,
}) => {
  const data = useMemo(
    () => createdInstances?.map(toSucceededGridRow) ?? [],
    [createdInstances],
  );

  const filters = useMemo<DataGridFilter[]>(
    () =>
      (orderBy ?? []).map((o) => ({
        columnId: o.fieldName,
        sort: o.direction === 'Ascending' ? 'ASC' : 'DESC',
      })),
    [orderBy],
  );

  return (
    <Grid
      id={'BulkUploadSucceededGrid'}
      clickThroughColumnIds={CLICK_THROUGH_COLUMNS}
      columns={COLUMNS}
      data={data}
      filters={filters}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      isLoadingPage={isLoadingMore}
      isScrollable={true}
      isVirtualized={true}
      loadedPage={loadedPage}
      loadMoreText={loadMoreText}
      onClickThroughColumnClick={onInstanceNameClick}
      onColumnSort={onColumnSort}
      onLoadPage={onLoadMore}
    />
  );
};

const toSucceededGridRow = (instance: IBulkUploadCreatedInstance): IGridRow => {
  return {
    id: instance.id,
    data: [
      moment(instance.createdAt).format('D MMM YY, h:mm a'),
      instance.templateName,
      instance.assignedToName,
      instance.subjectName,
      moment(instance.updatedAt).format('D MMM YY, h:mm a'),
      str.humanize(instance.status, true),
    ],
  };
};

const CLICK_THROUGH_COLUMNS = [BulkUploadClickThroughColumns.TEMPLATE_NAME];

const COLUMNS: IDataGridColumn[] = [
  {
    id: BulkUploadClickThroughColumns.CREATED_AT,
    label: 'Created',
    width: '*',
    borderRight: true,
  },
  {
    id: BulkUploadClickThroughColumns.TEMPLATE_NAME,
    label: 'Name',
    width: '2*',
    isSortable: false,
    borderRight: true,
  },
  {
    id: BulkUploadClickThroughColumns.ASSIGNED_TO_NAME,
    label: 'Assigned To',
    isSortable: false,
    width: '2*',
  },
  {
    id: BulkUploadClickThroughColumns.SUBJECT_NAME,
    label: 'Subject',
    width: '2*',
    isSortable: false,
    borderRight: true,
  },
  {
    id: BulkUploadClickThroughColumns.UPDATED_AT,
    label: 'Updated',
    width: '*',
    borderRight: true,
  },
  {
    id: BulkUploadClickThroughColumns.STATUS,
    label: 'Status',
    width: '*',
    borderRight: true,
  },
];
