/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ActionBar } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionBar/ActionBar.tsx';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionButtons/ActionButtons.tsx';
import { ReactNode } from 'react';

interface IDialogOuterProps {
  actions: IActionBarButton[];
  children: ReactNode;
}

/**
 * Wraps child content with an action bar for content to be used in a shell modal dialog.
 */
export const DialogOuter: React.FC<IDialogOuterProps> = ({
  children,
  actions,
}) => (
  <div css={styles.base}>
    {children}
    <ActionBar actions={actions} />
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
};
