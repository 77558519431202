import { combineEpics, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { IShellAction, IShellState } from '../types.ts';
import { ISheetToolbarClickAction } from './types.ts';
import { removeSheet } from './actions.ts';

export const epics = combineEpics<IShellAction, IShellAction, IShellState>(
  sheetToolbarClick,
);

function sheetToolbarClick(action$: Observable<ISheetToolbarClickAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'SHEET_UP'),
    map(removeSheet),
  );
}
