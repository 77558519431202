import {
  FormsBulkUploadFileValidationErrorPayload,
  FormsBulkUploadFileValidationResult,
  FormsBulkUploadValidateFileUploadRow,
  RowStatus,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  FormatString,
  TenantLocale,
} from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import * as R from 'ramda';

export const bulkUploadImportRowHasStatus = (
  row: FormsBulkUploadValidateFileUploadRow,
  status: RowStatus,
): boolean => row.status === status;

export const bulkUploadImportRowIsDuplicate = (
  validationResult: FormsBulkUploadFileValidationResult,
): boolean =>
  Boolean(
    validationResult?.rows.find((row) =>
      row?.errors.find((error) => error.code === 'DUPLICATED_PREVIOUS_IMPORT'),
    ),
  );

export const tryStringFormat = (
  errorCode: string,
  format: FormatString,
  args: { [key: string]: string | {} },
) => {
  try {
    const computedFormat = typeof format === 'string' ? format : format(args);
    return str.format(computedFormat, args);
  } catch (error) {
    log.error(
      new Error(
        `Unable to format Bulk Upload error outcome of type '${errorCode}'. Error: ${error}`,
      ),
    );
    return undefined;
  }
};

export const toFormatArgs = (
  locale: TenantLocale,
  payload: FormsBulkUploadFileValidationErrorPayload[],
): { [key: string]: string | {} } => {
  if (!payload) return { locale };

  return payload
    .filter((x) => !R.isNil(x.value))
    .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { locale });
};
