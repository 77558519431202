import { goalsActionsGridSlice } from '@seeeverything/ui.forms/src/redux/goalsActionsGrid/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { concatAll, EMPTY, filter, map, Observable, of } from 'rxjs';
import { parentSectionIdFromState } from '../../util/util.ts';
import * as actions from '../actions.ts';
import {
  GlobalDashboardsState,
  IDashboardActionComponentViewDetail,
  IDashboardActionComponentViewEntity,
  IDashboardActionViewClickThroughGrid,
} from '../types.ts';

export const rememberScrollPositionEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalDashboardsState
>(
  fullSheetClickedEpic,
  viewEntityEpic,
  gridClickThroughEpic,
  goalsActionsGridFullSheetClickedEpic,
);

function fullSheetClickedEpic(
  action$: Observable<IDashboardActionComponentViewDetail>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_DETAIL'),
    map((action) => {
      const scrollId = parentSectionIdFromState(
        state$.value.dashboardsV2,
        action.payload.id,
      );
      return scrollId ? of(actions.rememberScrollToPosition(scrollId)) : EMPTY;
    }),
    concatAll(),
  );
}

function viewEntityEpic(
  action$: Observable<IDashboardActionComponentViewEntity>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY'),
    map((action) => {
      const scrollId = parentSectionIdFromState(
        state$.value.dashboardsV2,
        action.payload.componentId,
      );
      return scrollId ? of(actions.rememberScrollToPosition(scrollId)) : EMPTY;
    }),
    concatAll(),
  );
}

function gridClickThroughEpic(
  action$: Observable<IDashboardActionViewClickThroughGrid>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/GRID/VIEW_CLICK_THROUGH_GRID'),
    map((action) => {
      const scrollId = parentSectionIdFromState(
        state$.value.dashboardsV2,
        action.payload.sourceGrid.id,
      );
      return scrollId ? of(actions.rememberScrollToPosition(scrollId)) : EMPTY;
    }),
    concatAll(),
  );
}

function goalsActionsGridFullSheetClickedEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    filter(goalsActionsGridSlice.loadMoreClicked.match),
    map((action) => {
      const dashboardId = action.payload.dashboardId;

      const scrollId =
        dashboardId &&
        parentSectionIdFromState(state$.value.dashboardsV2, dashboardId);

      return scrollId ? of(actions.rememberScrollToPosition(scrollId)) : EMPTY;
    }),
    concatAll(),
  );
}
