/** @jsxImportSource @emotion/react */
import {
  clearImportFileValidation,
  fileRejected,
  fileUpload,
  importFile,
  showDuplicateRowImportDialog,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/actions.ts';
import {
  selectCreateAutomatedActions,
  selectWorkflow,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/select-workflow/actions.ts';
import { FormsBulkUploadWorkflowType } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/select-workflow/types.ts';
import { RadioGroupEventHandler } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/index.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import { useCallback, useMemo } from 'react';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import {
  bulkUploadImportRowHasStatus,
  bulkUploadImportRowIsDuplicate,
} from '../../utils/utils.ts';
import { BulkUploadFileImportDialog } from './BulkUploadFileImportDialog.tsx';
import { DialogOuter } from './components/DialogOuter.tsx';

export const BulkUploadFileImportDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const file = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.file,
  );

  const filteredRows = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.filteredRows,
  );

  const selectedTabId = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.selectedTabId,
  );

  const statusFilters = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.statusFilters,
  );

  const validatedFile = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.validatedFile,
  );

  const validationResult = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.validationResult,
  );

  const isSpinning = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.isSpinning,
  );

  const workflowType = useShellSelector(
    (state) => state.formBulkUpload.workflow?.workflowType,
  );

  const createAutomatedActions = useShellSelector(
    (state) => state.formBulkUpload.workflow?.createAutomatedActions,
  );

  const locale = useShellSelector((state) => state.tenantState.tenant.locale);

  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const includeAutomatedActions = useMemo(
    () => module === 'compliance',
    [module],
  );

  const hasFiles = Boolean(file);

  const hasDuplicateRows = useMemo(
    () => bulkUploadImportRowIsDuplicate(validationResult),
    [validationResult],
  );

  const isValid =
    validationResult?.errors.length === 0 &&
    !validationResult?.rows.every((row) =>
      bulkUploadImportRowHasStatus(row, 'Error'),
    );

  const handleFileUpload = useCallback(
    (files: File[]) => {
      dispatch(fileUpload(files[0]));
    },
    [dispatch],
  );

  const handleFileRejected = useCallback(
    (files: File[], maxFileSize: number) => {
      dispatch(fileRejected(files[0], maxFileSize));
    },
    [dispatch],
  );

  const handleWorkflowChange: RadioGroupEventHandler = useCallback(
    ({ id }) => dispatch(selectWorkflow(id as FormsBulkUploadWorkflowType)),
    [dispatch],
  );

  const handleCreateAutomatedActionsChange = useCallback(
    (to: boolean) => {
      dispatch(selectCreateAutomatedActions(to));
    },
    [dispatch],
  );

  const actionButtons: IActionBarButton[] = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        isEnabled: true,
        onAction: () => {
          dispatch(hideModalDialog());
          dispatch(clearImportFileValidation());
        },
      },
      {
        id: 'Import',
        label: 'Import',
        isEnabled: Boolean(!isSpinning && hasFiles && isValid),
        onAction: () => {
          if (!validatedFile) return;
          if (hasDuplicateRows) return dispatch(showDuplicateRowImportDialog());

          dispatch(
            importFile(
              validatedFile,
              'Create',
              workflowType,
              createAutomatedActions,
            ),
          );
        },
      },
    ],
    [
      createAutomatedActions,
      dispatch,
      hasDuplicateRows,
      hasFiles,
      isSpinning,
      isValid,
      validatedFile,
      workflowType,
    ],
  );

  return (
    <DialogOuter actions={actionButtons}>
      <BulkUploadFileImportDialog
        file={file}
        filteredRows={filteredRows}
        isSpinning={isSpinning}
        locale={locale}
        onFileRejected={handleFileRejected}
        onFileUpload={handleFileUpload}
        selectedTabId={selectedTabId}
        statusFilters={statusFilters}
        validationResult={validationResult}
        onWorkflowChange={handleWorkflowChange}
        isValidationResultLoaded={Boolean(validationResult)}
        onCreateAutomatedActionsChange={handleCreateAutomatedActionsChange}
        createAutomatedActions={createAutomatedActions}
        includeAutomatedActions={includeAutomatedActions}
      />
    </DialogOuter>
  );
};
