import { IDesignerDigitalContent } from '../../model/index.ts';
import { rejectedFileMetadata } from './fileMetadata.ts';
import { IFormFile } from '@seeeverything/ui.forms/src/redux/form-file/types.ts';
import {
  IFormsDesignerDigitalContentPackActivateAction,
  IFormsDesignerDigitalContentPackActivatedAction,
  IFormsDesignerDigitalContentPackActivateFailedAction,
  IFormsDesignerDigitalContentPackCreateAction,
  IFormsDesignerDigitalContentPackCreatedAction,
  IFormsDesignerDigitalContentPackCreateFailedAction,
  IFormsDesignerDigitalContentPackDeactivateAction,
  IFormsDesignerDigitalContentPackDeactivatedAction,
  IFormsDesignerDigitalContentPackDeactivateFailedAction,
  IFormsDesignerDigitalContentPackLoadAction,
  IFormsDesignerDigitalContentPackLoadedAction,
  IFormsDesignerDigitalContentPackLoadFailedAction,
  IFormsDesignerDigitalContentPackUpdateAction,
  IFormsDesignerDigitalContentPackUpdatedAction,
  IFormsDesignerDigitalContentPackUpdateFailedAction,
  IFormsDesignerFileDelete,
  IFormsDesignerFileDeleted,
  IFormsDesignerFileErrorDeleting,
  IFormsDesignerFileErrorUploading,
  IFormsDesignerFileRejected,
  IFormsDesignerFileUpload,
  IFormsDesignerFileUploaded,
} from './types.digitalContent.ts';

export function activateDigitalContentPack(
  id: string,
): IFormsDesignerDigitalContentPackActivateAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/ACTIVATE_PACK',
    payload: { id },
  };
}

export function activatedDigitalContentPack(
  id: string,
): IFormsDesignerDigitalContentPackActivatedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/ACTIVATED_PACK',
    payload: { id },
  };
}

export function activateDigitalContentPackFailed(
  error: string,
): IFormsDesignerDigitalContentPackActivateFailedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/ACTIVATE_PACK_FAILED',
    payload: { action: 'ACTIVATE_PACK', error },
  };
}

export function createDigitalContentPack(
  id: string,
  commentsEnabled: boolean,
  commentGuidance: string,
  description: string,
  publishDate: Date,
  summary: string,
): IFormsDesignerDigitalContentPackCreateAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/CREATE_PACK',
    payload: {
      id,
      commentsEnabled,
      commentGuidance,
      description,
      publishDate,
      summary,
    },
  };
}

export function createdDigitalContentPack(
  id: string,
): IFormsDesignerDigitalContentPackCreatedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/CREATED_PACK',
    payload: { id },
  };
}

export function createDigitalContentPackFailed(
  error: string,
): IFormsDesignerDigitalContentPackCreateFailedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/CREATE_PACK_FAILED',
    payload: { action: 'CREATE_PACK', error },
  };
}

export function deactivateDigitalContentPack(
  id: string,
): IFormsDesignerDigitalContentPackDeactivateAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/DEACTIVATE_PACK',
    payload: { id },
  };
}

export function deactivatedDigitalContentPack(
  id: string,
): IFormsDesignerDigitalContentPackDeactivatedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/DEACTIVATED_PACK',
    payload: { id },
  };
}

export function deactivateDigitalContentPackFailed(
  error: string,
): IFormsDesignerDigitalContentPackDeactivateFailedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/DEACTIVATE_PACK_FAILED',
    payload: { action: 'DEACTIVATE_PACK', error },
  };
}

export function loadDigitalContentPack(
  id: string,
): IFormsDesignerDigitalContentPackLoadAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/LOAD_PACK',
    payload: { id },
  };
}

export function loadedDigitalContentPack(
  draft: IDesignerDigitalContent,
): IFormsDesignerDigitalContentPackLoadedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/LOADED_PACK',
    payload: draft,
  };
}

export function loadDigitalContentPackFailed(
  error: string,
): IFormsDesignerDigitalContentPackLoadFailedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/LOAD_PACK_FAILED',
    payload: { action: 'LOAD_PACK', error },
  };
}

export function updateDigitalContentPack(
  id: string,
  commentsEnabled: boolean,
  commentGuidance: string,
  description: string,
  publishDate: Date,
  summary: string,
): IFormsDesignerDigitalContentPackUpdateAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/UPDATE_PACK',
    payload: {
      id,
      commentsEnabled,
      commentGuidance,
      description,
      publishDate,
      summary,
    },
  };
}

export function updatedDigitalContentPack(
  id: string,
): IFormsDesignerDigitalContentPackUpdatedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/UPDATED_PACK',
    payload: { id },
  };
}

export function updateDigitalContentPackFailed(
  error: string,
): IFormsDesignerDigitalContentPackUpdateFailedAction {
  return {
    type: 'ui.forms.designer/designer/digitalContent/UPDATE_PACK_FAILED',
    payload: { action: 'UPDATE_PACK', error },
  };
}

export function fileDelete(
  payload: IFormsDesignerFileDelete['payload'],
): IFormsDesignerFileDelete {
  return {
    type: 'ui.forms.designer/designer/digitalContent/FILE_DELETE',
    payload,
  };
}

export function fileDeleted(
  digitalContentPackId: string,
  fileId: string,
): IFormsDesignerFileDeleted {
  return {
    type: 'ui.forms.designer/designer/digitalContent/FILE_DELETED',
    payload: { digitalContentPackId, fileId },
  };
}

export function fileErrorDeleting(
  fileId: string,
  errorMessage: string,
): IFormsDesignerFileErrorDeleting {
  return {
    type: 'ui.forms.designer/designer/digitalContent/FILE_ERROR_DELETING',
    payload: { fileId, errorMessage },
  };
}

export function fileRejected(
  files: File[],
  maxSize: number,
): IFormsDesignerFileRejected {
  const toRejectedFile = rejectedFileMetadata(maxSize);
  return {
    type: 'ui.forms.designer/designer/digitalContent/FILE_REJECTED',
    payload: {
      rejections: files.map(toRejectedFile),
    },
  };
}

export function fileUpload(
  payload: IFormsDesignerFileUpload['payload'],
): IFormsDesignerFileUpload {
  return {
    type: 'ui.forms.designer/designer/digitalContent/FILE_UPLOAD',
    payload,
  };
}

export function fileUploaded(
  fileId: string,
  metadata: IFormFile,
): IFormsDesignerFileUploaded {
  return {
    type: 'ui.forms.designer/designer/digitalContent/FILE_UPLOADED',
    payload: { fileId, metadata },
  };
}

export function fileErrorUploading(
  fileId: string,
  errorMessage: string,
): IFormsDesignerFileErrorUploading {
  return {
    type: 'ui.forms.designer/designer/digitalContent/FILE_ERROR_UPLOADING',
    payload: { fileId, errorMessage },
  };
}
