import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import {
  DashboardTeam,
  DashboardTemplatePerson,
  DashboardTemplateTeam,
  DashboardV2Subject,
  DashboardV2Template,
} from './types.ts';

export const template = async (
  client: IGraphQLClient,
  module: ModuleType,
  entity: DashboardV2Subject,
  entityId: string,
  templateId: string,
): Promise<DashboardV2Template> => {
  const data = await query(client, module, entity, entityId, templateId);
  if (!data) {
    throw new Error(
      `Unable to retrieve ${entity} template for id '${templateId}'.`,
    );
  }
  if (!data.definition) {
    throw new Error(`Definition with id ${templateId} is not valid`);
  }

  return data;
};

type EntityTemplate = {
  definition: string;
  id: string;
  type: 'Person' | 'Team';
  entityId: string;
};

const query = async (
  client: IGraphQLClient,
  module: ModuleType,
  entity: DashboardV2Subject,
  entityId: string,
  templateId: string,
): Promise<DashboardV2Template> => {
  return entity === 'person'
    ? queryPerson(client, module, entityId, templateId)
    : queryTeam(client, module, entityId, templateId);
};

const queryTeam = async (
  client: IGraphQLClient,
  module: ModuleType,
  entityId: string,
  templateId: string,
): Promise<DashboardTemplateTeam> => {
  const response = await client.query<{
    dashboards: {
      team: {
        template: EntityTemplate;
        team: DashboardTeam;
      };
    };
  }>({
    fetchPolicy: 'cache-first',
    query: gql`
      query DashboardTeamTemplate($entityId: ID!, $templateId: ID!) {
        dashboards {
          team(id: $entityId) {
            template(id: $templateId) {
              id
              type
              definition
              entityId
            }
            team {
              id
              name
              path
              membershipCount
              hasChildTeams
            }
          }
        }
      }
    `,
    variables: { module, entityId, templateId },
  });

  return {
    id: response.data.dashboards.team.template.id,
    kind: 'team',
    entityId: response.data.dashboards.team.template.entityId,
    definition: str.decodeBase64(
      response.data.dashboards.team.template.definition,
      false,
    ),
    entity: response.data.dashboards.team.team,
  };
};

const queryPerson = async (
  client: IGraphQLClient,
  module: ModuleType,
  entityId: string,
  templateId: string,
): Promise<DashboardTemplatePerson> => {
  const response = await client.query<{
    dashboards: {
      person: {
        template: EntityTemplate;
      };
    };
  }>({
    fetchPolicy: 'cache-first',
    query: gql`
      query DashboardPersonTemplate($entityId: ID!, $templateId: ID!) {
        dashboards {
          person(id: $entityId) {
            template(id: $templateId) {
              id
              type
              definition
              entityId
            }
          }
        }
      }
    `,
    variables: { module, entityId, templateId },
  });

  return {
    id: response.data.dashboards.person.template.id,
    kind: 'person',
    entityId: response.data.dashboards.person.template.entityId,
    definition: str.decodeBase64(
      response.data.dashboards.person.template.definition,
      false,
    ),
  };
};
