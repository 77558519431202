import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';

type ValidationOverwriteExistingPayload = {
  existingTemplate?: string;
  definitionJson?: string;
};

type ValidationNewPayload = {
  name?: string;
  category?: string;
  definitionJson?: string;
};

export type FormsDesignerReduxImportTemplatesState = {
  selectedMethod: 'NEW' | 'OVERWRITE_EXISTING';
  isSpinning: boolean;
  generalError?: string;
  createNewInputs: {
    name?: string;
    category?: ISelectionListItem;
    definitionJson?: string;
    validationErrors: {
      name?: string;
      category?: string;
      definitionJson?: string;
    };
  };
  overwriteExistingInputs: {
    existingTemplate?: ISelectionListItem;
    definitionJson?: string;
    validationErrors: {
      existingTemplate?: string;
      definitionJson?: string;
    };
  };
};

const DEFAULT_STATE: FormsDesignerReduxImportTemplatesState = {
  createNewInputs: { validationErrors: {} },
  isSpinning: false,
  overwriteExistingInputs: { validationErrors: {} },
  selectedMethod: 'NEW',
};

const slice = createSlice({
  name: 'libs/formsDesigner/templates/import',
  initialState: DEFAULT_STATE,
  reducers: {
    cancelImport(state) {
      state.createNewInputs = { validationErrors: {} };
      state.isSpinning = false;
      state.overwriteExistingInputs = { validationErrors: {} };
      state.selectedMethod = 'NEW';
      state.generalError = undefined;
    },
    importTemplate(state) {
      state.isSpinning = true;
      state.generalError = undefined;
    },
    importedTemplate(state) {
      state.isSpinning = false;
      state.generalError = undefined;
    },
    importTemplateFailed(state, action: PayloadAction<string>) {
      state.isSpinning = false;
      state.generalError = action.payload;
    },
    setSelectedMethod(
      state,
      action: PayloadAction<'NEW' | 'OVERWRITE_EXISTING'>,
    ) {
      state.selectedMethod = action.payload;
      state.generalError = undefined;
    },
    updateNameFieldOnNew(state, action: PayloadAction<string>) {
      state.createNewInputs.name = action.payload;
      state.createNewInputs.validationErrors.name = undefined;
      state.generalError = undefined;
    },
    updateCategoryFieldOnNew(state, action: PayloadAction<ISelectionListItem>) {
      state.createNewInputs.category = action.payload;
      state.createNewInputs.validationErrors.category = undefined;
      state.generalError = undefined;
    },
    updateDefinitionFieldOnNew(state, action: PayloadAction<string>) {
      state.createNewInputs.definitionJson = action.payload;
      state.createNewInputs.validationErrors.definitionJson = undefined;
      state.generalError = undefined;
    },
    updateExistingTemplateFieldOnOverwrite(
      state,
      action: PayloadAction<ISelectionListItem>,
    ) {
      state.overwriteExistingInputs.existingTemplate = action.payload;
      state.overwriteExistingInputs.validationErrors.existingTemplate =
        undefined;
      state.generalError = undefined;
    },
    updateDefinitionFieldOnOverwrite(state, action: PayloadAction<string>) {
      state.overwriteExistingInputs.definitionJson = action.payload;
      state.overwriteExistingInputs.validationErrors.definitionJson = undefined;
      state.generalError = undefined;
    },
    validationErrorsOnNew(state, action: PayloadAction<ValidationNewPayload>) {
      const { category, definitionJson, name } = action.payload;
      state.createNewInputs.validationErrors.category = category;
      state.createNewInputs.validationErrors.definitionJson = definitionJson;
      state.createNewInputs.validationErrors.name = name;
    },
    validationErrorsOnOverwriteExisting(
      state,
      action: PayloadAction<ValidationOverwriteExistingPayload>,
    ) {
      const { existingTemplate, definitionJson } = action.payload;
      state.overwriteExistingInputs.validationErrors.existingTemplate =
        existingTemplate;
      state.overwriteExistingInputs.validationErrors.definitionJson =
        definitionJson;
    },
  },
});

export const {
  cancelImport,
  importTemplate,
  importedTemplate,
  importTemplateFailed,
  setSelectedMethod,
  updateNameFieldOnNew,
  updateCategoryFieldOnNew,
  updateDefinitionFieldOnNew,
  updateExistingTemplateFieldOnOverwrite,
  updateDefinitionFieldOnOverwrite,
  validationErrorsOnNew,
  validationErrorsOnOverwriteExisting,
} = slice.actions;
export const reducer = slice.reducer;
