/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  cancelDuplicateRowImport,
  importFile,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/actions.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionButtons/ActionButtons.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMemo } from 'react';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { DialogOuter } from '../DialogOuter/index.ts';

export const BulkUploadDuplicateRowWarningDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const file = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation.validatedFile,
  );

  const workflowType = useShellSelector(
    (state) => state.formBulkUpload.workflow.workflowType,
  );

  const createAutomatedActions = useShellSelector(
    (state) => state.formBulkUpload.workflow.createAutomatedActions,
  );

  const isSpinning = useShellSelector(
    (state) => state.formBulkUpload.importFileValidation?.isSpinning,
  );

  const actionButtons: IActionBarButton[] = useMemo(
    () => [
      {
        id: 'Back',
        label: 'Back',
        isEnabled: !isSpinning,
        onAction: () => {
          dispatch(hideModalDialog());
          dispatch(cancelDuplicateRowImport('IMPORT_BULK_UPLOAD_FILE'));
        },
      },
      {
        id: 'Continue',
        label: 'Continue',
        isEnabled: !isSpinning,
        onAction: () => {
          dispatch(
            importFile(file, 'Create', workflowType, createAutomatedActions),
          );
        },
      },
    ],
    [dispatch, file, isSpinning, workflowType, createAutomatedActions],
  );

  return (
    <DialogOuter actions={actionButtons}>
      <div css={styles.base}>
        {isSpinning ? (
          <Spinner center={true} />
        ) : (
          <div css={styles.content}>
            <Text size={14} weight={900}>
              {'Possible Duplicates'}
            </Text>
            <Text>
              {
                'Some rows have duplicate information to previously loaded data. Continue?'
              }
            </Text>
          </div>
        )}
      </div>
    </DialogOuter>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    minWidth: 350,
    minHeight: 100,
    padding: 25,
    position: 'relative',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
};
