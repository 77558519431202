/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  DataGrid,
  DataGridDownloadBehavior,
  DataGridIsLoadingBehavior,
  DataGridStatusToggleSortableBehavior,
  IDataGridColumn,
  IDataGridDownloadBehaviorProps,
  IDataGridProps,
  IDataGridStatusToggleSortableBehaviorProps,
  RenderDataGridCell,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import {
  GridClickEventHandler,
  IGridRow,
} from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/index.ts';
import { useDateContext } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { pipe } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formEditGoalSlice } from '../../redux/form-edit-goal/index.ts';
import { formActionSlice } from '../../redux/formAction/index.ts';
import { goalsActionsGridSlice } from '../../redux/goalsActionsGrid/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { fetchGoalsAndActions } from './query/index.ts';
import { FormAction, FormGoal } from './types.ts';
import {
  goalsActionsGridLabel,
  isChildAction,
  isGoal,
  toGridSort,
} from './util.ts';

type GoalActionRow = IGridRow & { record: FormGoal | FormAction };

const Grid = pipe(
  DataGridStatusToggleSortableBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridStatusToggleSortableBehaviorProps & IDataGridProps
>;

const GridWithDownload = pipe(
  DataGridStatusToggleSortableBehavior,
  DataGridIsLoadingBehavior,
  DataGridDownloadBehavior,
)(DataGrid) as React.FC<
  IDataGridStatusToggleSortableBehaviorProps &
    IDataGridDownloadBehaviorProps &
    IDataGridProps
>;

export interface IGoalsActionsGridContainerProps {
  dashboardId?: string;
  dateRangeLabel: string;
  endDate: string;
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  inViewport: boolean;
  sheetType: 'DASHBOARD' | 'FULL_SHEET';
  startDate: string;
}

export const GoalsActionsGridContainer: React.FC<
  IGoalsActionsGridContainerProps
> = ({
  dashboardId,
  dateRangeLabel,
  endDate,
  entityId,
  entityType,
  inViewport,
  sheetType,
  startDate,
}) => {
  const { client } = useGraphQL();
  const dispatch = useFormsDispatch();

  const [isQueryEnabled, setIsQueryEnabled] = useState(inViewport);
  useEffect(() => {
    if (inViewport) setIsQueryEnabled(true);
  }, [inViewport]);

  const reloadFlag = useFormsSelector(
    (state) => state.formGoalActionsGrid.reloadFlag,
  );

  const dueByDirection = useFormsSelector(
    (state) => state.formGoalActionsGrid.orderDueByDirection,
  );
  const orderBy = useFormsSelector(
    (state) => state.formGoalActionsGrid.orderBy,
  );

  const actionLabel = useFormsSelector((state) =>
    str.titleCase(state.tenantState.tenant.locale.label.action),
  );

  const issueActionLabel = useFormsSelector((state) =>
    str.titleCase(state.tenantState.tenant.locale.label.issueAction),
  );

  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const showCompleted = useFormsSelector(
    (state) => state.formGoalActionsGrid.showCompleted,
  );

  const columns = useMemo(
    () => (module !== 'coaching' ? COLUMNS_NO_TYPE : COLUMNS),
    [module],
  );

  const dateContext = useDateContext();

  const { data, isLoading } = useQuery({
    queryKey: [
      {
        key: 'goalsAndActions' as const,
        actionLabel,
        dueByDirection,
        endDate,
        entityId,
        entityType,
        issueActionLabel,
        module,
        orderBy,
        reloadFlag,
        sheetType,
        showCompleted,
        startDate,
      },
    ],
    queryFn: ({ queryKey }) =>
      fetchGoalsAndActions(client, queryKey[0], dateContext.tenantTimezone),
    enabled: isQueryEnabled,
    placeholderData: keepPreviousData,
  });

  const [hasLoadedWhileInViewport, setHasLoadedWhileInViewport] =
    useState(false);

  useEffect(() => {
    if (hasLoadedWhileInViewport) return;
    if (!inViewport) return;
    if (!isQueryEnabled) return;
    if (isLoading) return;
    setHasLoadedWhileInViewport(true);
  }, [isLoading, hasLoadedWhileInViewport, inViewport, isQueryEnabled]);

  const rowData = useMemo<GoalActionRow[]>(() => data?.rowData ?? [], [data]);

  const cellThemeOverrides = useCallback<IDataGridProps['cellThemeOverrides']>(
    ({ rowIndex, columnIndex }) => {
      if (!rowData.length) return {};

      const record = rowData[rowIndex].record;
      const nextRecord = rowData[rowIndex + 1]?.record;

      const baseTheme = {
        borderTop: isChildAction(record) ? 'none' : undefined,
        borderBottom: isChildAction(nextRecord) ? 'none' : undefined,
      };

      return record.status === 'Overdue' && columns[columnIndex].id === 'dueBy'
        ? { ...baseTheme, bgColor: '#F9C26530' }
        : baseTheme;
    },
    [rowData, columns],
  );

  const showDialog = useCallback(
    (record: FormGoal | FormAction) => () => {
      if (isGoal(record))
        return dispatch(
          formEditGoalSlice.loadGoal({
            goalId: record.id,
            source: { type: 'GOALS_AND_ACTIONS_GRID' },
          }),
        );

      dispatch(
        formActionSlice.load({
          actionId: record.id,
          source: { type: 'GOALS_AND_ACTIONS_GRID' },
        }),
      );
    },
    [dispatch],
  );

  const showDialogOnRowDoubleClicked = useCallback<GridClickEventHandler>(
    (e) => {
      const rowId = e.rowId;
      const row = rowData.find((r) => r.id === rowId);
      const record = row?.record;
      if (record) showDialog(record);
    },
    [rowData, showDialog],
  );

  const renderLinkOnDescription = useCallback(
    (record: FormGoal | FormAction) => {
      return (
        <div
          css={
            isChildAction(record)
              ? styles.descriptionOuterPadding
              : styles.descriptionOuter
          }
        >
          <TextLink
            cursor={'pointer'}
            size={14}
            ellipsis={true}
            color={COLORS.BLUE}
            style={styles.descriptionText}
            onClick={showDialog(record)}
          >
            {record.description}
          </TextLink>
        </div>
      );
    },
    [showDialog],
  );

  const renderCustomColumns = useCallback<RenderDataGridCell<GoalActionRow>>(
    ({ columnIndex, row }) => {
      if (!row.record) return;

      const columnId = columns[columnIndex].id;

      if (columnId === 'description')
        return renderLinkOnDescription(row.record);
    },
    [columns, renderLinkOnDescription],
  );

  const updateOrderBy = useCallback(
    (columnId: string, direction: 'ASC' | 'DESC') => {
      const column = columns.find((c) => c.id === columnId);
      if (!column?.isSortable) return;

      return dispatch(
        goalsActionsGridSlice.columnOrderBy({
          columnId,
          direction: direction === 'ASC' ? 'Ascending' : 'Descending',
        }),
      );
    },
    [columns, dispatch],
  );

  const handleToggleShowCompleted = useCallback(
    (columnId: string, toggleId: string, to: boolean) => {
      if (columnId !== 'status') return;
      if (toggleId !== 'SHOW_COMPLETED') return;

      dispatch(goalsActionsGridSlice.showCompleted(to));
    },
    [dispatch],
  );

  const filters = useMemo(() => {
    const orderByChanged =
      orderBy && orderBy.fieldName !== 'status'
        ? {
            columnId: orderBy.fieldName,
            sort: toGridSort(orderBy.direction),
          }
        : undefined;

    const dueByOrder = {
      columnId: 'dueBy',
      sort: toGridSort(dueByDirection),
    };

    const statusFilter = {
      columnId: 'status',
      toggles: [
        {
          columnId: 'status',
          id: 'SHOW_COMPLETED',
          label: 'Show Completed',
          isToggled: showCompleted,
        },
      ],
      sort:
        orderBy?.fieldName === 'status'
          ? toGridSort(orderBy.direction)
          : undefined,
    };

    return [orderByChanged, dueByOrder, statusFilter].filter(Boolean);
  }, [dueByDirection, orderBy, showCompleted]);

  const loadMoreClicked = useCallback(() => {
    if (dashboardId)
      dispatch(
        goalsActionsGridSlice.loadMoreClicked({
          dashboardId,
          dateRangeLabel,
          endDate,
          entityId,
          entityType,
          startDate,
        }),
      );
  }, [
    dashboardId,
    dateRangeLabel,
    dispatch,
    endDate,
    entityId,
    entityType,
    startDate,
  ]);

  const downloadGridClicked = useCallback(
    () =>
      dispatch(
        goalsActionsGridSlice.downloadGrid({
          columnHeaderLabels: columns.map((column) => column.label),
          title: goalsActionsGridLabel({ module, dateRangeLabel, actionLabel }),
          queryArgs: {
            key: 'goalsAndActions' as const,
            actionLabel,
            dueByDirection,
            endDate,
            entityId,
            entityType,
            issueActionLabel,
            module,
            orderBy,
            sheetType: 'FULL_SHEET',
            showCompleted,
            startDate,
          },
        }),
      ),
    [
      actionLabel,
      columns,
      dateRangeLabel,
      dispatch,
      dueByDirection,
      endDate,
      entityId,
      entityType,
      issueActionLabel,
      module,
      orderBy,
      showCompleted,
      startDate,
    ],
  );

  return sheetType === 'DASHBOARD' ? (
    <GridWithDownload
      id={'GoalsActionsGridDashboard'}
      cellThemeOverrides={cellThemeOverrides}
      columns={columns}
      data={rowData}
      filters={filters}
      isLoading={isLoading || !hasLoadedWhileInViewport}
      isVirtualized={false}
      loadMoreText={
        data?.hasNextPage
          ? `Load more (${data?.totalCount} total rows)`
          : `Expand full screen`
      }
      onColumnStatusToggled={handleToggleShowCompleted}
      onColumnSort={updateOrderBy}
      onDownloadClicked={downloadGridClicked}
      onDoubleClick={showDialogOnRowDoubleClicked}
      onLoadMore={loadMoreClicked}
      renderCellContents={renderCustomColumns}
    />
  ) : (
    <Grid
      id={'GoalsActionsGridFullSheet'}
      cellThemeOverrides={cellThemeOverrides}
      columns={columns}
      data={rowData}
      filters={filters}
      isVirtualized={false}
      isLoading={isLoading || !hasLoadedWhileInViewport}
      onColumnStatusToggled={handleToggleShowCompleted}
      onColumnSort={updateOrderBy}
      onDoubleClick={showDialogOnRowDoubleClicked}
      renderCellContents={renderCustomColumns}
    />
  );
};

export const COLUMNS: IDataGridColumn[] = [
  {
    id: 'createdAt',
    label: 'Created',
    borderRight: true,
    isSortable: true,
    width: '3*',
  },
  {
    id: 'type',
    label: 'Type',
    borderRight: true,
    isSortable: true,
    width: '3*',
  },
  {
    id: 'description',
    label: 'Description',
    borderRight: true,
    isSortable: false,
    width: '10*',
  },
  {
    id: 'assignedTo.name',
    label: 'Assignee',
    borderRight: true,
    isSortable: true,
    width: '3*',
  },
  {
    id: 'dueBy',
    label: 'Due',
    borderRight: true,
    isSortable: true,
    width: '3*',
  },
  {
    id: 'status',
    label: 'Status',
    borderRight: true,
    isSortable: true,
    isFilterable: true,
    width: '3*',
    filterToggles: [
      {
        id: 'Completed',
        columnId: 'status',
        label: 'Show completed',
        isToggled: false,
      },
    ],
  },
];

export const COLUMNS_NO_TYPE = COLUMNS.filter((column) => column.id !== 'type');

const styles = {
  descriptionOuter: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 4,
  }),
  descriptionOuterPadding: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 16,
  }),
  descriptionText: css({
    ':hover': {
      textDecoration: 'underline',
    },
  }),
};
