import { FormsBulkUploadWorkflowType } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/select-workflow/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';

export interface IBulkUploadFileImportOptions {
  file: File;
  workflowType: FormsBulkUploadWorkflowType;
  createAutomatedActions: boolean;
}

export async function importFile(
  uploadClient: IGraphQLClient,
  options?: IBulkUploadFileImportOptions,
): Promise<boolean> {
  if (!options) {
    return false;
  }

  const id = uuid.generate();
  const { file, workflowType, createAutomatedActions } = options;

  try {
    const response =
      await uploadClient.mutate<GraphQLFormsBulkImportFileResponse>({
        mutation: createBulkInstances,
        variables: {
          id,
          file,
          workflowType,
          createAutomatedActions,
        },
      });

    return Boolean(response.data.forms.createBulkInstances.ok);
  } catch (err) {
    log.error(
      new Error(`GraphQL Error: Failed to import bulk upload file. ${err}`),
    );
    return false;
  }
}

const createBulkInstances = gql`
  mutation CreateBulkInstances(
    $id: ID!
    $file: Upload!
    $workflowType: FormsBulkUploadWorkflowType!
    $createAutomatedActions: Boolean!
  ) {
    forms {
      createBulkInstances(
        input: {
          id: $id
          file: $file
          workflowType: $workflowType
          createAutomatedActions: $createAutomatedActions
        }
      ) {
        ok
      }
    }
  }
`;

interface GraphQLFormsBulkImportFileResponse {
  forms: {
    createBulkInstances: {
      ok: boolean;
    };
  };
}
