/** @jsxImportSource @emotion/react */
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { LoadMore } from '@seeeverything/ui.primitives/src/components/LoadMore/LoadMore.tsx';
import {
  ISelectionListColumn,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { QueryRouter } from '@seeeverything/ui.shell/src/api/api.queryBuilder/QueryRouter.ts';
import { IQueryRequest } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import { dropdownLoaded } from '@seeeverything/ui.shell/src/redux/query/actions.dropdown.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import * as R from 'ramda';
import { filter } from 'rxjs';
import { getInitializedApp } from '../../../../app.ts';
import { formInstancesPaged } from '../../../../data/data.formInstances.ts';
import { IInstance } from '../../../../data/types.ts';
import {
  MAX_HEIGHT,
  calculateDropdownHeight,
  lastChipFromQuery,
} from '../helpers.tsx';

export const router = new QueryRouter();

/**
 * DROPDOWN
 */
router
  .dropdown('forms', /\/forms[^/]*/, 'forms dropdown')
  .pipe(
    filter(({ req }) =>
      Boolean(
        !req.isHandled &&
          ((req.context && req.context.id === 'forms') ||
            (req.selection &&
              req.selection.type === 'EDITING' &&
              req.selection.chip.type === 'forms')),
      ),
    ),
  )
  .subscribe(({ req, res }) => {
    const chip = req.selection && req.selection.chip;
    const searchFilter = (chip && chip.label) || '';
    res.dropdown({
      component: 'LIST',
      width: 400,
      height: 50,
      props: formsFromServer(req, searchFilter), // Promise.
    });
  });

/**
 * AUTOCOMPLETE
 */
router
  .autocomplete('forms', /\/forms[^/]*$/, 'Forms autocomplete')
  .pipe(
    filter(({ req }) =>
      Boolean(
        !req.isHandled &&
          req.context.value &&
          req.selection &&
          req.selection.type === 'EDITING' &&
          req.selection.chip.type === 'forms',
      ),
    ),
  )
  .subscribe(({ req, res }) => {
    const { query, context, selection } = req;
    const { id, label } = context;
    if (id && selection) {
      const chips = query.chips.slice(0, selection.chipIndex); // Remove anything beyond this current chip.
      res.query({
        chips: [
          ...chips,
          {
            type: 'forms',
            value: id,
            label: label.text,
          },
        ],
        filter: '',
      });
    } else {
      // No ID for a form supplied, cancel the operation.
      res.cancel();
    }
  });

const loadMore =
  (
    request: IQueryRequest,
    searchFilter: string,
    existingItems: ISelectionListItem[],
    nextPageNum: number,
  ) =>
  async () => {
    const app = getInitializedApp();
    const next = await formsFromServer(
      request,
      searchFilter,
      existingItems,
      nextPageNum,
    );

    app.store.dispatch(dropdownLoaded(next));
  };

/**
 * INTERNAL
 */
async function formsFromServer(
  request: IQueryRequest,
  searchFilter: string,
  existingItems: ISelectionListItem[] = [],
  nextPageNum = 1,
) {
  const chip = lastChipFromQuery(request);
  const personId = chip && chip.type === 'people' ? chip.value : undefined;

  const serverResponse = await formInstancesPaged(
    nextPageNum,
    personId,
    searchFilter,
  );

  const instances = serverResponse.data;

  // Convert into list items.
  const items = instances.map((item: IInstance) => {
    const { id, formName, createdDateLabel } = item;

    const text = `${formName} - ${createdDateLabel}`;

    const description = parseDescription(item);

    return {
      id,
      icon: Icons.document,
      value: id,
      content: {
        text,
        description,
        maxWidth: 331,
      },
    };
  });

  const lastExistingItem = R.last(existingItems);
  if (lastExistingItem && lastExistingItem.id === 'load-more') {
    existingItems.pop();
  }

  const allItems = [...existingItems, ...items];

  if (serverResponse.hasNextPage) {
    allItems.push({
      id: 'load-more',
      content: (
        <LoadMore
          onInView={loadMore(
            request,
            searchFilter,
            allItems,
            serverResponse.currentPage + 1,
          )}
        />
      ),
    });
  }

  const height = calculateDropdownHeight(allItems);

  const column: ISelectionListColumn = {
    isScrollable: height > MAX_HEIGHT,
    items: allItems,
  };
  return {
    columnTotal: 1,
    column,
    height: Math.min(height, MAX_HEIGHT),
  };
}

/**
 * Determines the `description` part for the form selection list item.
 */
const parseDescription = (item: IInstance) => {
  // Form status for display.
  const formStatusDisplay = str.humanize(item.status);

  // Form `assignedTo` and `subject` for display.
  const assignedToPart = item.assignedTo ? item.assignedTo.name : '';
  const ampersandPart = item.assignedTo && item.subject ? ' & ' : '';
  const subjectPart = item.subject ? item.subject.name : '';

  const formParticipantsDisplay =
    assignedToPart + ampersandPart + subjectPart || 'No assignees';

  /* e.g.
   * In Progress: Barry Quuxford & Foobert Barrison.
   * Created: No assignees.
   */
  return `${formStatusDisplay}: ${formParticipantsDisplay}.`;
};
