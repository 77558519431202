/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { IDesignerCommonProps } from '../types.ts';
import { Bullet } from '../Bullet/index.ts';
import { handleChangeEvent } from '../util.ts';
import { Checkbox } from '@seeeverything/ui.primitives/src/components/Checkbox/Checkbox.tsx';

export interface ISwitchProps extends IDesignerCommonProps {
  isEnabled?: boolean;
  descriptionToEnable: string;
  descriptionToDisable: string;
  editingLabel: string;
}

export class Switch extends React.Component<ISwitchProps, {}> {
  public render() {
    const {
      id,
      isEnabled = true,
      showBoundaryLines,
      showBullet = true,
      style,
      onChange,
      descriptionToEnable,
      descriptionToDisable,
      editingLabel,
    } = this.props;

    const computedStyles = {
      base: css({
        border: showBoundaryLines
          ? `dashed 1px ${color.format(-0.2)}`
          : 'solid 1px rgba(0, 0, 0, 0)',
        borderRadius: showBoundaryLines ? 8 : undefined,
      }),
    };

    const elDesignerContent = (
      <div css={styles.editingContent}>
        <Checkbox
          label={editingLabel}
          isChecked={isEnabled === true}
          isEnabled={true}
          helperText={isEnabled ? descriptionToDisable : descriptionToEnable}
          style={css({ margin: '0 -3px' })}
          onCheck={handleChangeEvent(id, 'SWITCH', onChange)}
        />
      </div>
    );

    const elOuter = <div css={styles.content}>{elDesignerContent}</div>;

    return (
      <div css={[styles.outer, style]}>
        {showBullet && <Bullet />}
        <div css={[styles.base, computedStyles.base]}>{elOuter}</div>
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 60,
    padding: '8px 20px 6px 40px',
    marginBottom: 5,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  }),
  editingContent: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};
