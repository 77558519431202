/** @jsxImportSource @emotion/react */
import { memo, useCallback } from 'react';
import { GridCellProps } from 'react-virtualized';
import { ListItemSelectedEventHandler } from '../ListSelectionBehavior/types.ts';
import { VirtualizedScroll } from '../VirtualizedScroll/VirtualizedScroll.tsx';
import * as types from './types.ts';
const isEqual = require('react-fast-compare');

type ItemHeightFunc = ({ index }: { index: number }) => number;

export interface IInnerListProps<V extends {} = undefined> {
  items: types.IGridRow<V>[];
  renderItem: types.RenderGridRow;
  itemHeight: number | ItemHeightFunc;
  onClick?: types.GridClickEventHandler;
  onDoubleClick?: types.GridClickEventHandler;

  // From: <ListSelectionBehavior>.
  isFocused?: boolean;
  selectedId?: number | string;
  onSelect?: ListItemSelectedEventHandler;
}

const shouldSkipRender = (
  prevProps: IInnerListProps,
  nextProps: IInnerListProps,
) =>
  isEqual(prevProps.items, nextProps.items) &&
  prevProps.selectedId === nextProps.selectedId &&
  prevProps.isFocused === nextProps.isFocused &&
  prevProps.renderItem === nextProps.renderItem;

/**
 * A list of items with virtualized rendering.
 * - Always fills parent
 * - No programmatic scrolling support
 * - Item height must be provided
 */
const View = <V extends {} = undefined>({
  items,
  renderItem,
  onSelect,
  itemHeight,
}: IInnerListProps<V>) => {
  const renderCell = useCallback(
    (props: GridCellProps) => {
      const data = items[props.rowIndex];

      const { id } = data;
      const isLast = props.rowIndex + 1 === items.length;

      return (
        <div
          key={id}
          style={props.style}
          onClick={() => onSelect?.(id)}
          onDoubleClick={() => onSelect?.(id)}
        >
          {renderItem({
            id,
            index: props.rowIndex,
            data,
            isLast,
          })}
        </div>
      );
    },
    [items, renderItem, onSelect],
  );

  return (
    <VirtualizedScroll
      rowHeight={itemHeight}
      rowCount={items.length}
      cellRenderer={renderCell}
    />
  );
};

export const InnerList = memo(View, shouldSkipRender);
