/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { ITreeItem } from '@seeeverything/ui.primitives/src/components/TreeView/index.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import { CsvDialog } from './CsvDialog.tsx';
import { DialogOuter } from './components/DialogOuter.tsx';
import { CsvDateRangeType } from './types.ts';
import * as csvExportActions from '../../redux/csvExport/actions.ts';
import * as modalDialogActions from '../../redux/modalDialog/actions.ts';
import { ICsvExportFilter } from '../../redux/csvExport/types.ts';
import moment from 'moment';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';

export interface ICsvDialogContainerProps {
  showFilters?: boolean;
}

export const CsvDialogContainer: React.FC<ICsvDialogContainerProps> = ({
  showFilters,
}) => {
  const dispatch = useShellDispatch();

  const hide = useCallback(
    () => dispatch(modalDialogActions.hideModalDialog()),
    [dispatch],
  );

  const dateChanged = useCallback(
    (to: string, type: CsvDateRangeType) =>
      dispatch(csvExportActions.dateChanged(to, type)),
    [dispatch],
  );

  const selectedFiltersChanged = useCallback(
    (to: string[]) => dispatch(csvExportActions.selectedFiltersChanged(to)),
    [dispatch],
  );

  const exportConfirmed = useCallback(
    () => dispatch(csvExportActions.exportConfirmed()),
    [dispatch],
  );

  const endDate = useShellSelector((state) => state.csvExport.endDate);
  const startDate = useShellSelector((state) => state.csvExport.startDate);
  const selectedFilters = useShellSelector(
    (state) => state.csvExport.selectedFilters,
  );
  const templates = useShellSelector((state) => state.csvExport.templates);
  const categories = useShellSelector((state) => state.csvExport.categories);
  const areTemplatesAndCategoriesLoading = useShellSelector(
    (state) => state.csvExport.areTemplatesAndCategoriesLoading,
  );

  const handleDateChange = useCallback(
    (dateType: CsvDateRangeType, to: string) => dateChanged(to, dateType),
    [dateChanged],
  );
  const handleFiltersSelected = useCallback(
    (selectedFilterIds: string[] = []) =>
      selectedFiltersChanged(selectedFilterIds),
    [selectedFiltersChanged],
  );
  const filtersFromTemplatesAndCategories = useMemo((): ITreeItem[] => {
    const formattedLabel = (labelText: string) => (
      <Text
        size={13}
        color={color.format(-0.8)}
        ellipsis={true}
        block={true}
        cursor={'pointer'}
        width={300}
      >
        {labelText}
      </Text>
    );

    const formattedArchivedLabel = (labelText: string) => (
      <Text
        size={13}
        color={color.format(-0.5)}
        italic={true}
        ellipsis={true}
        block={true}
        cursor={'pointer'}
        width={300}
      >
        {labelText}
      </Text>
    );

    const categoriesWithActiveTemplates = categories.filter((category) =>
      templates.some(
        (template) =>
          template.parentCategoryId === category.id &&
          template.status !== 'Archived',
      ),
    );

    const categoriesForArchivedTemplates = [] as ICsvExportFilter[];
    templates
      .filter((template) => template.status === `Archived`)
      .forEach((template) => {
        const categoryAlreadyAdded = categoriesForArchivedTemplates.some(
          (category) => category.id === template.parentCategoryId,
        );

        if (!categoryAlreadyAdded) {
          categoriesForArchivedTemplates.push({
            id: template.parentCategoryId,
            name: template.parentCategoryName,
          });
        }
      });

    const categoriesInTree = categoriesWithActiveTemplates.map(
      (category): ITreeItem => {
        const templatesInTree = templates
          .filter(
            (template) =>
              template.parentCategoryId === category.id &&
              template.status !== 'Archived',
          )
          .map(
            (template): ITreeItem => ({
              id: template.id,
              label: formattedLabel(template.name),
            }),
          );
        return {
          id: category.id,
          label: formattedLabel(category.name),
          children: templatesInTree,
        };
      },
    );

    const archivedCategoriesInTree = categoriesForArchivedTemplates.map(
      (category): ITreeItem => {
        const archivedTemplatesInTree = templates
          .filter(
            (template) =>
              template.status === 'Archived' &&
              template.parentCategoryId === category.id,
          )
          .sort((first, second) =>
            first.updatedAt < second.updatedAt ? 1 : -1,
          )
          .map((template): ITreeItem => {
            const updatedAtFormatted = moment(template.updatedAt)
              .parseZone()
              .format('D MMM YY');

            return {
              id: template.id,
              label: formattedArchivedLabel(
                `${template.name}, archived ${updatedAtFormatted}`,
              ),
            };
          });

        return {
          id: `ARCHIVED-${category.id}`,
          label: formattedArchivedLabel(category.name),
          children: archivedTemplatesInTree,
        };
      },
    );

    if (archivedCategoriesInTree?.length > 0) {
      categoriesInTree.push({
        id: 'ARCHIVED',
        label: formattedArchivedLabel('Archived Templates'),
        children: archivedCategoriesInTree,
      });
    }

    return [
      {
        id: 'ALL',
        label: formattedLabel('All'),
        children: categoriesInTree,
      },
    ];
  }, [categories, templates]);

  const actionButtons: IActionBarButton[] = [
    {
      id: 'Cancel',
      label: 'Cancel',
      isEnabled: true,
      onAction: hide,
    },
    {
      id: 'OK',
      label: 'OK',
      isEnabled: !(showFilters && areTemplatesAndCategoriesLoading),
      onAction: exportConfirmed,
    },
  ];

  return (
    <DialogOuter actions={actionButtons}>
      <CsvDialog
        onFilterSelected={handleFiltersSelected}
        onDateChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        selectedFilters={selectedFilters}
        areFiltersLoading={showFilters && areTemplatesAndCategoriesLoading}
        filters={showFilters ? filtersFromTemplatesAndCategories : undefined}
      />
    </DialogOuter>
  );
};
