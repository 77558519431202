import * as types from './types.ts';

const DEFAULT_STATE: types.IModalDialogShellState = {
  isShowing: false,
  width: 500,
  actions: [],
};

export function reducer(
  state: types.IModalDialogShellState = DEFAULT_STATE,
  action: types.IModalDialogAction,
) {
  switch (action.type) {
    case 'ui.shell/modalDialog/SHOW':
      return {
        ...state,
        ...action.payload,
        isShowing: true,
      };

    case 'ui.shell/modalDialog/HIDE':
      return { ...state, isShowing: false };

    default:
      return state;
  }
}
