/** @jsxImportSource @emotion/react */
import { BulkUploadJobsGrid } from '@seeeverything/ui.forms/src/components/BulkUploadJobsGrid/BulkUploadJobsGrid.tsx';
import {
  failedClickThroughClick,
  succeededClickThroughClick,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/click-through/actions.ts';
import { loadMore } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/job-history/actions.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export const SheetFormsBulkUploadHistoryContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const locale = useShellSelector((state) => state.tenantState.tenant.locale);

  const jobs = useShellSelector(
    (state) => state.formBulkUpload.jobHistory.jobs || [],
  );

  const isLoading = useShellSelector(
    (state) => state.formBulkUpload.jobHistory.isLoading || false,
  );

  const isLoadingMore = useShellSelector(
    (state) => state.formBulkUpload?.jobHistory.isLoadingMore,
  );

  const hasNextPage = useShellSelector(
    (state) => state.formBulkUpload.jobHistory.hasNextPage,
  );

  const loadedPage = useShellSelector(
    (state) => state.formBulkUpload.jobHistory.currentPage ?? 1,
  );

  const handleFailedClickThroughGrid = useCallback(
    (jobId: string) => dispatch(failedClickThroughClick(jobId)),
    [dispatch],
  );

  const handleSucceededClickThroughClick = useCallback(
    (jobId: string) => dispatch(succeededClickThroughClick(jobId)),
    [dispatch],
  );

  const handleLoadMore = useCallback(() => dispatch(loadMore()), [dispatch]);

  const title = useShellSelector(
    (state) => `${state.tenantState.tenant.locale.label.form} Loader`,
  );

  return (
    <SheetLayout
      title={title}
      leftTools={[
        {
          id: 'DOWNLOAD_FORM_TEMPLATE',
          icon: Icons.fileDownload,
          label: 'Download Template',
        },
        {
          id: 'IMPORT_BULK_UPLOAD_FILE',
          icon: Icons.publish,
          label: `Upload ${str.plural(locale.label.form)}`,
        },
      ]}
      rightTools={[
        {
          id: 'IMPORT_BULK_UPLOAD_REFRESH',
          icon: Icons.refresh,
          label: 'Refresh List',
        },
      ]}
    >
      <BulkUploadJobsGrid
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        loadedPage={loadedPage}
        jobs={jobs}
        onFailedClickThroughClick={handleFailedClickThroughGrid}
        onLoadMore={handleLoadMore}
        onSucceededClickThroughClick={handleSucceededClickThroughClick}
      />
    </SheetLayout>
  );
};
