import { QueryResult, QuerySuccess } from '@se/data/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import gql from 'graphql-tag';

export async function getTenantConfigurations(
  client: IGraphQLClient,
  tenantIds: string[],
): Promise<QueryResult<Record<string, TenantConfiguration>>> {
  const responses = await Promise.all(
    tenantIds.map(async (tenantId) => getTenantConfiguration(client, tenantId)),
  );

  const configurations = responses.filter(
    (config): config is QuerySuccess<TenantConfigurationResponse> =>
      config.isSuccess === true,
  );

  const data = configurations
    .filter((config) => config.isSuccess === true)
    .reduce(
      (acc, config) => {
        return {
          ...acc,
          [config.data.id]: config.data.configuration,
        };
      },
      {} as Record<string, TenantConfiguration>,
    );

  return { isSuccess: true, data };
}

export type TenantConfigurationResponse = {
  id: string;
  configuration: TenantConfiguration;
};

export async function getTenantConfiguration(
  client: IGraphQLClient,
  tenantId: string,
): Promise<QueryResult<TenantConfigurationResponse>> {
  try {
    const response = await client.query<{ configuration: { tenant: string } }>({
      fetchPolicy: 'cache-first',
      query: gql`
        query TenantConfig($tenantId: String) {
          configuration {
            tenant(id: $tenantId)
          }
        }
      `,
      variables: { tenantId },
    });

    const encodedConfig = response?.data?.configuration?.tenant;
    if (!encodedConfig) return { isSuccess: false, errorReason: 'NOT_FOUND' };

    const decoded = str.decodeBase64(encodedConfig);
    if (!decoded) return;

    const result = JSON.parse(decoded) as TenantConfiguration;
    return {
      isSuccess: true,
      data: {
        id: tenantId,
        configuration: result,
      },
    };
  } catch (err) {
    log.error(`Failed to load configuration for tenant ${tenantId}`);
    return { isSuccess: false, errorReason: 'UNKNOWN', error: err };
  }
}
