import * as types from './types.ts';

export function reducer(
  state: types.IQueryState,
  action: types.INextQueryAction,
) {
  const { url, query, changeSource, selection } = action.payload;
  const nextState = {
    ...state,
    url,
    query,
    changeSource,
  };

  // Store or remove the selection state.
  if (selection) {
    nextState.selection = selection;
  } else {
    delete nextState.selection;
  }

  if (changeSource === 'INPUT') {
    // Only show source of the change in the state tree if it was
    // not from the user interacting with the component.
    //    -- WHY? --
    //    User input is the default, and so this is only interesting to the
    //    QB container to update state if the change came from some other
    //    business logic.
    delete nextState.changeSource;
  }
  return nextState;
}
