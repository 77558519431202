/** @jsxImportSource @emotion/react */
import {
  DataGrid,
  DataGridClickThroughBehavior,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  IDataGridClickThroughProps,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import moment from 'moment';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';
import { IBulkUploadJob } from '../../redux/form-bulk-upload/job-history/types.ts';
import { useFormsSelector } from '../../redux/store.ts';
import { BulkUploadClickThroughColumn, BulkUploadColumns } from './types.ts';

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridClickThroughBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridProps &
    IDataGridClickThroughProps &
    IDataGridInfiniteScrollBehaviorProps
>;

export interface IBulkUploadJobsGridProps {
  jobs: IBulkUploadJob[];
  popup?: string;
  onFailedClickThroughClick?: (jobId: string) => void;
  onSucceededClickThroughClick?: (jobId: string) => void;
  onLoadPage?: () => void;
  isLoading?: boolean;
  onLoadMore?: () => void;
  loadedPage?: number;
  loadMoreText?: string;
  isLoadingMore?: boolean;
  hasNextPage?: boolean;
}

/**
 * A table of Bulk Upload Jobs.
 */
export const BulkUploadJobsGrid: React.FC<IBulkUploadJobsGridProps> = ({
  jobs,
  onFailedClickThroughClick,
  onSucceededClickThroughClick,
  isLoading,
  isLoadingMore,
  loadedPage,
  onLoadMore,
  loadMoreText = 'Load more',
  hasNextPage,
}) => {
  const handleClickThroughClicked = useCallback(
    (rowId: string, columnId: BulkUploadClickThroughColumn) =>
      columnId === BulkUploadColumns.FAILED
        ? onFailedClickThroughClick?.(rowId)
        : onSucceededClickThroughClick?.(rowId),
    [onFailedClickThroughClick, onSucceededClickThroughClick],
  );

  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const data = useMemo(() => jobs.map(toGridRow), [jobs]);
  return (
    <Grid
      id={'BulkUploadJobs'}
      clickThroughColumnIds={CLICK_THROUGH_COLUMNS}
      onClickThroughColumnClick={handleClickThroughClicked}
      isLoading={isLoading}
      isScrollable={true}
      isVirtualized={true}
      columns={createColumns(formLabel)}
      data={data}
      onLoadPage={onLoadMore}
      isLoadingPage={isLoadingMore}
      loadedPage={loadedPage}
      loadMoreText={loadMoreText}
      hasNextPage={hasNextPage}
    />
  );
};

function toGridRow(job: IBulkUploadJob): IGridRow {
  return {
    id: job.id,
    data: [
      moment(job.uploadedAt).format('D MMM YY, h:mm a').toString(),
      job.templateNames,
      job.uploadedBy.name,
      str.humanize(job.jobStatus, true),
      job.succeeded,
      job.failed,
    ],
    canClickThrough: job.jobStatus !== 'Processing',
  };
}

const CLICK_THROUGH_COLUMNS = [
  BulkUploadColumns.SUCCEEDED,
  BulkUploadColumns.FAILED,
];
const createColumns = (formLabel: string): IDataGridColumn[] => [
  {
    id: BulkUploadColumns.UPLOADED_AT,
    label: 'Uploaded Date',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.TEMPLATE_NAMES,
    label: `${formLabel} Types`,
    width: '5*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.UPLOADED_BY,
    label: 'Uploaded By',
    width: '3*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.JOB_STATUS,
    label: 'Status',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.SUCCEEDED,
    label: 'Created',
    align: 'center',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.FAILED,
    label: 'Failed',
    align: 'center',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
];
