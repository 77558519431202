import { formatDateString } from '@seeeverything/ui.dashboards/src/util/util.ts';
import {
  cancelFormInstance,
  exportToPdf,
} from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import {
  ReduxFormInstanceExportPdf,
  ReduxFormInstancePdfExportError,
  ReduxFormInstanceServerCancelled,
} from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import {
  removeSheet,
  showStatusBar,
} from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { ISheetToolbarDropdownClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, map, of } from 'rxjs';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';
import { addAuditLogChip } from '../../config.sheets/query.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  addAuditLogChipEpic,
  cancelFormInstanceEpic,
  exportInstancePdfEpic,
  removeSheetWhenFormCancelled,
  showStatusOnFormInstancePdfExportError,
  showStatusOnFormInstancePdfExportRequested,
);

/**
 * Remove the current sheet when a form instance is cancelled, if the user is
 * currently viewing that instance.
 */
function removeSheetWhenFormCancelled(
  action$: Observable<ReduxFormInstanceServerCancelled>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_CANCELLED'),
    map(({ payload }) => {
      const sheet = state$.value.sheets.current;
      const displayingInstance =
        sheet.type === 'FORM' && sheet.props.instanceId === payload.instanceId;

      return displayingInstance ? of(removeSheet()) : EMPTY;
    }),
    concatAll(),
  );
}

function addAuditLogChipEpic(
  action$: Observable<ISheetToolbarDropdownClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(({ payload }) => payload.itemId === 'FORM_AUDIT_LOG'),
    map(() => addAuditLogChip()),
  );
}

function cancelFormInstanceEpic(
  action$: Observable<ISheetToolbarDropdownClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(({ payload }) => payload.itemId === 'SHEET_OPTION_CANCEL_FORM'),
    map((action) => {
      const { instanceId } = action.payload.sheet.props;
      const locale = state$.value.tenantState.tenant?.locale;
      const confirmationMessage = locale.forms.instance.confirmCancellation;
      return cancelFormInstance(instanceId, confirmationMessage);
    }),
  );
}

function exportInstancePdfEpic(
  action$: Observable<ISheetToolbarDropdownClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(
      ({ payload }) =>
        payload.itemId === 'FORM_EXPORT_INSTANCE_TO_PDF' &&
        Boolean(Object.values(state$.value.formInstance.instances).length),
    ),
    map(() => {
      const instance = Object.values(state$.value.formInstance.instances)?.[0];
      const lastUpdated = formatDateString(instance.status.lastUpdated, {
        format: 'YYYY-MM-DD',
      });

      const fileName = `${instance.name} - Last updated ${lastUpdated}.pdf`;

      return exportToPdf(instance.id, fileName);
    }),
  );
}

function showStatusOnFormInstancePdfExportRequested(
  action$: Observable<ReduxFormInstanceExportPdf>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/EXPORT_INSTANCE_TO_PDF'),
    filter((action) => Boolean(action.payload.instanceId)),
    map(() =>
      showStatusBar(
        'INFO',
        `A PDF is being generated for you. Once ready, it will automatically be downloaded by your browser.`,
        5000,
      ),
    ),
  );
}

function showStatusOnFormInstancePdfExportError(
  action$: Observable<ReduxFormInstancePdfExportError>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/PDF_EXPORT_ERROR'),
    map(() =>
      showStatusBar(
        'ERROR',
        `Something went wrong trying to download your document. Please try again later.`,
        5000,
      ),
    ),
  );
}
