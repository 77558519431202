/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { value } from '@seeeverything/ui.util/src/value/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { FormResult } from '@seeeverything/ui.forms/src/redux/form-score/types.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { FormInstanceStatus } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';

export interface ISheetFormScoreProps {
  answeredQuestions: number;
  currentScorePoints: number;
  currentWeightPoints: number;
  failMaximumScore: number;
  instanceStatus: FormInstanceStatus;
  instantFailAnswerCount: number;
  isVisible?: boolean;
  passRate: number;
  percentageScore: number;
  result: FormResult;
  totalScorableQuestions: number;
}

const GREEN = color.create(COLORS.GREEN).alpha(0.3).css();

export const SheetFormScore: React.FC<ISheetFormScoreProps> = ({
  answeredQuestions,
  currentScorePoints,
  currentWeightPoints,
  failMaximumScore,
  instanceStatus,
  instantFailAnswerCount,
  isVisible = true,
  passRate,
  percentageScore,
  result,
  totalScorableQuestions,
}) => {
  const [isRevealed, setIsRevealed] = React.useState(isVisible);
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);

  const toggleScoreVisibility = React.useCallback(() => {
    const toggleToVisibility = !isRevealed;
    setIsRevealed(toggleToVisibility);
    setIsTooltipVisible(toggleToVisibility);
  }, [isRevealed]);

  const showTooltip = React.useCallback(() => {
    if (isRevealed) {
      setIsTooltipVisible(true);
    }
  }, [isRevealed]);

  const hideTooltip = React.useCallback(() => {
    if (isRevealed) {
      setIsTooltipVisible(false);
    }
  }, [isRevealed]);

  const isPass = result === 'Pass';
  const isInProgress = instanceStatus !== 'Completed';

  const computedStyles = {
    base: css({
      backgroundColor: isRevealed
        ? result === 'N/A'
          ? color.format(-0.1)
          : isPass
            ? GREEN
            : 'rgba(255, 0, 0, 0.1)'
        : 'none',
    }),
  };

  const elReveal = !isRevealed && (
    <Button style={styles.button} onClick={toggleScoreVisibility}>
      <Text cursor={'pointer'} size={15} weight={FontWeight.light}>
        {'Reveal score'}
      </Text>
    </Button>
  );

  const valueDisplayString = value.toPercentageString(percentageScore, 2);

  const resultString =
    result === 'N/A'
      ? 'To be determined'
      : `${isPass ? 'Pass' : 'Fail'} -- ${valueDisplayString}`;

  const elRevealed = isRevealed && (
    <Button style={styles.button} onClick={toggleScoreVisibility}>
      <Text cursor={'pointer'} size={15} weight={FontWeight.light}>
        {resultString}
      </Text>
    </Button>
  );

  const elTooltip = (
    <Transition.Fade in={isTooltipVisible}>
      <div css={styles.tooltip}>
        <Text size={12} color={color.format(-0.6)} weight={FontWeight.bold}>
          {`Score Breakdown (${isInProgress ? 'in progress' : 'complete'})`}
        </Text>
        <Text size={12} color={color.format(-0.6)} isMarkdown={true}>
          {`* The ${
            isInProgress
              ? 'current score of this review is'
              : 'final score of this review was'
          } ${
            result === 'N/A'
              ? isInProgress
                ? ' to be determined'
                : ' N/A'
              : valueDisplayString
          }.

          \n* The pass-rate for this review ${
            isInProgress ? 'is' : 'was'
          } ${value.toPercentageString(passRate, 2)}.

          \n* Responses to ${answeredQuestions} out of ${totalScorableQuestions} scorable ${str.pluralize.plural(
            'question',
            totalScorableQuestions,
          )} ${isInProgress ? 'have been' : 'were'} provided.

          \n* ${
            isInProgress ? 'Currently scored' : 'Scored'
          } ${toTwoDecimalPlaces(
            currentScorePoints,
          )} out of ${toTwoDecimalPlaces(currentWeightPoints)} points${
            isInProgress
              ? ` with ${
                  totalScorableQuestions - answeredQuestions
                } scorable responses remaining`
              : ''
          }.

          \n* ${
            instantFailAnswerCount === 0 ? 'No' : instantFailAnswerCount
          } ${str.pluralize.plural('response', instantFailAnswerCount)} ${
            isInProgress
              ? `${
                  instantFailAnswerCount === 1 ? 'has' : 'have'
                } been provided that will instantly fail the review.`
              : `${
                  instantFailAnswerCount === 1 ? 'was' : 'were'
                } provided that instantly failed this review.`
          } ${
            instantFailAnswerCount > 0
              ? `Because of this, the maximum score that ${
                  isInProgress ? 'can be' : 'could have been'
                } achieved in this review ${
                  isInProgress ? 'is' : 'was'
                } ${value.toPercentageString(failMaximumScore, 2)}.`
              : ''
          }`}
        </Text>
      </div>
    </Transition.Fade>
  );

  return (
    <div
      css={css([styles.base, computedStyles.base])}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {elTooltip}
      {elReveal}
      {elRevealed}
    </div>
  );
};

const toTwoDecimalPlaces = (number: number) =>
  Math.round((number + Number.EPSILON) * 100) / 100;

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: 30,
    maxWidth: 180,
    padding: '0',
    border: 'dashed 1px #AAAAAA',
    borderRadius: 5,
    marginRight: 10,
  }),
  tooltip: css({
    position: 'absolute',
    top: 35,
    right: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    width: 300,
    zIndex: 25,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15)',
    borderRadius: 3,
    padding: '20px 40px 20px 20px',
  }),
  button: { padding: '7px 10px' },
};
