/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryFormTemplateCategories } from '@se/data/forms/hooks/useQueryFormTemplateCategories.ts';
import { useQueryFormTemplatesByCategory } from '@se/data/forms/hooks/useQueryFormTemplatesByCategory.ts';
import { IFormDropdownProvider } from '@seeeverything/ui.forms/src/types/types.ts';
import { MessageBar } from '@seeeverything/ui.primitives/src/components/MessageBar/MessageBar.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { TabStrip } from '@seeeverything/ui.primitives/src/components/TabStrip/TabStrip.tsx';
import { TitledDialog } from '@seeeverything/ui.primitives/src/components/TitledDialog/TitledDialog.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useMemo } from 'react';
import { formsDesignerImportTemplatesSlice } from '../../redux/index.ts';
import {
  useFormsDesignerDispatch,
  useFormsDesignerSelector,
} from '../../redux/store.ts';
import { NewTemplate } from './components/NewTemplate.tsx';
import { OverwriteExistingTemplate } from './components/OverwriteExistingTemplate.tsx';

const TABS = [
  { id: 'NEW', label: 'New Template' },
  { id: 'OVERWRITE_EXISTING', label: 'Overwrite Existing' },
];
export interface IImportTemplateDialogContainerProps {
  categoryDropdownProvider?: IFormDropdownProvider;
}

export const ImportTemplateDialogContainer: React.FC = () => {
  const dispatch = useFormsDesignerDispatch();
  const { categorySelections, categoriesLoading } =
    useQueryFormTemplateCategories();
  const { templateSelections, templatesLoading } =
    useQueryFormTemplatesByCategory();

  const selectedMethod = useFormsDesignerSelector(
    (state) => state.formsDesignerImportTemplates.selectedMethod,
  );

  const newTemplateName = useFormsDesignerSelector(
    (state) => state.formsDesignerImportTemplates.createNewInputs.name,
  );

  const newTemplateNameErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.createNewInputs.validationErrors.name,
  );

  const newTemplateDefinitionJson = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.createNewInputs.definitionJson,
  );

  const newTemplateDefinitionJsonErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.createNewInputs.validationErrors
        .definitionJson,
  );

  const newTemplateCategory = useFormsDesignerSelector(
    (state) => state.formsDesignerImportTemplates.createNewInputs.category,
  );

  const newTemplateCategoryErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.createNewInputs.validationErrors
        .category,
  );

  const overwriteExistingTemplate = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.overwriteExistingInputs
        .existingTemplate,
  );

  const overwriteExistingTemplateErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.overwriteExistingInputs
        .validationErrors.existingTemplate,
  );

  const overwriteExistingDefinitionJson = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.overwriteExistingInputs.definitionJson,
  );

  const overwriteExistingDefinitionJsonErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerImportTemplates.overwriteExistingInputs
        .validationErrors.definitionJson,
  );

  const generalError = useFormsDesignerSelector(
    (state) => state.formsDesignerImportTemplates.generalError,
  );

  const isSpinning = useFormsDesignerSelector(
    (state) => state.formsDesignerImportTemplates.isSpinning,
  );

  const importTemplate = useCallback(() => {
    switch (selectedMethod) {
      case 'NEW': {
        const jsonValid = isJsonValid(newTemplateDefinitionJson);
        const validationRequired =
          !newTemplateName || !jsonValid || !newTemplateCategory;

        if (validationRequired) {
          dispatch(
            formsDesignerImportTemplatesSlice.validationErrorsOnNew({
              category: !newTemplateCategory
                ? 'Please select a category.'
                : undefined,
              definitionJson: !jsonValid
                ? 'Please enter a valid definition.'
                : undefined,
              name: !newTemplateName ? 'Please enter a name.' : undefined,
            }),
          );
          return;
        }
        break;
      }

      case 'OVERWRITE_EXISTING': {
        const jsonValid = isJsonValid(overwriteExistingDefinitionJson);
        const validationRequired = !overwriteExistingTemplate || !jsonValid;
        if (validationRequired) {
          dispatch(
            formsDesignerImportTemplatesSlice.validationErrorsOnOverwriteExisting(
              {
                definitionJson: !jsonValid
                  ? 'Please enter a valid definition.'
                  : undefined,
                existingTemplate: !overwriteExistingTemplate
                  ? 'Please select an existing template to overwrite.'
                  : undefined,
              },
            ),
          );
          return;
        }
        break;
      }
    }

    dispatch(formsDesignerImportTemplatesSlice.importTemplate());
  }, [
    dispatch,
    newTemplateCategory,
    newTemplateDefinitionJson,
    newTemplateName,
    overwriteExistingDefinitionJson,
    overwriteExistingTemplate,
    selectedMethod,
  ]);

  const cancelImport = useCallback(
    () => dispatch(formsDesignerImportTemplatesSlice.cancelImport()),
    [dispatch],
  );

  const setSelectedMethod = useCallback(
    (to: 'NEW' | 'OVERWRITE_EXISTING') =>
      dispatch(formsDesignerImportTemplatesSlice.setSelectedMethod(to)),
    [dispatch],
  );

  const updateNewTemplateName = useCallback(
    (to: string) =>
      dispatch(formsDesignerImportTemplatesSlice.updateNameFieldOnNew(to)),
    [dispatch],
  );

  const updateNewTemplateCategory = useCallback(
    (to: ISelectionListItem) =>
      dispatch(formsDesignerImportTemplatesSlice.updateCategoryFieldOnNew(to)),
    [dispatch],
  );

  const updateNewTemplateDefinitionJson = useCallback(
    (to: string) =>
      dispatch(
        formsDesignerImportTemplatesSlice.updateDefinitionFieldOnNew(to),
      ),
    [dispatch],
  );

  const updateOverwriteExistingTemplate = useCallback(
    (to: ISelectionListItem) =>
      dispatch(
        formsDesignerImportTemplatesSlice.updateExistingTemplateFieldOnOverwrite(
          to,
        ),
      ),
    [dispatch],
  );

  const updateOverwriteExistingDefinitionJson = useCallback(
    (to: string) =>
      dispatch(
        formsDesignerImportTemplatesSlice.updateDefinitionFieldOnOverwrite(to),
      ),
    [dispatch],
  );

  const elSpinnerOverlay = useMemo(
    () =>
      isSpinning && (
        <div css={styles.spinnerOverlay}>
          <Spinner />
        </div>
      ),
    [isSpinning],
  );

  const elError = generalError ? (
    <MessageBar
      message={generalError}
      style={styles.messageBar}
      type={'Error'}
    />
  ) : undefined;

  const elSelectedTab =
    selectedMethod === 'NEW' ? (
      <NewTemplate
        categories={categorySelections}
        categoriesIsLoading={categoriesLoading}
        category={newTemplateCategory}
        categoryErrorText={newTemplateCategoryErrorText}
        definitionJson={newTemplateDefinitionJson}
        definitionJsonErrorText={newTemplateDefinitionJsonErrorText}
        name={newTemplateName}
        nameErrorText={newTemplateNameErrorText}
        onCategoryChange={updateNewTemplateCategory}
        onDefinitionJsonChange={updateNewTemplateDefinitionJson}
        onNameChange={updateNewTemplateName}
      />
    ) : (
      <OverwriteExistingTemplate
        definitionJson={overwriteExistingDefinitionJson}
        definitionJsonErrorText={overwriteExistingDefinitionJsonErrorText}
        onDefinitionJsonChange={updateOverwriteExistingDefinitionJson}
        onSelectedTemplateChange={updateOverwriteExistingTemplate}
        selectedTemplate={overwriteExistingTemplate}
        selectedTemplateErrorText={overwriteExistingTemplateErrorText}
        templates={templateSelections}
        templatesIsLoading={templatesLoading}
      />
    );

  const actions = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        isEnabled: true,
        onAction: cancelImport,
      },
      { id: 'Import', label: 'Import', onAction: importTemplate },
    ],
    [cancelImport, importTemplate],
  );

  return (
    <div css={styles.base}>
      {elSpinnerOverlay}
      <TitledDialog
        title={'Import Template'}
        actions={actions}
        style={styles.dialog}
      >
        <div css={styles.bodyFull}>
          <div css={styles.tabsContainer}>
            <TabStrip
              tabs={TABS}
              selectedId={selectedMethod}
              onSelectionChanged={setSelectedMethod}
            />
          </div>
          {elError}
          {elSelectedTab}
        </div>
      </TitledDialog>
    </div>
  );
};

const isJsonValid = (input?: string) => {
  if (!input?.length) return false;
  if (!isNaN(Number(input))) return false;

  try {
    JSON.parse(input);
  } catch {
    return false;
  }

  return true;
};

const styles = {
  base: css({
    position: 'relative',
    backgroundColor: '#ffffff',
    flex: '1 1 auto',
  }),
  dialog: css({
    height: 550,
  }),
  bodyFull: css({
    width: '100%',
  }),
  messageBar: css({
    marginLeft: 16,
  }),
  tabsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    background: color.format(1),
    width: '100%',
    left: 0,
    padding: '0 20px',
  }),
  spinnerOverlay: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.format(0.8),
    zIndex: 1,
  }),
};
