import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { GlobalReduxEpicDependenciesType } from '@seeeverything/ui.util/src/redux/types.ts';
import { equals } from 'ramda';
import { StateObservable, ofType } from 'redux-observable';
import {
  EMPTY,
  Observable,
  concatAll,
  debounceTime,
  filter,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import {
  NotFoundErrorKind,
  personHeaderDetails,
  teamHeaderDetails,
  toDateFilter,
} from '../../data/index.ts';
import * as actions from '../actions.ts';
import {
  DashboardV2ReduxState,
  GlobalDashboardsState,
  IDashboardActionLoadHeader,
  IDashboardActionSetCustomDateRange,
  IDashboardActionSetDateFilter,
} from '../types.ts';

export function loadHeaderOnDateFiltersChanged(
  action$: Observable<
    IDashboardActionSetDateFilter | IDashboardActionSetCustomDateRange
  >,
) {
  return action$.pipe(
    ofType(
      'ui.dashboards/dashboard/HEADER/SET_DATE_FILTER',
      'ui.dashboards/dashboard/HEADER/SET_CUSTOM_DATE_RANGE',
    ),
    filter((action) => action.payload.reloadHeader),
    debounceTime(10),
    switchMap(async () => actions.loadHeader()),
  );
}

export function loadHeaderEpic(
  action$: Observable<IDashboardActionLoadHeader>,
  state$: StateObservable<GlobalDashboardsState>,
  { client }: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/HEADER/LOAD'),
    filter(() =>
      Boolean(
        state$.value.dashboardsV2.HEADER && state$.value.dashboardsV2.template,
      ),
    ),
    mergeMap(async () => {
      const template = state$.value.dashboardsV2.template;
      try {
        const getDashboardEntity = () => ({
          id: state$.value.dashboardsV2.template?.entityId,
          type: state$.value.dashboardsV2.template?.kind,
          module: state$.value.tenantState.tenant?.module,
          dateFilterId: toDateFilter(state$.value)?.selection?.id,
        });
        const initialEntity = getDashboardEntity();

        const data = await loadData(
          client,
          state$.value,
          template.kind,
          template.entityId,
          template.id,
        );

        const currentEntity = getDashboardEntity();
        const dashboardChangedSinceLoad = !equals(initialEntity, currentEntity);

        return dashboardChangedSinceLoad
          ? EMPTY
          : of(actions.headerLoaded(data));
      } catch (e) {
        if (e.kind === NotFoundErrorKind) {
          // NotFound can be permissions error and is fatal to whole dashboard.
          return of(actions.errorMessage(e.toString()));
        }
        // Other errors are not currently fatal.
        log.error('Unable to load header.', e);
        return EMPTY;
      }
    }),
    concatAll(),
  );
}

const loadData = async (
  client: IGraphQLClient,
  state: DashboardV2ReduxState,
  entityType: string,
  entityId: string,
  templateId: string,
) =>
  entityType === 'person'
    ? personHeaderDetails(client, {
        personId: entityId,
        templateId,
        dateFilter: toDateFilter(state),
      })
    : teamHeaderDetails(client, {
        teamId: entityId,
        templateId,
        dateFilter: toDateFilter(state),
      });
