import {
  ICsvExportConfirmedAction,
  ICsvExportReadyStatusCheckCompletedAction,
  ICsvExportReadyStatusCheckFailedAction,
} from '@seeeverything/ui.shell/src/redux/csvExport/types.ts';
import { IQueryChipAddedExternally } from '@seeeverything/ui.shell/src/redux/query/types.ts';
import { ISheetToolbarClickAction } from '@seeeverything/ui.shell/src/redux/sheets/index.ts';
import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { GlobalAppActionType, GlobalAppState } from '../../types.ts';
import { trackEvent } from './util.ts';

export const generateSheetUpEvent = (
  action: ISheetToolbarClickAction,
): Promise<AnalyticsEvent[]> =>
  new Promise<AnalyticsEvent[]>((resolve, reject) => {
    try {
      if (!action || action.payload.toolId !== 'SHEET_UP') return undefined;

      resolve([trackEvent('dashboard_click_up', {})]);
    } catch (error) {
      reject(error);
    }
  });

export const generateDownloadCsvEvent = async (
  action: ICsvExportConfirmedAction,
  state: GlobalAppState,
) => {
  const headerData = state.dashboardsV2.HEADER.dataState.data.data;
  const eventData = {
    sectionType: state.csvExport.dataSetId,
    subjectId: state.csvExport.forSheet.entityId,
    sourceType: headerData.kind,
    subjectPath: headerData.path,
    startDate: state.csvExport.startDate,
    endDate: state.csvExport.endDate,
    selectedFilters: state.csvExport.selectedFilters,
  };

  return [trackEvent('dashboard_download_csv', eventData)];
};

export const generateCsvExportResultCompletedEvent = async (
  action:
    | ICsvExportReadyStatusCheckCompletedAction
    | ICsvExportReadyStatusCheckFailedAction,
) => [
  trackEvent('dashboard_download_csv_complete', {
    endDate: action.payload._eventData.endDate,
    exception:
      action.type === 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_FAILED'
        ? action.payload.exception
        : undefined,
    exportJobId: action.payload.exportJobId,
    pollingRetries: action.payload.retryCounter,
    result:
      action.type === 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_COMPLETED'
        ? 'SUCCESS'
        : action.payload.reason,
    sectionType: action.payload._eventData.dataSetId,
    selectedFilters: action.payload._eventData.selectedFilters,
    startDate: action.payload._eventData.startDate,
    subjectId: action.payload._eventData.entityId,
    subjectType: action.payload._eventData.entityType,
  }),
];

export const generateShellFeatureUsedEvent = async (
  action: IQueryChipAddedExternally,
): Promise<AnalyticsEvent[]> => {
  const { type, value } = action.payload.newChip;
  if (type !== 'settings') return [];

  if (value === 'formsDesignerTemplates') {
    return [trackEvent('form_designer_open')];
  }
  if (value === 'formsBulkUpload') {
    return [trackEvent('form_uploader_open')];
  }
  if (value === 'automatedActions') {
    return [trackEvent('form_automated_actions_open')];
  }
  if (value === 'schedules') {
    return [trackEvent('form_scheduler_open')];
  }

  return [];
};

export const generateQueryBuilderInteractionEvent = (
  action: GlobalAppActionType,
  state: GlobalAppState,
): Promise<AnalyticsEvent[]> =>
  new Promise<AnalyticsEvent[]>((resolve, reject) => {
    try {
      const selectedChips = state.query.query.chips;

      resolve([
        trackEvent('query_builder_click', {
          chipType: selectedChips[selectedChips.length - 1]?.type,
        }),
      ]);
    } catch (error) {
      reject(error);
    }
  });
