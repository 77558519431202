import { dashboardsQuery } from '@se/data/dashboards/query/index.ts';
import {
  GlobalReduxEpicDependenciesType,
  ReduxAction,
} from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, debounceTime, filter, map, mergeMap } from 'rxjs';
import { toDateFilter, toTimespanVariable } from '../../data/util.ts';
import { IDashboardGrid } from '../../types.ts';
import {
  GlobalDashboardsState,
  IDashboardActionInitializeComponent,
  IDashboardActionTemplateLoaded,
} from '../types.ts';
import * as dashboardGridsSlice from './dashboardGridsSlice.ts';

export const dashboardGridsEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalDashboardsState
>(
  initializeClickThroughGridSessionEpic,
  initializeGridSessionEpic,
  invalidateCachedColumnFiltersOnDateFilterChangedEpic,
  loadColumnFiltersEpic,
  loadColumnFiltersOnSearchEpic,
  loadColumnFiltersOnShowingPopupEpic,
);

const INACTIVE_ENTITY_KEYS = [
  'person.membership-exists',
  'reviewer.membership-exists',
];

function initializeGridSessionEpic(
  action$: Observable<IDashboardActionInitializeComponent>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/INITIALIZE'),
    filter((action) => {
      if (action.payload.componentType !== 'GRID') return false;

      const gridKey = dashboardGridsSlice.utils.createGridKey({
        module: state$.value.tenantState.tenant.module,
        gridId: action.payload.id,
        entityId: state$.value.dashboardsV2.template.entityId,
      });

      return !state$.value.dashboardGrids.sort[gridKey];
    }),
    map((action) => {
      const grid = action.payload.component as IDashboardGrid;
      const orderBys = grid.initialOrderBys
        .filter((orderBy) => !INACTIVE_ENTITY_KEYS.includes(orderBy.fieldName))
        .map((orderBy) => ({
          columnId: orderBy.fieldName,
          direction: orderBy.direction,
        }));

      const inactiveEntity = grid.initialOrderBys.find((orderBy) =>
        INACTIVE_ENTITY_KEYS.includes(orderBy.fieldName),
      );

      const gridKey = dashboardGridsSlice.utils.createGridKey({
        module: state$.value.tenantState.tenant.module,
        gridId: grid.id,
        entityId: state$.value.dashboardsV2.template.entityId,
      });

      return dashboardGridsSlice.initializeGrid({
        gridKey,
        inactiveEntity: inactiveEntity
          ? {
              columnId: inactiveEntity.fieldName,
              direction: inactiveEntity.direction,
            }
          : undefined,
        orderBys,
      });
    }),
  );
}

function initializeClickThroughGridSessionEpic(
  action$: Observable<IDashboardActionTemplateLoaded>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/TEMPLATE_LOADED'),
    filter((action) => {
      const clickThroughGrid = action.payload.clickThroughGrid;
      if (!clickThroughGrid) return false;

      const gridId = action.payload.loadedSingleComponentId;
      if (!gridId) return false;

      const gridKey = dashboardGridsSlice.utils.createGridKey({
        module: state$.value.tenantState.tenant.module,
        gridId,
        dataSetId: clickThroughGrid.clickThrough.dataSetId,
        rowId: clickThroughGrid.clickThrough.rowId,
        columnId: clickThroughGrid.clickThrough.columnId,
        entityId: state$.value.dashboardsV2.template.entityId,
      });

      return !state$.value.dashboardGrids.sort[gridKey];
    }),
    map((action) => {
      const clickThroughGrid = action.payload.clickThroughGrid;
      const orderBys = clickThroughGrid.initialOrderBys
        .filter((orderBy) => !INACTIVE_ENTITY_KEYS.includes(orderBy.fieldName))
        .map((orderBy) => ({
          columnId: orderBy.fieldName,
          direction: orderBy.direction,
        }));

      const inactiveEntity = clickThroughGrid.initialOrderBys.find((orderBy) =>
        INACTIVE_ENTITY_KEYS.includes(orderBy.fieldName),
      );

      const gridKey = dashboardGridsSlice.utils.createGridKey({
        module: state$.value.tenantState.tenant.module,
        gridId: clickThroughGrid.id,
        dataSetId: clickThroughGrid.clickThrough.dataSetId,
        rowId: clickThroughGrid.clickThrough.rowId,
        columnId: clickThroughGrid.clickThrough.columnId,
        entityId: state$.value.dashboardsV2.template.entityId,
      });

      return dashboardGridsSlice.initializeGrid({
        gridKey,
        inactiveEntity: inactiveEntity
          ? {
              columnId: inactiveEntity.fieldName,
              direction: inactiveEntity.direction,
            }
          : undefined,
        orderBys,
      });
    }),
  );
}

function loadColumnFiltersOnShowingPopupEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    filter(dashboardGridsSlice.showColumnPopup.match),
    filter((action) => {
      const { gridKey, columnId } = action.payload;
      const columnFiltersState =
        state$.value.dashboardGrids.columnFilters[gridKey];

      if (!columnFiltersState) return true;
      return columnFiltersState[columnId]?.isLoaded !== true;
    }),
    map((action) => {
      const { gridId, gridKey, columnId } = action.payload;
      return dashboardGridsSlice.loadColumnFilters({
        gridId,
        gridKey,
        columnId,
      });
    }),
  );
}

function loadColumnFiltersOnSearchEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(dashboardGridsSlice.updateColumnFilterSearch.match),
    filter((action) => Boolean(action.payload.search.length)),
    debounceTime(300),
    map((action) =>
      dashboardGridsSlice.loadColumnFilters({
        gridId: action.payload.gridId,
        gridKey: action.payload.gridKey,
        columnId: action.payload.columnId,
        search: action.payload.search,
      }),
    ),
  );
}

function loadColumnFiltersEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalDashboardsState>,
  { client }: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    filter(dashboardGridsSlice.loadColumnFilters.match),
    filter((action) => {
      if (!state$.value.dashboardsV2.template) return false;

      const { gridKey, columnId } = action.payload;
      return Boolean(
        state$.value.dashboardGrids.columnFilters[gridKey]?.[columnId],
      );
    }),
    mergeMap(async (action) => {
      const { gridId, gridKey, columnId, search } = action.payload;

      const template = state$.value.dashboardsV2.template;

      const templateId = template.id;
      const entityId = template.entityId;
      const entityType = template.kind === 'person' ? 'Person' : 'Team';

      const dataSetId =
        state$.value.dashboardsV2.CLICK_THROUGH_GRID?.[gridId]?.dataState
          .dataSetId ??
        state$.value.dashboardsV2.GRID[gridId].dataState.dataSetId;

      const clickThrough =
        state$.value.dashboardsV2.CLICK_THROUGH_GRID?.[gridId]?.clickThrough;

      const allColumnFilters =
        state$.value.dashboardGrids.columnFilters[gridKey];

      const columnState = allColumnFilters[columnId];

      const nextPage = (columnState.lastLoadedPage ?? 0) + 1;

      const pageNumber = search ? 1 : nextPage;

      const dateFilter = toDateFilter(state$.value);
      const timespan = toTimespanVariable(dateFilter);

      const filterStatements = allColumnFilters
        ? Object.entries(allColumnFilters)
            .filter(
              ([c, { selectedFilters }]) =>
                c !== columnId && selectedFilters?.length > 0,
            )
            .map(([c, { selectedFilters }]) => ({
              columnId: c,
              filters: selectedFilters.map(({ value }) => value),
            }))
        : [];

      const response = await dashboardsQuery.getGridFilters(client, {
        clickThrough,
        columnId,
        dataSetId,
        entityId,
        entityType,
        filterStatements,
        gridId,
        pageNumber,
        search,
        templateId,
        timespan,
      });

      if (!response.isSuccess)
        return dashboardGridsSlice.loadColumnFiltersError({
          gridKey,
          columnId,
        });

      return dashboardGridsSlice.loadedColumnFilters({
        gridKey,
        columnId,
        filters: response.data.filters,
        pageInfo: response.data.pageInfo,
        search,
      });
    }),
  );
}

function invalidateCachedColumnFiltersOnDateFilterChangedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    ofType(
      'ui.dashboards/dashboard/HEADER/SET_DATE_FILTER',
      'ui.dashboards/dashboard/HEADER/CLEAR_DATE_FILTER',
    ),
    map(() => dashboardGridsSlice.invalidateCachedColumnFilters()),
  );
}
