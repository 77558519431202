import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/index.ts';
import { IDashboardChartTools, IDashboardToolButton } from '../types.ts';

/**
 * Attempts to parse the tools for a chart.
 */
export function parser(
  item: any,
  index: number,
): IDashboardChartTools | undefined {
  if (!item['chart-tools']) {
    return;
  }

  const parentId = item['chart-tools'].id;
  const buttons = item['chart-tools'].tools.map(
    (tool: any): IDashboardToolButton => {
      //NOTE: 'full screen' not supported for charts.
      return {
        id: tool.id,
        parentId,
        toolId: tool['chart-id'],
        icon: tool.icon && Icons[tool.icon],
        label: tool.label,
        selectionGroup: tool.group,
        isSelected: tool['is-default'] === true,
      };
    },
  );

  return {
    componentType: 'CHART_TOOLS',
    id: item['chart-tools'].id,
    toolButtons: buttons,
    index,
    dataState: {
      isLoaded: false,
      isLoading: false,
      forceReload: false,
      dataSetId: '',
    },
    group: item['chart-tools'].group,
  };
}
