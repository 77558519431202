import { FormsBulkUploadFileValidationResult } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/types.ts';
import { FormsBulkUploadJobType } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/job-history/types.ts';
import { FormsBulkUploadWorkflowType } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/select-workflow/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';

interface GraphQLFormsBulkValidateFileResponse {
  forms: {
    formsBulkUploadValidate: FormsBulkUploadFileValidationResult;
  };
}

export interface IBulkUploadFileValidationOptions {
  file: File;
  workflowType: FormsBulkUploadWorkflowType;
  jobType?: FormsBulkUploadJobType;
  createAutomatedActions: boolean;
}

export async function validate(
  uploadClient: IGraphQLClient,
  options?: IBulkUploadFileValidationOptions,
): Promise<FormsBulkUploadFileValidationResult> {
  if (!options) {
    return {
      errors: [],
      rows: [],
    };
  }

  const { file, jobType, workflowType, createAutomatedActions } = options;

  try {
    const response =
      await uploadClient.query<GraphQLFormsBulkValidateFileResponse>({
        fetchPolicy: 'network-only',
        ...options,
        query: validateFileQuery,
        variables: {
          file,
          jobType,
          workflowType,
          createAutomatedActions,
        },
      });

    return response.data.forms.formsBulkUploadValidate;
  } catch (err) {
    log.error(
      new Error(`GraphQL Error: Failed to validate bulk upload file. ${err}`),
    );
    return undefined;
  }
}

const validateFileQuery = gql`
  query ValidateFormsBulkUpload(
    $file: Upload!
    $jobType: FormBulkUploadJobType!
    $workflowType: FormsBulkUploadWorkflowType!
    $createAutomatedActions: Boolean!
  ) {
    forms {
      formsBulkUploadValidate(
        file: $file
        jobType: $jobType
        workflowType: $workflowType
        createAutomatedActions: $createAutomatedActions
      ) {
        errors {
          code
          payload {
            key
            value
          }
        }
        rows {
          rowNumber
          associatedEntities {
            assignedTo
            subject
            automatedActionIds
          }
          status
          errors {
            code
            payload {
              key
              value
            }
          }
        }
      }
    }
  }
`;
