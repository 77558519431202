/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IDesignerCommonProps } from '../types.ts';
import {
  handleChangeEvent,
  handleEventOccurred,
  getValidationError,
} from '../util.ts';
import { Bullet } from '../Bullet/index.ts';

export interface IDesignerTextBlockProps extends IDesignerCommonProps {
  title?: string;
}

export class DesignerTextBlock extends React.Component<
  IDesignerTextBlockProps,
  {}
> {
  public render() {
    const {
      id,
      title,
      canDelete,
      showBoundaryLines,
      showBullet = true,
      style,
      onChange,
      onEventOccurred,
      validationError,
    } = this.props;

    const computedStyles = {
      base: css({
        border: showBoundaryLines
          ? `dashed 1px ${color.format(-0.2)}`
          : 'solid 1px rgba(0, 0, 0, 0)',
        borderRadius: showBoundaryLines ? 8 : undefined,
      }),
    };

    return (
      <div css={[styles.outer, style]}>
        {showBullet && <Bullet />}
        <div css={[styles.base, computedStyles.base]}>
          <div css={styles.content}>
            <div css={styles.editIcons}>
              {canDelete && (
                <div title={'Remove Question'}>
                  <Icons.delete
                    onClick={handleEventOccurred(id, 'DELETE', onEventOccurred)}
                    cursor={'pointer'}
                    fill={color.format(-0.4)}
                    size={20}
                  />
                </div>
              )}
            </div>
            <TextField
              id={`designer-text-block-title-${id}`}
              onChange={handleChangeEvent(id, 'LABEL', onChange)}
              label={'Text Block - Text'}
              style={styles.fullWidth}
              value={title}
              multiline={true}
              error={getValidationError(validationError?.fields, 'title')}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    justifyContent: 'center',
    minHeight: 80,
    padding: '8px 20px 6px 10px',
    marginBottom: 5,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }),
  editIcons: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: 30,
  }),
  fullWidth: css({
    width: '100%',
  }),
};
