import * as R from 'ramda';
import {
  FormDesignerReduxDesignerState,
  IFormsDesignerIntegrationAddComponentToTemplate,
} from './types.ts';
import { locale } from './locale.ts';
import { DesignerComponentKind } from '../../components/types.ts';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import {
  FormLineType,
  FormLineComponent,
} from '@seeeverything/ui.forms/src/types/types.ts';

export function addComponentToTemplateReducer(
  state: FormDesignerReduxDesignerState,
  action: IFormsDesignerIntegrationAddComponentToTemplate,
) {
  if (!state.draft || !state.draft.lines || !state.draft.designerLines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      lines: addComponent(
        action.payload.id,
        state.draft.lines,
        action.payload.type,
        action.payload.insertBeforeId,
      ),
    },
  };
}

const addText = (
  id: string | number,
  label: string = locale.defaultLabel.default,
) => ({
  id,
  bullet: false,
  isVisible: true,
  type: 'inputs',
  isRequired: true,
  inputs: [
    {
      id,
      bullet: false,
      isVisible: true,
      isRequired: true,
      isEnabled: true,
      floatingText: label,
      placeholder: '',
      value: '',
      type: 'textAnswer',
    },
  ],
});

const addSection = (id: string | number, label: string) => ({
  id,
  type: 'section',
  title: label,
  bullet: false,
  index: [{ title: label }],
});

const addOptions = (id: string | number, label: string) => ({
  id,
  bullet: false,
  isVisible: true,
  isRequired: true,
  isEnabled: true,
  type: 'optionsAnswer',
  question: label,
  options: [
    { id: 'option-1', label: 'Yes' },
    { id: 'option-2', label: 'No' },
    { id: 'option-3', label: 'N/A' },
  ],
  size: 'medium',
});

const addSpeechBlock = (id: string | number, label: string) => ({
  id,
  bullet: false,
  isVisible: true,
  type: 'speechBlock',
  text: label,
});

const addTextBlock = (id: string | number, label: string) => ({
  id,
  bullet: false,
  isVisible: true,
  type: 'question',
  label,
});

const addSwitchAnswer = (id: string | number, label: string) => ({
  id,
  bullet: false,
  isVisible: true,
  type: 'switchAnswer',
  label,
});

// N.B. Maps from Designer Component Type to Forms Component Type.
const COMPONENT_FACTORIES: {
  [key in DesignerComponentKind]: {
    add: (id: string | number, label: string) => any;
    defaultLabel: string;
  };
} = {
  heading: {
    add: addSection,
    defaultLabel: locale.defaultLabel.section,
  },
  text: {
    add: addText,
    defaultLabel: locale.defaultLabel.textField,
  },
  options: {
    add: addOptions,
    defaultLabel: locale.defaultLabel.options,
  },
  speechBlock: {
    add: addSpeechBlock,
    defaultLabel: locale.defaultLabel.speechBlock,
  },
  textBlock: {
    add: addTextBlock,
    defaultLabel: locale.defaultLabel.textBlock,
  },
  switch: {
    add: addSwitchAnswer,
    defaultLabel: locale.defaultLabel.switchAnswer,
  },
};

const addComponent = (
  id: string | number,
  lines: FormLineById,
  type: DesignerComponentKind,
  insertBeforeId?: string | number,
): FormLineById => {
  const { add, defaultLabel } = COMPONENT_FACTORIES[type];

  const newLine = add(id, defaultLabel);
  return inject(lines, newLine, insertBeforeId);
};

const order = (type: FormLineType): number => {
  switch (type) {
    case 'digitalContent':
      return 2;

    case 'fileUpload':
      return 3;

    case 'attendance':
      return 4;

    case 'actionPlan':
      return 5;

    case 'signoff':
      return 6;

    default:
      return 1;
  }
};

/**
 * Injects a line into the collection of lines at the correct spot.
 */
const inject = (
  lines: FormLineById,
  newLine: FormLineComponent,
  insertBeforeId?: string | number,
): FormLineById => {
  const withOrder = Object.values(lines).map((line) => ({
    line,
    order: order(line.type),
  }));

  if (insertBeforeId === undefined) {
    withOrder.push({ line: newLine, order: order(newLine.type) });
  }
  const insertBeforeLine = (line: FormLineComponent) =>
    insertBeforeId !== undefined && line.id === insertBeforeId;

  const sorted = R.sortBy((item) => item.order, withOrder);
  return sorted
    .map((item) =>
      insertBeforeLine(item.line)
        ? { [newLine.id]: newLine, [item.line.id]: item.line }
        : { [item.line.id]: item.line },
    )
    .reduce((a, v) => ({ ...a, ...v }), {});
};
