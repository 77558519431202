import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { CsvDateRangeType } from '../../components/CsvDialog/types.ts';
import {
  IChangeCsvExportDateAction,
  IChangeCsvExportFiltersAction,
  ICsvExportFilter,
  ICsvExportSheetArgs,
  ICsvExportConfirmedAction,
  ICsvExportFiltersLoaded,
  ICsvExportLoad,
  ICsvExportReinitialize,
  ICsvExportReadyStatusCheckAction,
  ICsvExportReadyStatusCheckCompletedAction,
  ICsvExportReadyStatusCheckFailedAction,
  ICsvExportDone,
  CsvExportEventData,
} from './types.ts';

export const dateChanged = (
  to: string,
  type: CsvDateRangeType,
): IChangeCsvExportDateAction => ({
  type: 'ui.shell/csvExport/DATE_CHANGED',
  payload: { to, type },
});

export const selectedFiltersChanged = (
  to: string[],
): IChangeCsvExportFiltersAction => ({
  type: 'ui.shell/csvExport/SELECTED_FILTERS_CHANGED',
  payload: { to },
});

export const exportConfirmed = (): ICsvExportConfirmedAction => ({
  type: 'ui.shell/csvExport/CONFIRMED',
  payload: {},
});

export const exportReadyStatusCheck = (
  exportJobId: string,
  retryCounter: number,
  module: ModuleType,
  eventData: CsvExportEventData,
): ICsvExportReadyStatusCheckAction => ({
  type: 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK',
  payload: { exportJobId, retryCounter, module, _eventData: eventData },
});

export const exportReadyStatusCheckCompleted = (
  exportJobId: string,
  retryCounter: number,
  downloadUrl: string,
  eventData: CsvExportEventData,
): ICsvExportReadyStatusCheckCompletedAction => ({
  type: 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_COMPLETED',
  payload: { exportJobId, retryCounter, downloadUrl, _eventData: eventData },
});

export const exportReadyStatusCheckFailed = (
  exportJobId: string,
  retryCounter: number,
  reason: ICsvExportReadyStatusCheckFailedAction['payload']['reason'],
  eventData: CsvExportEventData,
  exception?: string,
): ICsvExportReadyStatusCheckFailedAction => ({
  type: 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_FAILED',
  payload: {
    exportJobId,
    retryCounter,
    reason,
    exception,
    _eventData: eventData,
  },
});

export const filtersLoaded = (
  templates: ICsvExportFilter[],
  categories: ICsvExportFilter[],
): ICsvExportFiltersLoaded => ({
  type: 'ui.shell/csvExport/FILTERS_LOADED',
  payload: { templates, categories },
});

export const exportDone = (): ICsvExportDone => ({
  type: 'ui.shell/csvExport/DONE',
  payload: {},
});

export const load = (
  startDate: string,
  endDate: string,
  dataSetId: string,
  sheetArgs?: ICsvExportSheetArgs,
  shouldLoadTemplatesAndCategories?: boolean,
): ICsvExportLoad => ({
  type: 'ui.shell/csvExport/LOAD',
  payload: {
    startDate,
    endDate,
    dataSetId,
    sheetArgs,
    shouldLoadTemplatesAndCategories,
  },
});

export const reinitialize = (): ICsvExportReinitialize => ({
  type: 'ui.shell/csvExport/REINITIALIZE',
  payload: {},
});
