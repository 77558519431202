import * as R from 'ramda';
import {
  Subject,
  from,
  Observable,
  map,
  filter,
  takeUntil,
  mergeMap,
  distinctUntilChanged,
} from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { value } from '@seeeverything/ui.util/src/value/index.ts';
import {
  IShowQueryDropdown,
  IRequestQueryDropdown,
  IQueryDropdownInlineFilterClicked,
  IQueryBlurAction,
  IQueryDropdownLoaded,
  IQueryAutocompleteDropdown,
  IQueryAction,
  IQueryDropdownState,
} from './types.ts';
import {
  dropdownLoaded,
  showDropdown,
  hideDropdown,
  requestDropdown,
} from './actions.dropdown.ts';
import * as actions from './actions.ts';
import { IShellAction, ShellGlobalState } from '../types.ts';
import {
  IQuerySelection,
  IDropdown,
} from '../../api/api.queryBuilder/types.ts';

const cancelLoad$ = new Subject<void>();

export const epics = combineEpics<IShellAction, IShellAction, ShellGlobalState>(
  autoCompleteEpic,
  focusInputWhenDropdownComponentChangedEpic,
  focusInputWhenDropdownLoadedEpic,
  hideOnBlurEpic,
  loadDataEpic,
  loadDropdownChangesOnInlineFilterClick,
  requestedDropdownEpic,
);

/**
 * When a request for a drop-down is made, ask the business-logic for
 * details about the dropdown to show (based on the current query state).
 */
function requestedDropdownEpic(
  action$: Observable<IRequestQueryDropdown>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_REQUEST'),
    filter(() => Boolean(state$.value.query.focus)),
    map((action) => {
      const { data, query, selection } = action.payload;
      const response = data.change({
        action: 'DROPDOWN',
        query,
        selection,
      });
      return response && !response.isCancelled ? response.dropdown : undefined;
    }),
    filter((dropdown) =>
      isDropdownChanged(state$.value.query.dropdown, dropdown),
    ),
    map((dropdown) => (dropdown ? showDropdown(dropdown) : hideDropdown())),
  );
}

/**
 * When an inline filter is clicked (e.g. Include Inactive People), reload the dropdown.
 */
function loadDropdownChangesOnInlineFilterClick(
  action$: Observable<IQueryDropdownInlineFilterClicked>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_INLINE_FILTER_CLICKED'),
    map((action) =>
      requestDropdown(
        action.payload.data,
        action.payload.query,
        action.payload.selection,
      ),
    ),
  );
}

/**
 * When a dropdown is shown, ensure it's data is loaded.
 */
function loadDataEpic(action$: Observable<IShowQueryDropdown>) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_SHOW'),
    filter((action) => value.isPromise(action.payload.dropdown.props)),
    mergeMap((action) => {
      cancelLoad$.next(null); // Cancel any prior load operation.
      return from(action.payload.dropdown.props).pipe(
        takeUntil(cancelLoad$),
        map((data) => dropdownLoaded(data)),
      );
    }),
  );
}

/**
 * Hide dropdown if QB is blurred and a drop-down is present.
 */
function hideOnBlurEpic(
  action$: Observable<IQueryBlurAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/BLUR'),
    filter(() => Boolean(state$.value.query.dropdown)),
    map(() => hideDropdown()),
  );
}

/**
 * When a visible dropdown changes it's [component] type
 * ensure focus is returned to the INPUT.
 */
function focusInputWhenDropdownComponentChangedEpic(
  action$: Observable<IShowQueryDropdown>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_SHOW'),
    distinctUntilChanged((prev, next) =>
      isDropdownComponentUnchanged(
        prev.payload.dropdown,
        next.payload.dropdown,
      ),
    ),
    filter(() => state$.value.query.focus !== 'INPUT'),
    map(() => {
      // The dropdown has changed type,
      // ensure focus is returned to the text Input.
      return actions.focus('INPUT');
    }),
  );
}

/**
 * When a dropdown with async props is loaded,
 * ensure the dropdown with loaded items is focused.
 */
function focusInputWhenDropdownLoadedEpic(
  action$: Observable<IQueryDropdownLoaded>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_LOADED'),
    filter(() => state$.value.query.focus !== 'DROPDOWN'),
    map(() => actions.focus('DROPDOWN')),
  );
}

/**
 * When an auto-complete for a dropdown is requested,
 * retrieve the data from the business logic and
 * load as a new query.
 */
function autoCompleteEpic(action$: Observable<IQueryAutocompleteDropdown>) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_AUTOCOMPLETE'),
    mergeMap((action) => {
      const result: IQueryAction[] = [];
      const { query, editingChip } = action.payload;

      if (query) {
        // Setup the selection if a chip is being edited.
        const chipIndex = editingChip ? editingChip.index : -1;
        const chip = query.chips[chipIndex];
        const selection =
          chip &&
          ({
            type: 'EDITING',
            chipIndex,
            chip,
          } as IQuerySelection);
        const isEditing = selection?.type === 'EDITING';

        // Add the next query.
        result.push(actions.next('CODE', query, selection));

        // Focus the text-input if not editing.
        if (!isEditing) {
          result.push(actions.focus('INPUT'));
        }
      }

      return from(R.reject(R.isNil, result));
    }),
  );
}

const isDropdownComponentUnchanged = (
  prev: IQueryDropdownState,
  next: IQueryDropdownState,
) => prev?.component === next?.component;

function isDropdownChanged(prev?: IDropdown, next?: IDropdown) {
  if ((prev && !next) || (!prev && next)) {
    return true;
  }

  return Boolean(prev && next && !R.equals(prev, next));
}
