import * as R from 'ramda';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { FormLineComponent } from '@seeeverything/ui.forms/src/types/types.ts';
import { RadioOption } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/RadioGroup.tsx';
import { includes } from 'ramda';

const DEFINITION_ROOT_KEYS = [
  'custom',
  'section',
  'actionPlan',
  'signoff',
  'fileUpload',
  'attendance',
  'digitalContent',
];

/**
 * Converts the lines structure to definition JSON for use within the service.
 */
export const convertLinesToDefinition = (
  lines: FormLineById,
  shouldGenerateUuids = true,
): Array<{}> => {
  if (!lines) {
    return [];
  }

  // Expand these to an array including its index.
  const allLines = Object.values(lines).map((line, index) => ({ line, index }));

  // Top-level items - many different components get nested under these.
  const rootItems = Object.values(lines)
    .map((line, index) => ({ line, index }))
    .filter(({ line }) => includes(line.type, DEFINITION_ROOT_KEYS));

  return R.reject(
    R.isEmpty,
    rootItems.map((rootItem, i) => {
      const index = rootItem.index;

      const nextItem = rootItems[i + 1];
      const nextIndex = nextItem ? nextItem.index : undefined;

      // Assumes all items between this index and next index are nested under this root-level item.
      const nestedItems = allLines.slice(index, nextIndex);

      return nestedItems
        .map((item) => lineToJson(item.line, shouldGenerateUuids))
        .reduce(R.mergeWith(mergeWith), {});
    }),
  );
};

/**
 * Converts a single form line to the JSON format used for server processing.
 */
export const lineToJson = (
  line?: FormLineComponent,
  shouldGenerateUuids?: boolean,
) => {
  if (!line) {
    return undefined;
  }

  switch (line.type) {
    case 'section':
      return {
        section: {
          title: line.title,
          id: isReservedId(line.id)
            ? line.id
            : toId(line.title, shouldGenerateUuids),
          index: [{ title: line.title }],
        },
      };

    case 'inputs':
      return {
        section: {
          questions: [
            {
              text: {
                bullet: true,
                fields: (line.inputs || []).map((input) => {
                  switch (input.type) {
                    case 'textAnswer':
                      return {
                        label: input.floatingText,
                        id: isReservedId(input.id)
                          ? input.id
                          : toId(input.floatingText, shouldGenerateUuids),
                        isRequired: true,
                        isEnabled: input.isEnabled,
                      };

                    case 'dateAnswer':
                      return {
                        hintText: input.floatingText,
                        id: isReservedId(input.id)
                          ? input.id
                          : toId(input.floatingText, shouldGenerateUuids),
                        kind: 'date',
                        isRequired: true,
                        isEnabled: input.isEnabled,
                      };

                    case 'dropdownAnswer':
                      return {
                        label: input.floatingText,
                        id: isReservedId(input.id)
                          ? input.id
                          : toId(input.floatingText, shouldGenerateUuids),
                        dropdownListName: input.dropdownListName,
                        isRequired: true,
                        isEnabled: input.isEnabled,
                      };

                    case 'numberAnswer':
                      return {
                        label: input.floatingText,
                        id: isReservedId(input.id)
                          ? input.id
                          : toId(input.floatingText, shouldGenerateUuids),
                        isRequired: true,
                        isEnabled: input.isEnabled,
                      };

                    case 'selectAnswer':
                      return {
                        label: input.floatingText,
                        id: isReservedId(input.id)
                          ? input.id
                          : toId(input.floatingText, shouldGenerateUuids),
                        isRequired: true,
                        isEnabled: input.isEnabled,
                      };
                  }
                }),
              },
            },
          ],
        },
      };

    case 'optionsAnswer':
      return {
        section: {
          questions: [
            {
              options: {
                bullet: true,
                isRequired: true,
                size: 'medium',
                question: line.question,
                id: toId(line.question, shouldGenerateUuids),
                answers: (line.options as RadioOption[]).map((answer) => ({
                  ...answer,
                  id: toId(answer.label, shouldGenerateUuids),
                })),
              },
            },
          ],
        },
      };

    case 'speechBlock':
      return {
        section: {
          questions: [
            {
              script: {
                id: toId(line.text, shouldGenerateUuids),
                text: line.text,
              },
            },
          ],
        },
      };

    case 'switchAnswer':
      return {
        section: {
          questions: [
            {
              switch: {
                id: toId(line.label, shouldGenerateUuids),
                label: line.label,
              },
            },
          ],
        },
      };

    case 'question':
      return {
        section: {
          questions: [
            {
              question: {
                id: toId(line.label, shouldGenerateUuids),
                label: line.label,
                bullet: false,
              },
            },
          ],
        },
      };

    case 'actionPlan':
      return {
        actionPlan: {
          isEnabled: line.isEnabled,
          showIndex: true,
          title: line.title ?? line.designerTitle,
          designerHintText: line.designerHintText,
        },
      };

    case 'fileUpload':
      return {
        fileUpload: {
          isEnabled: line.isEnabled,
          showIndex: true,
          title: line.title ?? 'Files',
        },
      };

    case 'signoff':
      return {
        signoff: {
          reviewerPlaceholderName: line.reviewerPlaceholderName,
          reviewerRequired: line.reviewerRequired,
          reviewerLabel: line.reviewerLabel,
          subjectPlaceholderName: line.subjectPlaceholderName,
          subjectRequired: line.subjectRequired,
          subjectLabel: line.subjectLabel,
          showIndex: true,
          title: line.title ?? 'Sign-off',
        },
      };

    case 'attendance':
      return {
        attendance: {
          isEnabled: line.isEnabled,
          mandatoryFollowUp: line.mandatoryFollowUp,
          showIndex: true,
          title: line.title ?? 'Attendance',
          designerHintText: line.trackAttendanceHintText,
        },
      };

    case 'digitalContent':
      return {
        digitalContent: {
          isEnabled: line.isEnabled,
          showIndex: true,
          title: line.title ?? 'Digital Content',
          description: line.description,
          commentsEnabled: line.commentsEnabled,
          commentsGuidance: line.commentsEnabled ? line.commentsGuidance : '',
          packId: line.packId,
        },
      };

    default:
      return undefined;
  }
};

/**
 * Deep merge with array concatenation specifically for `questions`.
 */
const mergeWith = (a: any, b: any) => {
  if (!a.questions || !b.questions) {
    return {
      ...a,
      ...b,
    };
  }

  return {
    ...a,
    ...b,
    questions: [...a.questions, ...b.questions],
  };
};

const RESERVED = [
  'header-section-title',
  'reportingDate',
  'subject',
  'assignedTo',
  'dueDate',
  'actionPlan',
  'fileUpload',
  'signoff',
  'attendance',
  'digitalContent',
];

const isReservedId = (id: string | number) => {
  return Boolean(id && RESERVED.includes(id.toString()));
};

const toId = (input?: string, shouldGenerateUuids?: boolean) => {
  const newUuid = shouldGenerateUuids ? ` (${uuid.generate()})` : '';
  return `${input}${newUuid}`;
};
