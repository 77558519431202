import { SerializedStyles } from '@emotion/react';
import { DashboardDateFilterPeriod } from '@se/data/dashboards/query/types.ts';
import { FormActionStatus } from '@se/data/forms/types.ts';
import { IChartProps } from '@seeeverything/ui.charts/src/components/Chart/Chart.tsx';
import { ChartKind } from '@seeeverything/ui.charts/src/components/Chart/types.ts';
import { FormGoalStatus } from '@seeeverything/ui.forms/src/redux/form-edit-goal/types.ts';
import {
  CellTheme,
  IDataGridCellTheme,
  IDataGridColumn,
  IDataGridHeaderGroup,
  IRenderDataGridCellArgs,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import {
  IToolButton,
  IToolButtonSetProps,
} from '@seeeverything/ui.primitives/src/components/ToolButtonSet/index.ts';
import { FontWeight, OrderBy } from '@seeeverything/ui.util/src/types.ts';
import {
  DashboardClickThrough,
  IContentPackSelectionListItem,
} from './data/types.ts';

export const COMPONENT_HEADER = 'HEADER';
export const COMPONENT_CHART = 'CHART';
export const COMPONENT_GRID = 'GRID';
export const COMPONENT_GRID_TOOLS = 'GRID_TOOLS';
export const COMPONENT_CHART_TOOLS = 'CHART_TOOLS';
export const COMPONENT_SECTION = 'SECTION';
export const COMPONENT_COMMENTS_LIST = 'COMMENTS_LIST';
export const COMPONENT_CLICK_THROUGH_GRID = 'CLICK_THROUGH_GRID';
export const COMPONENT_DIGITAL_CONTENT = 'DIGITAL_CONTENT';
export const COMPONENT_GOALS_AND_ACTIONS = 'GOALS_AND_ACTIONS';

export type ComponentType =
  | typeof COMPONENT_HEADER
  | typeof COMPONENT_CHART
  | typeof COMPONENT_GRID
  | typeof COMPONENT_GRID_TOOLS
  | typeof COMPONENT_CHART_TOOLS
  | typeof COMPONENT_SECTION
  | typeof COMPONENT_COMMENTS_LIST
  | typeof COMPONENT_CLICK_THROUGH_GRID
  | typeof COMPONENT_DIGITAL_CONTENT
  | typeof COMPONENT_GOALS_AND_ACTIONS;

export const COMPONENTS: Array<ComponentType> = [
  COMPONENT_HEADER,
  COMPONENT_CHART,
  COMPONENT_GRID,
  COMPONENT_GRID_TOOLS,
  COMPONENT_CHART_TOOLS,
  COMPONENT_SECTION,
  COMPONENT_COMMENTS_LIST,
  COMPONENT_CLICK_THROUGH_GRID,
  COMPONENT_DIGITAL_CONTENT,
];

export type DashboardV2ViewEntity = (
  gridId: string,
  entity: { id: string; type: DashboardV2Entity; label?: string },
) => void;

export interface IDashboardEntity {
  id: string;
  type: DashboardV2Entity;
  label?: string;
}

export type DashboardV2Entity =
  | 'Person'
  | 'Team'
  | 'Form'
  | 'Action'
  | 'Goal'
  | 'Category'
  | 'Answer';

export interface IGridCellDecoration {
  alignment?: IGridCellAlignment;
  icon?: IGridCellIcon;
  text?: IGridCellText;
  tooltip?: IGridCellTooltip;
  launch?: IGridCellLaunch;
  link?: IGridCellLink;
  dataTest?: string;
}

export type IGridCellAlignment = 'left' | 'center' | 'right';

export interface IGridCellIcon {
  icon?: string;
  color?: string;
  leftIndent?: boolean;
  divider?: boolean;
  label?: string;
  size?: number;
}

export interface IGridCellText {
  datapoint?: any;
  label?: string;
  color?: string;
  ellipsis?: boolean;
  size?: number;
  weight?: FontWeight;
  italic?: boolean;
  style?: SerializedStyles;
}

export interface IGridCellLink {
  handler?: () => void;
}

export interface IGridCellTooltip {
  body: string | React.ReactElement;
}

export interface IGridCellLaunch {
  handler?: () => void;
}

export interface IGridCellDecorateArgs {
  datapoint: IGridCellDatapoint;
  cellTheme: IDataGridCellTheme;
  cellArgs: IRenderDataGridCellArgs<IDashboardGridRow>;
  columnDefinition: IDashboardExtendedGridColumn;
  launchHandler: (
    args: IRenderDataGridCellArgs<IDashboardGridRow>,
  ) => () => void;
  linkHandler: (args: IRenderDataGridCellArgs<IDashboardGridRow>) => () => void;
  categoryClickedHandler?: (
    args: IRenderDataGridCellArgs<IDashboardGridRow>,
  ) => () => void;
  viewEntity: DashboardV2ViewEntity;
  gridState: IDashboardGrid;
}

export type GridCellDecorator = (
  args: IGridCellDecorateArgs,
) => IGridCellDecoration;

export interface IGridCellDatapoint {
  displayValue: string;
  value: string | number;
  displayPreviousValue?: string;
  previousValue?: string;
  displayTrend?: string;
  trend?: number;
  rowTags?: string[];
  isMasked?: boolean;
}

export interface IDashboardContentMapping {
  icon?: string;
  color?: string;
  bgColor?: string;
  label?: string;
  weight?: FontWeight;
  minValueInclusive?: number;
  minValue?: number;
  maxValueInclusive?: number;
  maxValue?: number;
  equalsValue?: string | string[];
  rowTag?: string;
  textIcon?: string;
  textIconColor?: string;
}
export interface IDashboardTrendMapping {
  icon: string;
  color: string;
  label: string;
  size: number;
  displayComparativeValue?: string;
  displayChangeValue?: string;
  minValueInclusive?: number;
  minValue?: number;
  maxValueInclusive?: number;
  maxValue?: number;
}
export interface IDashboardCategoryMapping {
  value: string;
  scrollToId: string;
}
export interface IDashboardExtendedGridColumn<T extends string = string>
  extends IDataGridColumn {
  id: T;
  contentMap?: IDashboardContentMapping[];
  trendMap?: IDashboardContentMapping[];
  categoryMap?: IDashboardCategoryMapping[];
  canLaunch?: boolean;
  clickThrough?: IDashboardClickThroughGrid;
}

export interface IToggleValues<T> {
  toolTypeState: { [x: string]: T };
}

export interface IDashboardClickThroughGrid {
  gridId: string;
  definitionGridId: string;
  type: 'SESSIONS' | 'GOALS_AND_ACTIONS';
}

export type DashboardLevel = 'h1' | 'h2' | 'h3';

export interface IDashboardDataState<T = any | any[]> {
  isLoading: boolean;
  isLoaded: boolean;
  forceReload: boolean;
  dataSetId: string;
  data?: IDataPayload<T>;
  error?: string;
}

export interface IDataPayloadPagination {
  hasNextPage: boolean;
  currentPage: number;
  totalCount: number;
  pageSize: number;
}

export interface IDataPayload<T = any | any[]> {
  data?: T;
  pagination?: IDataPayloadPagination;
  entityType?: 'person' | 'team';
  entityId?: string;
  clickThrough?: DashboardClickThrough;
  templateId?: string;
}

export type GridDataPoint = {
  key: string;
  value?: string;
  isMasked?: boolean;
};

export type GridDataRow = {
  id: string;
  tags: string[];
  entity?: {
    id: string;
    label?: string;
    type: DashboardV2Entity;
  };
  data: GridDataPoint[];
};

export interface IGridDataPayload extends IDataPayload {
  data: GridDataRow[];
  footer?: GridDataRow[];
}

export interface IDashboardComponent<T = any | any[]> {
  componentType: ComponentType;
  id: string;
  index: number;
  dataState: IDashboardDataState<T>;
  parentSectionId?: string;
  level?: DashboardLevel;
  title?: string;
  icon?: string;
}

export interface IDashboardChart extends IDashboardComponent, IChartProps {
  componentType: 'CHART';
  kind: ChartKind;
  isHidden: boolean;
  group?: string;
}

export interface IDashboardGrid
  extends IDashboardComponent<DashboardDataRow[]> {
  clickThrough?: DashboardClickThrough;
  columns: IDashboardExtendedGridColumn[];
  componentType: 'GRID' | 'CLICK_THROUGH_GRID';
  entityPath?: string;
  footer?: IGridRow | false;
  group: string;
  hasExpandButton?: boolean;
  headerGroups?: IDataGridHeaderGroup[];
  icon?: string;
  initialOrderBys: OrderBy[];
  isHidden: boolean;
  isLoadingPage?: boolean;
  name: string;
  pageSize?: number;
  tagStyles?: { [tag: string]: CellTheme };
  tempFormStatusExcludeCompleted?: boolean;
}

export interface IDashboardGridTools
  extends IDashboardComponent,
    IToolButtonSetProps {
  componentType: 'GRID_TOOLS';
  group: string;
  toolButtons: IDashboardToolButton[];
}

export interface IDashboardChartTools
  extends IDashboardComponent,
    IToolButtonSetProps {
  componentType: 'CHART_TOOLS';
  group: string;
  toolButtons: IDashboardToolButton[];
}

export interface IDashboardTools<T extends ComponentType>
  extends IDashboardComponent,
    IToolButtonSetProps {
  componentType: T;
  group: string;
  toolButtons: IDashboardToolButton[];
}

export interface IDashboardToolButton extends IToolButton {
  parentId: string;
  toolId?: string;
  fallbackToolId?: string;
  context?: { parentId?: string };
}

export interface IToolButtonValues {
  buttons: IDashboardToolButton[];
}

export interface IDashboardSection extends IDashboardComponent {
  componentType: 'SECTION';
  title: string;
  icon: string;
  showBullet: boolean;
}

export type DashboardComment = {
  id: string | number;
  subject: string;
  occurredAt: string;
  description: DashboardCommentType;
  form: { id: string; name: string };
};

export type DashboardCommentType =
  | DashboardBasicCommentType
  | DashboardCommentDescriptionList;

export type DashboardBasicCommentType = {
  kind: 'BASIC_COMMENT';
  description: string;
};

export type DashboardCommentDescriptionList = {
  kind: 'DESCRIPTION_LIST';
  items: DashboardCommentDescriptionListItem[];
};

export type DashboardCommentDescriptionListItem = {
  title: string;
  description: string;
};

export interface IDashboardCommentDataKeyItem {
  description: string;
  label: string;
}

export type DashboardCommentDataKeys = {
  subject: string;
  description: string | IDashboardCommentDataKeyItem[];
  occurredAt: string;
};

export type DashboardEntity = {
  id: string;
  type: 'Person' | 'Team' | 'Form' | 'Goal' | 'Action' | 'Category' | 'Answer';
  label: string;
};

export type DashboardDataRow = {
  id: string;
  key: string;
  tags: string[];
  entity: DashboardEntity;
  data: Array<{ key: string; value?: string }>;
};

export interface IDashboardCommentsList
  extends IDashboardComponent<DashboardDataRow[]> {
  componentType: 'COMMENTS_LIST';
  title: string;
  emptyText: string;
  dataKeys: DashboardCommentDataKeys;
  kind?: 'BASIC_COMMENT' | 'DESCRIPTION_LIST';
  isLoadingPage?: boolean;
  orderBy: OrderBy[];
}

export interface IDashboardDigitalContent extends IDashboardComponent {
  componentType: 'DIGITAL_CONTENT';
  packSelector: IDashboardDigitalContentPackSelector;
}

export interface IDashboardDigitalContentPackSelector {
  label: string;
  selectedContentPack?: IContentPackSelectionListItem;
}

export interface IDashboardGoalsAndActions extends IDashboardComponent {
  componentType: 'GOALS_AND_ACTIONS';
}

export type DashboardGoalsAndActionsGridItemType =
  | 'Goal'
  | 'GoalAction'
  | 'Action'
  | 'IssueAction';

export type DashboardGoalsAndActionsGridItem = {
  id: string;
  assignedTo: string;
  createdAt: string;
  description: string;
  dueDate: string;
  status: FormGoalStatus | FormActionStatus;
  type: DashboardGoalsAndActionsGridItemType;
};

export interface IDashboardHeader extends IDashboardComponent {
  componentType: 'HEADER';
  icon: string;
  metrics: IDashboardHeaderMetric[];
  dateFilters?: DashboardDateFilter[];
  defaultDateFilter?: DashboardDateFilter;
}

export type DashboardDateFilter =
  | IDashboardDateFilterTodayBackwards
  | IDashboardDateFilterRepeatingFrequency
  | IDashboardDateFilterCustomRange;

export interface IDateRange {
  startDate?: string;
  endDate?: string;
}

export interface IDateFilterLabelCustomOptions {
  startDateISO: string;
  endDateISO: string;
}

export type DashboardDateFilterOptions = {
  financialYearStartMonth?: number;
  customOptions?: IDateFilterLabelCustomOptions;
};

export interface IDashboardDateFilterBase {
  id: string;
  toLabel: (options: DashboardDateFilterOptions) => string;
  isDefault: boolean;
}

export type DashboardDateFilterKind =
  | 'TODAY_BACKWARDS'
  | 'CUSTOM_RANGE'
  | 'REPEATING_FREQUENCY';

export type DashboardDateFilterSelection = {
  selection: DashboardDateFilter;
  financialYearStartMonth?: number;
  selectedCustomDates?: IDateFilterLabelCustomOptions;
};

export interface IDashboardDateFilterTodayBackwards
  extends IDashboardDateFilterBase {
  period: DashboardDateFilterPeriod;
  amount: number;
  startDate: Date;
  endDate: Date;
  kind: 'TODAY_BACKWARDS';
}

export interface IDashboardDateFilterCustomRange
  extends IDashboardDateFilterBase {
  minAllowedStartDate?: Date;
  maxAllowedEndDate?: Date;
  kind: 'CUSTOM_RANGE';
}

export interface IDashboardDateFilterRepeatingFrequency
  extends IDashboardDateFilterBase {
  period: DashboardDateFilterPeriod;
  startDate: (options: DashboardDateFilterOptions) => Date;
  endDate: (options: DashboardDateFilterOptions) => Date;
  amount: number;
  recurrenceNumber: number;
  kind: 'REPEATING_FREQUENCY';
}

export interface IDashboardGridRow extends IGridRow {
  id: string;
  entity?: IDashboardEntity;
  data: string[];
  tags?: string[];
}

export type MetricValueFormatter = 'raw' | 'number-complete' | 'percentage';

export interface IDashboardHeaderMetric {
  label: string;
  icon: string;
  valueFormatter: MetricValueFormatter;
  dataKey: string;
  valueLabel: string;
  precision?: number;
  contentMap?: IDashboardContentMapping[];
  trendMap?: IDashboardTrendMapping[];
}

export type DashboardComponent =
  | IDashboardHeader
  | IDashboardChart
  | IDashboardGrid
  | IDashboardGridTools
  | IDashboardChartTools
  | IDashboardSection
  | IDashboardCommentsList
  | IDashboardDigitalContent
  | IDashboardGoalsAndActions;

export interface IDashboardTemplate {
  id?: string;
  definition: string;
}

export type ParserOptions = {
  tenantTimezone: string;
};
