import { authorizationQuery } from '@se/data/authorization/query/index.ts';
import { configurationQuery } from '@se/data/configuration/query/index.ts';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { Person } from '@se/data/orgHierarchy/types.ts';
import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { templates } from '@seeeverything/ui.dashboards/src/data/templates.ts';
import { ClickThroughGridValuesType } from '@seeeverything/ui.dashboards/src/data/types.ts';
import {
  setCustomDateRange,
  setDateFilter,
} from '@seeeverything/ui.dashboards/src/redux/actions.ts';
import { dashboardGridsSlice } from '@seeeverything/ui.dashboards/src/redux/index.ts';
import { DashboardV2TemplatesByModule } from '@seeeverything/ui.shell/src/components/types.ts';
import {
  initializePermissions,
  initialized,
} from '@seeeverything/ui.shell/src/redux/app/actions.ts';
import { replaceChips } from '@seeeverything/ui.shell/src/redux/query/actions.ts';
import {
  changeDateRange,
  clearDateRange,
} from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { monitoring } from '@seeeverything/ui.util/src/monitoring/index.ts';
import { toStore$ } from '@seeeverything/ui.util/src/redux/redux.ts';
import {
  TenantFeatures,
  tenantSlice,
} from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/index.ts';
import { decodeBase64 } from '@seeeverything/ui.util/src/str/str.base64.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import {
  Chip,
  parseChips,
} from '@seeeverything/ui.util/src/urlDeepLink/urlDeepLink.ts';
import { Subject } from 'rxjs';
import {
  getInitializedApp,
  isAppInitialized,
  setAppInitialized,
} from './app.ts';
import { db } from './common/index.ts';
import { init as queryBuilderInit } from './config/config.queryBuilder/index.ts';
import { IAddChipOptions, addChip } from './config/config.sheets/query.ts';
import { sheetContentFactory } from './config/contentFactory.tsx';
import { data } from './data/index.ts';
import { env } from './env.ts';
import { AppDispatch } from './redux/configureStore.ts';
import { DateFilterType, EnhancedStore, IInitializedApp } from './types.ts';
import { moduleUtil } from './util/index.ts';

export type InitOptions = {
  initialColumnFilters?: Record<string, string[]>;
  initialColumnSorts?: Record<string, string>;
  initialDateFilter?: DateFilterType;
  initialModule: ModuleType;
  initialPath?: string;
  initialTenant: string;
  store: EnhancedStore;
};

/**
 * Initializes the module (entry point).
 *
 * TODO: This single function is getting really complex. As a starting point, this just needs to be
 * broken up into smaller testable segments.
 *
 * Current App initialization sequence:
 * - Create the redux store.
 * - Ensure tenantId is set and present, and initialize tenant config.
 * - Initialize the feature toggle (overridden by module).
 * - Set the locale (and set overrides by module).
 * - Initialize the Query Builder.
 * - Load V2 dashboard templates from the dashboards service (if enabled).
 * - Query for and set app permissions.
 * - Initialize the sheet content factory, and complete initializing the app.
 * - Pre-fetch some data the app will use later (people/team dropdowns, form categories and templates).
 * - Set the module chip and load the user's default dashboard.
 * - Sets the app state initialized flag in Redux.
 */
export async function init({
  initialColumnFilters,
  initialColumnSorts,
  initialDateFilter,
  initialModule,
  initialPath,
  initialTenant,
  store,
}: InitOptions): Promise<IInitializedApp> {
  // Only allow initialization once. This is left here for backwards compatibility.
  if (isAppInitialized()) {
    return getInitializedApp();
  }

  // Configure app.
  const dispatch = store.dispatch;

  const client = db.client;

  const tenantId = initialTenant ?? storageApi.tenant.getLocalStorage();

  if (!tenantId) {
    throw new Error('A fatal error has occurred during start-up.');
  }

  // Locates and puts the config into the redux store.
  const tenantConfigResponse = await configurationQuery.getTenantConfiguration(
    db.batchClient,
    tenantId,
  );
  if (!tenantConfigResponse.isSuccess)
    throw new Error('Unable to retrieve configuration');

  const tenantConfig = tenantConfigResponse.data.configuration;

  const module = moduleUtil.initializeModuleStorage(
    tenantConfig,
    initialModule,
  );

  dispatch(
    tenantSlice.setConfiguration({
      id: tenantId,
      module,
      configuration: tenantConfig,
    }),
  );

  const tenants = await authApi.getTenants(client);
  const isMultiTenant = Object.keys(tenants).length > 1;
  dispatch(tenantSlice.setMultiTenant(isMultiTenant));

  const tenantFeatures = store.getState().tenantState.tenant?.features;
  const locale = store.getState().tenantState.tenant?.locale;

  dispatch(
    tenantSlice.setReportingUrl(buildReportingUrl(tenantFeatures, tenantId)),
  );

  if (module) {
    dispatch(tenantSlice.setModule(module));
  }

  const queryBuilder = await queryBuilderInit(tenantConfig, locale);

  const userTenants = await authApi.getTenants(client);
  const personId = userTenants[tenantId].personId;

  monitoring.setUser({ id: personId });
  monitoring.setCustomData({ tenant: tenantId, version: env.VERSION() });

  const authenticatedUser = await data.personById(personId);
  const userPermissionsResult =
    await authorizationQuery.getUserPermissions(client);
  const userPermissions = userPermissionsResult.isSuccess
    ? userPermissionsResult.data
    : undefined;

  const allowedModules = moduleUtil.allowedModules(tenantConfig);

  const dashboardV2Templates = await loadDashboardYamlTemplates(
    client,
    allowedModules,
  );

  const personDashboardTemplateId = getPersonDashboardTemplateId(
    dashboardV2Templates,
    allowedModules,
  );

  const personPrimaryTeam =
    personDashboardTemplateId &&
    (await data.personPrimaryTeam(personId, personDashboardTemplateId));

  const formPermissionsResponse = await formsQuery.getFormPermissions(client, {
    module,
    tenantFeatures,
  });

  const formPermissions = formPermissionsResponse.isSuccess
    ? formPermissionsResponse.data
    : {
        scheduleManage: false,
        templateManage: false,
        instanceCreate: false,
        bulkFormLoadManage: false,
        automatedActionManage: false,
      };
  dispatch(initializePermissions(formPermissions));

  // Store initialization state.
  const initializedApp: IInitializedApp = {
    store,
    store$: toStore$(store),
    queryBuilder,
    sheetContentFactory: sheetContentFactory(),
    dashboardV2Templates,
    updatedApp$: new Subject<IInitializedApp>(),
  };

  setAppInitialized(initializedApp);

  dispatch(
    tenantSlice.setAuthenticatedUser({
      id: authenticatedUser.id,
      firstName: authenticatedUser.firstName,
      lastName: authenticatedUser.lastName,
      positionTitle: authenticatedUser.positionTitle,
      primaryTeam: personPrimaryTeam,
      emailDomain: await authApi.getEmailDomain(),
      permissions: {
        canAccessReporting: Boolean(userPermissions?.canAccessReporting),
      },
    }),
  );
  dispatch(initialized());

  if (initialDateFilter && initialDateFilter.id !== 'CUSTOM_RANGE') {
    dispatch(setDateFilter(initialDateFilter.id, false));
    dispatch(clearDateRange('DASHBOARDS'));
  }

  if (
    initialDateFilter?.id === 'CUSTOM_RANGE' &&
    initialDateFilter?.customStart &&
    initialDateFilter?.customEnd
  ) {
    const { customStart, customEnd } = initialDateFilter;
    dispatch(setDateFilter(initialDateFilter.id, false));
    dispatch(setCustomDateRange(customStart, customEnd, false));
    dispatch(changeDateRange('START', customStart, 'DASHBOARDS'));
    dispatch(changeDateRange('END', customEnd, 'DASHBOARDS'));
  }

  dispatchUrlPathToChipsActions(dispatch, initialPath, authenticatedUser);

  if (initialPath) {
    const chips = parseChips(initialPath);
    dispatchGridColumnFiltersAndSorts(
      dispatch,
      module,
      chips,
      initialColumnFilters,
      initialColumnSorts,
    );
  }

  monitoring.addPageView('/init');

  return initializedApp;
}

export const dispatchGridColumnFiltersAndSorts = (
  dispatch: AppDispatch,
  module: ModuleType,
  chips?: Chip[],
  initialColumnFilters?: Record<string, string[]>,
  initialColumnSorts?: Record<string, string>,
) => {
  if (!chips?.length) return;

  const lastChip = chips[chips.length - 1];
  const prevChip = chips[chips.length - 2];

  if (
    lastChip.type === 'gridV2' &&
    ['people', 'team'].includes(prevChip?.type)
  ) {
    const gridKey = dashboardGridsSlice.utils.createGridKey({
      module,
      entityId: prevChip.value,
      gridId: lastChip.value,
    });

    dispatch(
      dashboardGridsSlice.initializeGrid({
        gridKey,
        orderBys: initialColumnSorts
          ? Object.entries(initialColumnSorts).map(([columnId, direction]) => ({
              columnId,
              direction: direction as 'Ascending' | 'Descending',
            }))
          : [],
        columnSelectedFilters: initialColumnFilters,
      }),
    );
  }

  if (lastChip.type === 'clickThroughGrid') {
    const clickThroughValues: ClickThroughGridValuesType = JSON.parse(
      decodeBase64(lastChip.value),
    );

    dispatch(
      dashboardGridsSlice.initializeGrid({
        gridKey: dashboardGridsSlice.utils.createGridKey({
          module,
          gridId: clickThroughValues.sourceGridId,
          dataSetId: clickThroughValues.sourceDataSetId,
          rowId: clickThroughValues.sourceGridRowId,
          columnId: clickThroughValues.sourceGridColId,
          entityId: clickThroughValues.templateEntityId,
        }),
        orderBys: initialColumnSorts
          ? Object.entries(initialColumnSorts).map(([columnId, direction]) => ({
              columnId,
              direction: direction as 'Ascending' | 'Descending',
            }))
          : [],
        columnSelectedFilters: initialColumnFilters,
      }),
    );
  }
};

const dispatchUrlPathToChipsActions = (
  dispatch: AppDispatch,
  urlPath?: string,
  authenticatedUser?: Person,
) => {
  const isPathValid = urlDeepLinkUtil.isUrlPathValid(urlPath);

  const isUserDashboard = urlDeepLinkUtil.isAuthenticatedUserDashboard(
    urlPath,
    authenticatedUser?.id,
  );

  const isAnyTeamOrPersonDashboard =
    urlDeepLinkUtil.containsAnyTeamOrPersonDashboard(urlPath);

  if (authenticatedUser && (!isAnyTeamOrPersonDashboard || isUserDashboard)) {
    dispatch(loadPersonDashboardAction(authenticatedUser));
  }

  if (isPathValid && !isUserDashboard) {
    const chips = urlDeepLinkUtil.parseChips(urlPath);
    dispatch(replaceChips(chips, false));
  }
};

const loadPersonDashboardAction = ({ id, firstName, lastName }: Person) => {
  const options: IAddChipOptions = {
    id,
    type: 'people',
    label: `${firstName} ${lastName}`,
  };

  return addChip(options);
};

function buildReportingUrl(tenantFeatures: TenantFeatures, tenantId: string) {
  const reportingUrl = env.REPORTING_URL();
  if (!reportingUrl) return undefined;

  const isLegacyUrl = reportingUrl.endsWith('/app/main#/home');
  if (isLegacyUrl) return reportingUrl;

  const sisenseTenant = tenantFeatures.QUERY
    .SETTINGS_SISENSE_URL_SELF_CONTAINED_TENANT
    ? tenantId
    : `main?tenant=${tenantId}`;

  return `${reportingUrl}/${sisenseTenant}`;
}

async function loadDashboardYamlTemplates(
  client: IGraphQLClient,
  modules: ModuleType[],
) {
  const moduleTemplates =
    modules && modules.length
      ? modules.map(async (module) => ({
          [module]: await templates(client, module),
        }))
      : undefined;

  if (!moduleTemplates) {
    return;
  }

  return (await Promise.all(moduleTemplates)).reduce((a, v) => ({
    ...a,
    ...v,
  })) as DashboardV2TemplatesByModule;
}

const getPersonDashboardTemplateId = (
  dashboardV2Templates: DashboardV2TemplatesByModule,
  allowedModules: ModuleType[],
): string | undefined => {
  for (const allowedModule of allowedModules) {
    const templateId = dashboardV2Templates?.[allowedModule]?.person?.id;
    if (templateId) {
      return templateId;
    }
  }
  return undefined;
};
