/** @jsxImportSource @emotion/react */
import React from 'react';
import { connect } from 'react-redux';
import {
  TextInputConfirmationDialog,
  ITextInputConfirmationDialogProps,
} from './TextInputConfirmationDialog.tsx';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';

interface IViewProps extends ITextInputConfirmationDialogProps {
  okActions: (reason: string) => ReduxAction[];
  cancelActions: () => ReduxAction[];
}

interface IViewDispatchProps {
  dispatch: (action: ReduxAction) => void;
}

const View: React.FC<IViewProps & IViewDispatchProps> = ({
  dispatch,
  okActions,
  cancelActions,
  ...dialogProps
}) => {
  const handleOk = (reason: string) => {
    okActions(reason).forEach((action) => dispatch(action));
  };

  const handleCancel = () => {
    cancelActions().forEach((action) => dispatch(action));
  };

  return (
    <TextInputConfirmationDialog
      {...dialogProps}
      onActionOK={handleOk}
      onActionCancel={handleCancel}
    />
  );
};

export default connect<{}, IViewDispatchProps, IViewProps>(
  () => ({}),
  (dispatch) => ({ dispatch }),
)(View);
