/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DOCUMENT_UPLOAD } from '@seeeverything/ui.forms/src/common/constants.ts';
import { FormFilesPanel } from '@seeeverything/ui.forms/src/components/FormFilesPanel/FormFilesPanel.tsx';
import {
  IFormFile,
  IFormRejectedFile,
} from '@seeeverything/ui.forms/src/redux/form-file/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { useCallback } from 'react';

export interface IUploadTabProps {
  locale: TenantLocale;
  files?: IFormFile[];
  onFileRejected?: (files: File[], maxSize?: number) => void;
  onFileUpload: (files?: File[]) => void;
  rejections?: IFormRejectedFile[];
}

export const UploadTab: React.FC<IUploadTabProps> = ({
  files,
  onFileRejected,
  onFileUpload,
  rejections,
}) => {
  const handleFileUpload = useCallback(
    (uploadFiles: File[]) => {
      if (uploadFiles?.length) {
        onFileUpload(uploadFiles as File[]);
      }
    },
    [onFileUpload],
  );

  const handleRejectedFile = useCallback(
    (rejectedFiles: File[]) => {
      onFileRejected?.(rejectedFiles, DOCUMENT_UPLOAD.maxSize);
    },
    [onFileRejected],
  );

  return (
    <div css={styles.body}>
      <Text style={styles.fileUploadLabel}>{'Upload File'}</Text>
      <FormFilesPanel
        allowedMimeTypes={{
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
          ],
          'application/vnd.ms-excel': ['.xls'],
          'text/csv': ['.csv'],
        }}
        allowedTypesReadable={'.csv, .xls, .xlsx'}
        canDelete={Boolean(files?.length === 1)}
        canUpload={Boolean(files?.length === 0)}
        emptySize={'small'}
        files={files}
        maxSize={DOCUMENT_UPLOAD.maxSize}
        onFilesRejected={handleRejectedFile}
        onFilesUpload={handleFileUpload}
        rejections={rejections}
      />
    </div>
  );
};

const styles = {
  body: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    padding: '0 25px 25px 25px',
  }),
  fileUploadLabel: css({
    fontSize: 16,
    fontWeight: 'bold',
    padding: '20px 0 20px 0',
    width: '100%',
  }),
};
