import { Analytics } from '@segment/analytics-next';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { IGraphQLClient } from '../../graphql/types.ts';

export type ReduxAnalyticsDependencies<
  A extends Action = Action,
  S extends {} = {},
> = {
  analyticsClient?: () => Promise<Analytics | undefined>;
  analyticsInfoProvider?: (
    analyticsRequest: AnalyticsRequest<A, S>,
  ) => Promise<AnalyticsResponse>;
};

export enum AnalyticsAction {
  identify,
  track,
}

export type AnalyticsEvent = {
  action: AnalyticsAction;
  eventIdentifier: string;
  payload: any;
};

export type AnalyticsRequest<A extends Action = Action, S extends {} = {}> = {
  action: A;
  state$: StateObservable<S>;
  client: IGraphQLClient;
};

export type AnalyticsResponse = {
  events: AnalyticsEvent[];
};
