import { PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import * as types from './types.ts';
import { IBulkUploadJob } from './types.ts';

export function loadJobs(
  isLoadingMore = false,
): types.ReduxFormsBulkUploadJobsLoadAction {
  return {
    type: 'ui.forms/bulk-upload/jobs/LOAD',
    payload: { isLoadingMore },
  };
}

export function jobsLoaded(
  jobs: IBulkUploadJob[],
  pageInfo: PageInfoResponse,
  hasLoadedMore: boolean,
): types.ReduxFormsBulkUploadJobsLoadedAction {
  return {
    type: 'ui.forms/bulk-upload/jobs/LOADED',
    payload: { jobs, pageInfo, hasLoadedMore },
  };
}

export function jobsLoadFailed(): types.ReduxFormsBulkUploadJobsLoadFailedAction {
  return {
    type: 'ui.forms/bulk-upload/jobs/LOAD_FAILED',
    payload: {},
  };
}

export const loadMore = (): types.ReduxFormsBulkUploadJobsLoadMoreAction => ({
  type: 'ui.forms/bulk-upload/jobs/LOAD_MORE',
  payload: {},
});
