/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  RadioGroup,
  RadioGroupEventHandler,
  RadioOption,
} from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import React from 'react';

const createWorkflowOptions = (locale: TenantLocale): RadioOption[] => [
  {
    id: 'Default',
    label: `${locale.label.formAssignedTo} to complete the ${locale.label.form}`,
  },
  {
    id: 'SignOffAssignedTo',
    label: `Sign off on behalf of the ${locale.label.formAssignedTo}`,
  },
];

interface IWorkflowTabProps {
  locale: TenantLocale;
  value?: string;
  onChange?: any;
  onCreateAutomatedActionsChange: (to: boolean) => void;
  createAutomatedActions: boolean;
  includeAutomatedActions: boolean;
}

export const WorkflowTab: React.FC<IWorkflowTabProps> = ({
  locale,
  onChange,
  value,
  onCreateAutomatedActionsChange,
  createAutomatedActions,
  includeAutomatedActions,
}) => {
  const [workflowValue, setWorkflowValue] = React.useState(value);

  const handleRadioChange = React.useCallback<RadioGroupEventHandler>(
    ({ id }) => {
      setWorkflowValue(id);
      return onChange?.({ id });
    },
    [onChange],
  );

  const handleCreateSmartActionChange = React.useCallback(() => {
    if (!includeAutomatedActions) return;
    return onCreateAutomatedActionsChange?.(!createAutomatedActions);
  }, [
    createAutomatedActions,
    includeAutomatedActions,
    onCreateAutomatedActionsChange,
  ]);

  const elCreateSmartAction = includeAutomatedActions && (
    <div
      css={styles.createSmartActions}
      onClick={handleCreateSmartActionChange}
    >
      <div css={styles.createSmartActionsTitle}>
        <Text style={styles.title}>{'Create configured Smart Actions?'}</Text>
        <Icons.info
          fill={color.format(-0.6)}
          tooltip={`Smart Actions are automated remediation actions, based on any issues that have been identified in the review.
            These can be configured as appropriate for your organization. Note that any actions with placeholder text will not be created.`}
        />
      </div>
      <div>
        <Switch
          isChecked={createAutomatedActions}
          isEnabled={true}
          label={createAutomatedActions ? 'Yes' : 'No'}
          labelStyle={{ marginRight: 0 }}
        />
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {elCreateSmartAction}
      <div>
        <Text style={styles.title}>{'Select Workflow'}</Text>
        <div css={styles.radioGroup}>
          <RadioGroup
            value={workflowValue}
            options={createWorkflowOptions(locale)}
            isEnabled={true}
            onChange={handleRadioChange}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginTop: 10,
  }),
  title: css({
    fontSize: 16,
    fontWeight: 'bold',
    width: '100%',
    paddingLeft: 10,
  }),
  radioGroup: css({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
  }),
  createSmartActions: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':hover': { backgroundColor: color.format(-0.1) },
    paddingRight: 10,
  }),
  createSmartActionsTitle: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  }),
};
