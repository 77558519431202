import { Observable, map } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  IFormsDesignerDesignerLoadDraftAction,
  IFormsDesignerDesignerToggleDesigningAction,
} from './types.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';

export function clearFormsDesignerTrackedDataIds(
  action$: Observable<
    | IFormsDesignerDesignerToggleDesigningAction
    | IFormsDesignerDesignerLoadDraftAction
  >,
) {
  return action$.pipe(
    ofType(
      'ui.forms.designer/designer/TOGGLE_DESIGNING',
      'ui.forms.designer/designer/LOAD_DRAFT',
    ),
    map(() => scrollSlice.clearTrackedDataIds()),
  );
}
