import {
  FormDesignerReduxDesignerState,
  IFormsDesignerIntegrationCopyDesignerComponentToTemplate,
} from './types.ts';
import { DesignerOption } from '../../components/DesignerOptions/DesignerOptions.tsx';
import { findLineByInputId } from '../../data/util.ts';
import { FormLineByIdExtended } from '../../components/FormDesignerContent/types.ts';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import {
  IActionPlanLine,
  IFileUploadLine,
  IAttendanceLine,
  ISignoffLine,
  IDigitalContentLine,
  IOptionsAnswerLine,
  ISectionLine,
} from '@seeeverything/ui.forms/src/types/types.ts';

export function copyDesignerComponentToTemplateReducer(
  state: FormDesignerReduxDesignerState,
  action: IFormsDesignerIntegrationCopyDesignerComponentToTemplate,
) {
  if (!state.draft?.designerLines || !state.draft.lines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      lines: copyFromDesigner(
        action.payload.id,
        state.draft.lines,
        state.draft.designerLines,
      ),
    },
  };
}

const updateHeaderSection = (
  id: string,
  headerKey: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
) => ({
  [id]: {
    ...lines[id],
    title: designerLines.headers[headerKey],
    showIndex: Boolean(designerLines.headers[headerKey]),
  } as ISectionLine,
});

const updateHeaderDateInput = (
  id: string,
  headerKey: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
) => {
  const dateline = findLineByInputId(lines, id);

  if (!dateline) {
    return undefined;
  }

  return {
    [dateline.id]: {
      ...lines[dateline.id],
      inputs: dateline.inputs?.map((input) => {
        if (input.id !== id) {
          return input;
        }

        return {
          ...input,
          floatingText: designerLines.headers[headerKey],
        };
      }),
    },
  };
};

const updateHeaderReviewerOrSubject = (
  id: string,
  headerKey: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
  inputId: string,
) => {
  const participantLine = findLineByInputId(lines, inputId);

  if (!participantLine) {
    return undefined;
  }

  return {
    [participantLine.id]: {
      ...lines[participantLine.id],
      inputs: participantLine.inputs?.map((input) => {
        if (input.id !== inputId) {
          return input;
        }

        return {
          ...input,
          floatingText: designerLines.headers[headerKey].label,
          dropdownListName: (
            designerLines.headers[headerKey].kind as string
          ).toUpperCase() as 'PEOPLE' | 'TEAMS',
        };
      }),
    },
  };
};

const updateSection = (
  id: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
) => ({
  [id]: {
    ...lines[id],
    title: designerLines[id].title,
    showIndex: Boolean(designerLines[id].title.value),
  } as ISectionLine,
});

const updateTextField = (
  id: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
) => {
  const designerTextLine = Object.values(designerLines).find(
    (line) => line.id === id,
  );
  const textLine = findLineByInputId(lines, id);

  if (!designerTextLine || !textLine) {
    return undefined;
  }

  return {
    [textLine.id]: {
      ...lines[textLine.id],
      inputs: textLine.inputs?.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          floatingText: designerTextLine.title,
        };
      }),
    },
  };
};

const updateSpeechBlock = (
  id: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
) => ({
  [id]: {
    ...lines[id],
    text: designerLines[id].title,
  },
});

const updateTextBlock = (
  id: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
) => ({
  [id]: {
    ...lines[id],
    label: designerLines[id].title,
  },
});

const updateSwitchAnswer = (
  id: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
) => ({
  [id]: {
    ...lines[id],
    label: designerLines[id].title,
  },
});

const updateOptions = (
  id: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  [id]: {
    ...lines[id],
    question: designerLines[id].optionItems.title,
    options: Object.values(designerLines[id].optionItems.options).map(
      (option: DesignerOption) => ({
        id: option.id,
        label: option.label,
      }),
    ),
  } as IOptionsAnswerLine,
});

const STANDARD_HEADER_MAP: {
  [key: string]: {
    key: string;
    update: (
      id: string,
      headerKey: string,
      lines: FormLineById,
      designerLines: FormLineByIdExtended,
      inputId: string,
    ) => FormLineById;
    inputId?: string;
  };
} = {
  'header-section-title': {
    key: 'sectionTitle',
    update: updateHeaderSection,
  },
  reportingDate: {
    key: 'reportingDateLabel',
    update: updateHeaderDateInput,
  },
  dueDate: {
    key: 'dueDateLabel',
    update: updateHeaderDateInput,
  },
  'assignedTo.label': {
    key: 'reviewer',
    inputId: 'assignedTo',
    update: updateHeaderReviewerOrSubject,
  },
  'subject.label': {
    key: 'subject',
    inputId: 'subject',
    update: updateHeaderReviewerOrSubject,
  },
  'subject.dropdown': {
    key: 'subject',
    inputId: 'subject',
    update: updateHeaderReviewerOrSubject,
  },
};

const updateSignoffTitle = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  signoff: {
    ...lines.signoff,
    title: designerLines.signoff.title,
  } as ISignoffLine,
});

const updateSignoffReviewer = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  signoff: {
    ...lines.signoff,
    reviewerLabel: designerLines.signoff.reviewerLabel,
    reviewerPlaceholderName: designerLines.signoff.reviewerLabel,
  } as ISignoffLine,
});

const updateSignoffSubject = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  signoff: {
    ...lines.signoff,
    subjectLabel: designerLines.signoff.subject.label,
    subjectPlaceholderName: designerLines.signoff.subject.label,
    subjectRequired: designerLines.signoff.subject.isRequired,
  } as ISignoffLine,
});

const updateActionPlanTitle = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  actionPlan: {
    ...lines.actionPlan,
    showIndex: Boolean((lines.actionPlan as IActionPlanLine)?.isEnabled),
    title: (lines.actionPlan as IActionPlanLine)?.isEnabled
      ? designerLines.actionPlan.title ?? 'Action Plan'
      : undefined,
  } as IActionPlanLine,
});

const updateAttendanceIsEnabled = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  attendance: {
    ...lines.attendance,
    isEnabled: designerLines.attendance.isEnabled,
    mandatoryFollowUp: false,
    showIndex: designerLines.attendance.isEnabled,
    title: designerLines.attendance.title ?? 'Attendance',
  } as IAttendanceLine,
});

const updateAttendanceMandatoryFollowUp = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  attendance: {
    ...lines.attendance,
    mandatoryFollowUp: designerLines.attendance.mandatoryFollowUp,
    showIndex: designerLines.attendance.isEnabled,
    title: designerLines.attendance.title ?? 'Attendance',
  } as IAttendanceLine,
});

const updateFileUploadTitle = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  fileUpload: {
    ...lines.fileUpload,
    showIndex: Boolean((lines.fileUpload as IFileUploadLine)?.isEnabled),
    title: (lines.fileUpload as IFileUploadLine)?.isEnabled
      ? designerLines.fileUpload.title ?? 'File Upload'
      : undefined,
  } as IFileUploadLine,
});

const updateFileUploadIsEnabled = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  fileUpload: {
    ...lines.fileUpload,
    isEnabled: designerLines.fileUpload.isEnabled,
    showIndex: designerLines.fileUpload.isEnabled,
    title: designerLines.fileUpload.isEnabled
      ? designerLines.fileUpload.title ?? 'File Upload'
      : undefined,
  } as IFileUploadLine,
});

const updateActionPlanIsEnabled = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  actionPlan: {
    ...lines.actionPlan,
    isEnabled: designerLines.actionPlan.isEnabled,
    showIndex: designerLines.actionPlan.isEnabled,
    title: designerLines.actionPlan.isEnabled
      ? designerLines.actionPlan.title ?? 'Action Plan'
      : undefined,
  } as IActionPlanLine,
});

const updateAttendanceTitle = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  attendance: {
    ...lines.attendance,
    showIndex: (lines.attendance as IAttendanceLine)?.isEnabled,
    title: (lines.attendance as IAttendanceLine)?.isEnabled
      ? designerLines.attendance.title ?? 'Attendance'
      : undefined,
  } as IAttendanceLine,
});

const updateDigitalContentTitle = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  digitalContent: {
    ...lines.digitalContent,
    showIndex: designerLines.digitalContent.isEnabled,
    title: designerLines.digitalContent.isEnabled
      ? designerLines.digitalContent.title ?? 'Digital Content'
      : undefined,
  } as IDigitalContentLine,
});

const updateDigitalContentCommentsGuidance = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  digitalContent: {
    ...lines.digitalContent,
    commentsGuidance: designerLines.digitalContent.commentsGuidance,
    showIndex: designerLines.digitalContent.isEnabled,
    title: designerLines.digitalContent.isEnabled
      ? designerLines.digitalContent.title ?? 'Digital Content'
      : undefined,
  } as IDigitalContentLine,
});

const updateDigitalContentDescription = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  digitalContent: {
    ...lines.digitalContent,
    description: designerLines.digitalContent.description,
    showIndex: designerLines.digitalContent.isEnabled,
    title: designerLines.digitalContent.isEnabled
      ? designerLines.digitalContent.title ?? 'Digital Content'
      : undefined,
  } as IDigitalContentLine,
});

const updateDigitalContentIsEnabled = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  digitalContent: {
    ...lines.digitalContent,
    isEnabled: designerLines.digitalContent.isEnabled,
    showIndex: designerLines.digitalContent.isEnabled,
    title: designerLines.digitalContent.isEnabled
      ? designerLines.digitalContent.title ?? 'Digital Content'
      : undefined,
  } as IDigitalContentLine,
});

const updateDigitalContentCommentsEnabled = (
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => ({
  digitalContent: {
    ...lines.digitalContent,
    isEnabled: designerLines.digitalContent.isEnabled,
    commentsEnabled: designerLines.digitalContent.commentsEnabled,
    commentsGuidance: designerLines.digitalContent.commentsEnabled
      ? designerLines.digitalContent.commentsGuidance
      : '',
    showIndex: designerLines.digitalContent.isEnabled,
    title: designerLines.digitalContent.isEnabled
      ? designerLines.digitalContent.title ?? 'Digital Content'
      : undefined,
  } as IDigitalContentLine,
});

const SIGNOFF_MAP: {
  [key: string]: {
    key: string;
    update: (
      lines: FormLineById,
      designerLines: FormLineByIdExtended,
    ) => FormLineById;
  };
} = {
  'signoff-title': {
    key: 'title',
    update: updateSignoffTitle,
  },
  'signoff-reviewer.label': {
    key: 'reviewerLabel',
    update: updateSignoffReviewer,
  },
  'signoff-subject.label': {
    key: 'subject',
    update: updateSignoffSubject,
  },
  'signoff-subject.checkbox': {
    key: 'subject',
    update: updateSignoffSubject,
  },
};

const ACTION_PLAN_MAP: {
  [key: string]: {
    key: string;
    update: (
      lines: FormLineById,
      designerLines: FormLineByIdExtended,
    ) => FormLineById;
  };
} = {
  'action-plan-title': {
    key: 'title',
    update: updateActionPlanTitle,
  },
  'action-plan-enabled': {
    key: 'reviewerLabel',
    update: updateActionPlanIsEnabled,
  },
};

const FILE_UPLOAD_MAP: {
  [key: string]: {
    key: string;
    update: (
      lines: FormLineById,
      designerLines: FormLineByIdExtended,
    ) => FormLineById;
  };
} = {
  'file-upload-title': {
    key: 'title',
    update: updateFileUploadTitle,
  },
  'file-upload-enabled': {
    key: 'reviewerLabel',
    update: updateFileUploadIsEnabled,
  },
};

const COMPONENT_TYPE_MAP: {
  [key: string]: (
    id: string,
    lines: FormLineById,
    designerLines: FormLineByIdExtended,
  ) => FormLineById;
} = {
  textField: updateTextField,
  section: updateSection,
  options: updateOptions,
  speechBlock: updateSpeechBlock,
  textBlock: updateTextBlock,
  switchAnswer: updateSwitchAnswer,
};

const ATTENDANCE_MAP: {
  [key: string]: {
    key: string;
    update: (
      lines: FormLineById,
      designerLines: FormLineByIdExtended,
    ) => FormLineById;
  };
} = {
  'attendance-title': {
    key: 'title',
    update: updateAttendanceTitle,
  },
  'attendance-enabled': {
    key: 'isEnabled',
    update: updateAttendanceIsEnabled,
  },
  'attendance-mandatoryFollowUp': {
    key: 'mandatoryFollowUp',
    update: updateAttendanceMandatoryFollowUp,
  },
};

const DIGITAL_CONTENT_MAP: {
  [key: string]: {
    key: string;
    update: (
      lines: FormLineById,
      designerLines: FormLineByIdExtended,
    ) => FormLineById;
  };
} = {
  'digital-content-comments-enabled': {
    key: 'comments-enabled',
    update: updateDigitalContentCommentsEnabled,
  },
  'digital-content-comments-guidance': {
    key: 'comments-guidance',
    update: updateDigitalContentCommentsGuidance,
  },
  'digital-content-description': {
    key: 'description',
    update: updateDigitalContentDescription,
  },
  'digital-content-enabled': {
    key: 'isEnabled',
    update: updateDigitalContentIsEnabled,
  },
  'digital-content-title': {
    key: 'title',
    update: updateDigitalContentTitle,
  },
};

const copyFromDesigner = (
  id: string,
  lines: FormLineById,
  designerLines: FormLineByIdExtended,
): FormLineById => {
  // Special values - used in the designer standard heading.
  const headerValue = STANDARD_HEADER_MAP[id];
  if (headerValue) {
    return {
      ...lines,
      ...headerValue.update(
        id,
        headerValue.key,
        lines,
        designerLines,
        headerValue.inputId,
      ),
    };
  }

  const signoffValue = SIGNOFF_MAP[id];
  if (signoffValue) {
    return {
      ...lines,
      ...signoffValue.update(lines, designerLines),
    };
  }

  const actionPlanValue = ACTION_PLAN_MAP[id];
  if (actionPlanValue) {
    return {
      ...lines,
      ...actionPlanValue.update(lines, designerLines),
    };
  }

  const fileUploadValue = FILE_UPLOAD_MAP[id];
  if (fileUploadValue) {
    return {
      ...lines,
      ...fileUploadValue.update(lines, designerLines),
    };
  }

  const attendanceValue = ATTENDANCE_MAP[id];
  if (attendanceValue) {
    return {
      ...lines,
      ...attendanceValue.update(lines, designerLines),
    };
  }

  const digitalContentValue = DIGITAL_CONTENT_MAP[id];
  if (digitalContentValue) {
    return {
      ...lines,
      ...digitalContentValue.update(lines, designerLines),
    };
  }

  const designerLine = Object.values(designerLines).find(
    (line) => line.id === id,
  );

  if (!designerLine) {
    return lines;
  }

  const componentUpdate = COMPONENT_TYPE_MAP[designerLine.type];

  return {
    ...lines,
    ...componentUpdate(id, lines, designerLines),
  };
};
