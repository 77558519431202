import * as types from './types.ts';
import { IDesignerEditableDraft } from '../../model/types.ts';
import { DesignerComponentKind } from '../../components/types.ts';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { IFormsDesignerDesignerValidateDefinitionSuccessAction } from './types.ts';

export function load(id: string): types.IFormsDesignerDesignerLoadDraftAction {
  return {
    type: 'ui.forms.designer/designer/LOAD_DRAFT',
    payload: { id },
  };
}

export function loaded(
  draft: IDesignerEditableDraft,
): types.IFormsDesignerDesignerLoadedDraftAction {
  return {
    type: 'ui.forms.designer/designer/LOADED_DRAFT',
    payload: draft,
  };
}

export function loadFailed(): types.IFormsDesignerDesignerLoadDraftFailedAction {
  return {
    type: 'ui.forms.designer/designer/LOAD_DRAFT_FAILED',
    payload: {},
  };
}

export function editDefinition(
  id: string,
): types.IFormsDesignerDesignerEditDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/EDIT_DEFINITION',
    payload: { id },
  };
}

export function saveDefinition(
  id: string,
  source: 'save' | 'publish',
): types.IFormsDesignerDesignerSaveDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/SAVE_DEFINITION',
    payload: { id, source },
  };
}

export function savedDefinition(
  id: string,
  definition: any[],
  source: string,
): types.IFormsDesignerDesignerSavedDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/SAVED_DEFINITION',
    payload: { id, definition, source },
  };
}

export function saveDefinitionFailed(): types.IFormsDesignerDesignerSaveDefinitionFailedAction {
  return {
    type: 'ui.forms.designer/designer/SAVE_DEFINITION_FAILED',
    payload: {},
  };
}

export function validateDefinition(
  id: string,
  definition: any[],
): types.IFormsDesignerDesignerValidateDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/VALIDATE_DEFINITION',
    payload: { id, definition },
  };
}

export function validateDefinitionSuccess(
  id: string,
): IFormsDesignerDesignerValidateDefinitionSuccessAction {
  return {
    type: 'ui.forms.designer/designer/VALIDATE_DEFINITION_SUCCESS',
    payload: { id },
  };
}

export function validateDefinitionFailed(
  id: string,
  designerLines: FormLineById,
): types.IFormsDesignerDesignerValidateDefinitionFailedAction {
  return {
    type: 'ui.forms.designer/designer/VALIDATE_DEFINITION_FAILED',
    payload: { id, designerLines },
  };
}

export function toggleDesigning(): types.IFormsDesignerDesignerToggleDesigningAction {
  return {
    type: 'ui.forms.designer/designer/TOGGLE_DESIGNING',
    payload: {},
  };
}

/**
 * DESIGNER COMPONENTS
 */
export function changeComponentProperty(
  id: string,
  to = '',
): types.IFormsDesignerDesignerChangeComponentProperty {
  return {
    type: 'ui.forms.designer/designer/component/CHANGE_COMPONENT_PROPERTY',
    payload: { id, to },
  };
}

export function deleteComponent(
  id: string,
): types.IFormsDesignerDesignerDeleteComponent {
  return {
    type: 'ui.forms.designer/designer/component/DELETE_COMPONENT',
    payload: { id },
  };
}

export function addComponent(
  id: string,
  kind: DesignerComponentKind,
  insertBeforeId?: string | number,
): types.IFormsDesignerDesignerAddComponent {
  return {
    type: 'ui.forms.designer/designer/component/ADD_COMPONENT',
    payload: { id, kind, insertBeforeId },
  };
}

export function optionsChangeOptionText(
  id: string,
  optionId: string,
  to: string,
): types.IFormsDesignerDesignerOptionsChangeOptionText {
  return {
    type: 'ui.forms.designer/designer/component/OPTIONS/CHANGE_TEXT',
    payload: { id, optionId, to },
  };
}

export function optionsAddNewOption(
  id: string,
  optionId: string,
): types.IFormsDesignerDesignerOptionsAddNewOption {
  return {
    type: 'ui.forms.designer/designer/component/OPTIONS/ADD_OPTION',
    payload: { id, optionId },
  };
}

export function optionsDeleteExistingOption(
  id: string,
  optionId: string,
): types.IFormsDesignerDesignerOptionsDeleteExistingOption {
  return {
    type: 'ui.forms.designer/designer/component/OPTIONS/DELETE_OPTION',
    payload: { id, optionId },
  };
}

export function designerLinesChanged(
  lines: FormLineById,
): types.IFormsDesignerDesignerLinesChanged {
  return {
    type: 'ui.forms.designer/designer/LINES_CHANGED',
    payload: { lines },
  };
}

/**
 * DESIGNER <-> TEMPLATE INTEGRATION
 */
export function copyComponentFromDesignerToTemplate(
  id: string,
): types.IFormsDesignerIntegrationCopyDesignerComponentToTemplate {
  return {
    type: 'ui.forms.designer/integration/COPY_DESIGNER_COMPONENT_TO_TEMPLATE',
    payload: { id },
  };
}

export function deleteComponentFromTemplate(
  id: string,
): types.IFormsDesignerIntegrationDeleteComponentFromTemplate {
  return {
    type: 'ui.forms.designer/integration/DELETE_COMPONENT_FROM_TEMPLATE',
    payload: { id },
  };
}

export function addComponentToTemplate(
  id: string,
  type: DesignerComponentKind,
  insertBeforeId?: string | number,
): types.IFormsDesignerIntegrationAddComponentToTemplate {
  return {
    type: 'ui.forms.designer/integration/ADD_COMPONENT_TO_TEMPLATE',
    payload: { id, type, insertBeforeId },
  };
}
