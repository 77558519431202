/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryFormTemplatesByCategory } from '@se/data/forms/hooks/useQueryFormTemplatesByCategory.ts';
import { ExportableQuestion } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/download-template/types.ts';
import { ErrorRetry } from '@seeeverything/ui.primitives/src/components/ErrorRetry/index.ts';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { QuestionsSelection } from './components/QuestionsSelection.tsx';
import { QuestionsSelectionSkeleton } from './components/QuestionsSelectionSkeleton.tsx';
import { SwitchHiddenFields } from './components/SwitchHiddenFields.tsx';
import {
  QuestionSelectionEvent,
  SwitchHiddenFieldsChangeEventHandler,
} from './types.ts';

export interface IDownloadFormTemplateDialogProps {
  exportableQuestions: ExportableQuestion[];
  formLabel: string;
  isError: boolean;
  isTemplateLoading: boolean;
  onQuestionSelected: (e: QuestionSelectionEvent) => void;
  onRetryLoadTemplate?: () => void;
  onSectionHeadingSelected: (e: QuestionSelectionEvent) => void;
  onSelectedTemplateChange?: (template: ISelectionListItem) => void;
  onShowHiddenFieldsSwitch?: SwitchHiddenFieldsChangeEventHandler;
  selectedTemplate?: ISelectionListItem;
}

/**
 * Dialog content for downloading a form template for form upload.
 */
export const DownloadFormTemplateDialog: React.FC<
  IDownloadFormTemplateDialogProps
> = ({
  exportableQuestions,
  formLabel,
  isError,
  isTemplateLoading,
  onQuestionSelected,
  onRetryLoadTemplate,
  onSectionHeadingSelected,
  onSelectedTemplateChange,
  onShowHiddenFieldsSwitch,
  selectedTemplate,
}) => {
  const { templateSelections, templatesLoading } =
    useQueryFormTemplatesByCategory({ createInstanceOnly: true });

  const elTemplateLoadError = isError && (
    <ErrorRetry
      message={
        'Something went wrong while trying to retrieve this template. Click here to retry.'
      }
      onClick={onRetryLoadTemplate}
    />
  );

  const elShowHiddenFieldsSwitch = selectedTemplate && !isError && (
    <div css={styles.showHiddenFieldsSwitch}>
      <SwitchHiddenFields
        id={'downloadFormUploadTemplate-showHiddenFieldsSwitch'}
        onChange={onShowHiddenFieldsSwitch}
      />
    </div>
  );

  const elSelectExportQuestions = selectedTemplate && !isError && (
    <div>
      <IconWrapper
        icon={'list'}
        contentStyle={styles.fieldsContent}
        iconStyle={styles.fieldsIcon}
      >
        <Text color={color.format(-0.8)} size={15}>
          {'Select Fields to Export:'}
        </Text>
        {isTemplateLoading && <QuestionsSelectionSkeleton />}
        {!isTemplateLoading && (
          <QuestionsSelection
            onQuestionSelected={onQuestionSelected}
            onSectionHeadingSelected={onSectionHeadingSelected}
            exportableQuestions={exportableQuestions}
          />
        )}
      </IconWrapper>
      <Text color={color.format(-0.8)} size={12} italic={true}>
        {`* Fields in italics are hidden in ${str.plural(
          formLabel.toLowerCase(),
        )} by default, only becoming visible when questions are answered a certain way in the ${formLabel.toLowerCase()}.`}
      </Text>
    </div>
  );

  const elTemplateSelection = (
    <div css={styles.templateSelectRow}>
      <IconWrapper icon={'document'} style={styles.templateDropdown}>
        <TextFieldDropdown
          id={'template'}
          selections={templateSelections}
          isLoading={templatesLoading}
          label={'Select Template'}
          value={selectedTemplate}
          onChange={onSelectedTemplateChange}
        />
      </IconWrapper>
    </div>
  );

  const elHeading = (
    <div css={styles.header}>
      <div css={styles.title}>
        <Text size={48} color={color.format(-0.2)} weight={FontWeight.light}>
          {'Download Template'}
        </Text>
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {elHeading}
      <div css={styles.body}>
        {elTemplateSelection}
        {elShowHiddenFieldsSwitch}
        {elSelectExportQuestions}
        {elTemplateLoadError}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    overflow: 'hidden',
    width: '100%',
    flex: '1 1 auto',
  }),
  header: css({
    height: 96,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  }),
  title: css({
    flex: '1 1 auto',
    margin: '20px 0 0 34px',
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    padding: '0 33px 33px 33px',
  }),
  templateDropdown: css({
    width: '100%',
  }),
  templateSelectRow: css({
    display: 'flex',
    marginBottom: 15,
  }),
  fieldsContent: css({
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
  }),
  fieldsIcon: css({
    marginTop: 0,
  }),
  showHiddenFieldsSwitch: css({
    margin: '0 0 10px 0',
  }),
};
