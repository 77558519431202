/** @jsxImportSource @emotion/react */
import {
  ReduxFormsBulkUploadFailedClickThroughClicked,
  ReduxFormsBulkUploadSucceededClickThroughClicked,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/click-through/types.ts';
import { clearDownloadTemplate } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/download-template/actions.ts';
import {
  BulkUploadFormTemplateExported,
  ExportableQuestion,
  ReduxFormBulkUploadDownloadTemplateExportCancel,
  ReduxFormBulkUploadDownloadTemplateExportClick,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/download-template/types.ts';
import { formsBulkUploadFailedRowsRequestDownloadLink } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/failed-rows-download/actions.ts';
import {
  ReduxFormsBulkUploadFailedRowsDownloadFailed,
  ReduxFormsBulkUploadFailedRowsRequestDownloadLink,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/failed-rows-download/types.ts';
import {
  bulkUploadDialogTabChanged,
  clearImportFileValidation,
  fileErrorUploading,
  fileUploaded,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/actions.ts';
import {
  ReduxFormsBulkUploadCancelDuplicateRowImport,
  ReduxFormsBulkUploadDuplicateImportDialogShow,
  ReduxFormsBulkUploadFileImport,
  ReduxFormsBulkUploadFileUpload,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/types.ts';
import {
  jobsLoaded,
  jobsLoadFailed,
  loadJobs,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/job-history/actions.ts';
import {
  ReduxFormsBulkUploadJobsLoadAction,
  ReduxFormsBulkUploadJobsLoadMoreAction,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/job-history/types.ts';
import { ReduxFormsBulkSelectWorkflow } from '@seeeverything/ui.forms/src/redux/form-bulk-upload/select-workflow/types.ts';
import { DROPDOWN_RESERVED_IDS } from '@seeeverything/ui.forms/src/util/constants.ts';
import { BulkUploadDuplicateRowWarningDialogContainer } from '@seeeverything/ui.shell/src/components/BulkUploadDuplicateRowWarningDialog/BulkUploadDuplicateRowWarningDialogContainer.tsx';
import { BulkUploadFileImportDialogContainer } from '@seeeverything/ui.shell/src/components/BulkUploadFileImportDialog/BulkUploadFileImportDialogContainer.tsx';
import { DownloadFormTemplateDialogContainer } from '@seeeverything/ui.shell/src/components/DownloadFormTemplateDialog/index.ts';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { ISheetToolbarClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import moment from 'moment';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { concatAll, filter, from, map, mergeMap, Observable } from 'rxjs';
import sanitize from 'sanitize-filename';
import { utils, writeFile } from 'xlsx';
import { validate } from '../../../data/forms-bulk-upload/import-file-validation.ts';
import { importFile } from '../../../data/forms-bulk-upload/import-file.ts';
import { getBulkUploadJobs } from '../../../data/forms-bulk-upload/jobs.ts';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';
import { addChipAction } from '../../config.sheets/index.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  bulkUploadDownloadFailuresForJobEpic,
  bulkUploadDownloadFormTemplateEpic,
  bulkUploadExportFormTemplateEpic,
  bulkUploadShowDuplicateRowDialogEpic,
  bulkUploadShowImportFileEpic,
  refreshJobsListEpic,
  downloadTemplateCancelledEpic,
  importBulkUploadFileEpic,
  jobHistoryLoadMoreEpic,
  loadBulkUploadJobHistory,
  loadClickThroughForJobEpic,
  showStatusOnFormBulkFailuresDownloadFailed,
  showStatusOnFormBulkFailuresDownloadRequests,
  showStatusBarOnBulkUploadImportEpic,
  changeDialogTabOnFileUploaded,
  changeDialogTabToReviewOnWorkflowSelected,
  validateImportFileOnSelectWorkflowEpic,
);

const WORKSHEET_NAME_CHAR_LIMIT = 31;

function showStatusOnFormBulkFailuresDownloadRequests(
  action$: Observable<ReduxFormsBulkUploadFailedRowsRequestDownloadLink>,
) {
  return action$.pipe(
    ofType(
      'ui.forms/bulk-upload/jobs/failed-rows/download/REQUEST_DOWNLOAD_LINK',
    ),
    map(() =>
      showStatusBar(
        'INFO',
        `A .CSV file containing the failures is being generated for you. Once ready, it will automatically be downloaded by your browser.`,
        5000,
      ),
    ),
  );
}

function showStatusOnFormBulkFailuresDownloadFailed(
  action$: Observable<ReduxFormsBulkUploadFailedRowsDownloadFailed>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/jobs/failed-rows/download/DOWNLOAD_FAILED'),
    map(() =>
      showStatusBar(
        'ERROR',
        `Something went wrong when trying to download the failures for the job. Please try again.`,
        5000,
      ),
    ),
  );
}

/**
 * Epic to show status bar when bulk uploader imports files.
 */
function showStatusBarOnBulkUploadImportEpic(
  action$: Observable<ReduxFormsBulkUploadFileImport>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/import-file-validation/IMPORT'),
    map(() => {
      const locale = state$.value.tenantState.tenant?.locale;
      const forms = str.plural(locale.label.form).toLowerCase();

      return showStatusBar(
        'INFO',
        `Upload will start now. Refresh to see latest status. Note this may take several minutes before all the created ${forms} are visible in dashboards.`,
      );
    }),
  );
}

/**
 * Epic to load bulk upload job history.
 */
function loadBulkUploadJobHistory(
  action$: Observable<ReduxFormsBulkUploadJobsLoadAction>,
  state$: StateObservable<GlobalAppState>,
  { client }: GlobalAppEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/jobs/LOAD'),
    mergeMap(async () => {
      const jobHistory = state$.value.formBulkUpload.jobHistory;

      const pageNumber = jobHistory.isLoadingMore
        ? jobHistory.currentPage + 1
        : 1;

      const jobs = await getBulkUploadJobs(client, {
        jobType: 'Create',
        pagination: {
          pageNumber,
          pageSize: 50,
        },
        hasNextPage: jobHistory.hasNextPage,
        isLoadingMore: jobHistory.isLoadingMore,
      });

      if (!jobs) return jobsLoadFailed();

      return jobsLoaded(jobs.nodes, jobs.pageInfo, jobHistory.isLoadingMore);
    }),
  );
}

function jobHistoryLoadMoreEpic(
  action$: Observable<ReduxFormsBulkUploadJobsLoadMoreAction>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/jobs/LOAD_MORE'),
    map(() => loadJobs(true)),
  );
}

function loadClickThroughForJobEpic(
  action$: Observable<
    | ReduxFormsBulkUploadSucceededClickThroughClicked
    | ReduxFormsBulkUploadFailedClickThroughClicked
  >,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType(
      'ui.forms/bulk-upload/click-through/SUCCEEDED_CLICKED',
      'ui.forms/bulk-upload/click-through/FAILED_CLICKED',
    ),
    map((action) => {
      const locale = state$.value.tenantState.tenant.locale;
      const jobs = state$.value.formBulkUpload.jobHistory.jobs;
      const job = jobs.find(({ id }) => id === action.payload.jobId);

      const isSucceededClickThrough =
        action.type === 'ui.forms/bulk-upload/click-through/SUCCEEDED_CLICKED';

      const jobDate = moment(job.uploadedAt).format('D MMM YY, h:mm a');
      const chipLabel = `${jobDate} - ${
        isSucceededClickThrough ? 'Created' : 'Failed'
      } ${str.plural(locale.label.form)}`;

      return addChipAction({
        type: isSucceededClickThrough
          ? 'bulkUploadSucceeded'
          : 'bulkUploadFailed',
        label: chipLabel,
        id: action.payload.jobId,
      });
    }),
  );
}

function bulkUploadDownloadFailuresForJobEpic(
  action$: Observable<ISheetToolbarClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter(
      (action) => action.payload.toolId === 'DOWNLOAD_BULK_UPLOAD_JOB_FAILURES',
    ),
    map(() => {
      const jobId = state$.value.formBulkUpload.clickThrough?.jobId;
      return formsBulkUploadFailedRowsRequestDownloadLink(jobId);
    }),
  );
}

function bulkUploadDownloadFormTemplateEpic(
  action$: Observable<ISheetToolbarClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'DOWNLOAD_FORM_TEMPLATE'),
    map(() =>
      from([
        showModalDialog({
          content: <DownloadFormTemplateDialogContainer />,
          width: 830,
        }),
        clearDownloadTemplate(),
      ]),
    ),
    concatAll(),
  );
}

function refreshJobsListEpic(action$: Observable<ISheetToolbarClickAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'IMPORT_BULK_UPLOAD_REFRESH'),
    map(() => loadJobs()),
  );
}

function downloadTemplateCancelledEpic(
  action$: Observable<ReduxFormBulkUploadDownloadTemplateExportCancel>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/download-template/EXPORT_CANCEL'),
    map(hideModalDialog),
  );
}

function bulkUploadExportFormTemplateEpic(
  action$: Observable<ReduxFormBulkUploadDownloadTemplateExportClick>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/download-template/EXPORT_CLICK'),
    map((action) => {
      const { selectedQuestions, template } = action.payload;
      const locale = state$.value.tenantState.tenant.locale;

      const workbook = utils.book_new();
      workbook.Props = {
        Title: `${template.name} Template Export`,
        Subject: 'Template Export',
        Author: 'SeeEverything',
      };

      const templateWorksheetName = toWorksheetName(template.name);
      workbook.SheetNames.push(templateWorksheetName);
      workbook.Sheets[templateWorksheetName] = utils.aoa_to_sheet(
        generateImportWorksheetData(
          selectedQuestions,
          template,
          locale.label.form,
        ),
      );

      workbook.SheetNames.push('Guidance');
      workbook.Sheets['Guidance'] = utils.aoa_to_sheet(
        generateGuidanceWorksheetData(selectedQuestions, template, locale),
      );

      writeFile(
        workbook,
        `export-${toFileName(template.category)}-${toFileName(
          template.name,
        )}.xlsx`,
      );

      return from([
        hideModalDialog(),
        showStatusBar(
          'INFO',
          `The template file for ${template.name} will be automatically downloaded by your browser.`,
        ),
      ]);
    }),
    concatAll(),
  );
}

const toWorksheetName = (templateName: string) =>
  templateName.substring(0, WORKSHEET_NAME_CHAR_LIMIT);

/**
 * Epic to show bulk upload duplicate row warning dialog.
 */
function bulkUploadShowDuplicateRowDialogEpic(
  action$: Observable<ReduxFormsBulkUploadDuplicateImportDialogShow>,
) {
  return action$.pipe(
    ofType(
      'ui.forms/bulk-upload/import-file-validation/CONFIRM_DUPLICATE_IMPORT',
    ),
    map(() =>
      showModalDialog({
        content: <BulkUploadDuplicateRowWarningDialogContainer />,
        width: 300,
      }),
    ),
  );
}

/**
 * Epic to show bulk upload import file dialog.
 */
function bulkUploadShowImportFileEpic(
  action$: Observable<
    ISheetToolbarClickAction | ReduxFormsBulkUploadCancelDuplicateRowImport
  >,
) {
  return action$.pipe(
    ofType(
      'ui.shell/sheets/TOOLBAR_CLICK',
      'ui.forms/bulk-upload/import-file-validation/CANCEL_DUPLICATE_IMPORT',
    ),
    filter((action) => action.payload.toolId === 'IMPORT_BULK_UPLOAD_FILE'),
    map(() =>
      from([
        showModalDialog({
          content: <BulkUploadFileImportDialogContainer />,
          width: 1000,
        }),
        clearImportFileValidation(),
      ]),
    ),
    concatAll(),
  );
}

/**
 * Epic to change a bulk upload dialog tab after file is uploaded.
 */
function changeDialogTabOnFileUploaded(
  action$: Observable<ReduxFormsBulkUploadFileUpload>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/import-file-validation/FILE_UPLOAD'),
    map(() => bulkUploadDialogTabChanged('WORKFLOW')),
  );
}

/**
 * Epic to change a bulk upload dialog tab after workflow is selected.
 */
function changeDialogTabToReviewOnWorkflowSelected(
  action$: Observable<ReduxFormsBulkSelectWorkflow>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/select-workflow/SELECT_WORKFLOW_OPTION'),
    map(() => bulkUploadDialogTabChanged('REVIEW')),
  );
}

/**
 * Epic to import bulk upload file.
 */
function importBulkUploadFileEpic(
  action$: Observable<ReduxFormsBulkUploadFileImport>,
  state$: StateObservable<GlobalAppState>,
  { uploadClient }: GlobalAppEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/import-file-validation/IMPORT'),
    mergeMap(
      async ({ payload: { file, workflowType, createAutomatedActions } }) => {
        const includeAutomatedActions =
          state$.value.tenantState.tenant.module === 'compliance' &&
          createAutomatedActions;

        await importFile(uploadClient, {
          file,
          workflowType,
          createAutomatedActions: includeAutomatedActions,
        });

        return from([hideModalDialog(), loadJobs()]);
      },
    ),
    concatAll(),
  );
}

/**
 * Epic to validate bulk upload file on select workflow.
 */
function validateImportFileOnSelectWorkflowEpic(
  action$: Observable<ReduxFormsBulkSelectWorkflow>,
  state$: StateObservable<GlobalAppState>,
  { uploadClient }: GlobalAppEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/select-workflow/SELECT_WORKFLOW_OPTION'),
    mergeMap(async ({ payload: { workflowType } }) => {
      const includeAutomatedActions =
        state$.value.tenantState.tenant.module === 'compliance' &&
        state$.value.formBulkUpload.workflow.createAutomatedActions;

      const validationResult = await validate(uploadClient, {
        file: state$.value.formBulkUpload.importFileValidation.validatedFile,
        jobType: 'Create',
        workflowType,
        createAutomatedActions: includeAutomatedActions,
      });

      return validationResult
        ? fileUploaded(validationResult)
        : fileErrorUploading('Error uploading file');
    }),
  );
}

/**
 * Generates worksheet data in from an array of arrays where each array is a row and
 * every string in the array is a column e.g.
 * [
 *    [col1, col2, col3],
 *    [col1, col2, col3],
 *    [col1, col2, col3],
 * ]
 */
const generateImportWorksheetData = (
  selectedQuestions: ExportableQuestion[],
  template: BulkUploadFormTemplateExported,
  formLabel: string,
) => {
  const exportableKeys = selectedQuestions
    .filter((key) => !key.isHeading)
    .map(getDisplayKey);

  return [
    ['Category', formLabel, ...exportableKeys],
    [template.category, template.name],
  ];
};

const generateGuidanceWorksheetData = (
  selectedExportQuestions: ExportableQuestion[],
  template: BulkUploadFormTemplateExported,
  locale: TenantLocale,
) => {
  const keyAndOptionsRow = selectedExportQuestions
    .filter((key) => !key.isHeading)
    .map((key) => [getDisplayKey(key), ...key.answerOptions]);

  const formLabel = locale.label.form;

  const templateFields = [
    ['Category', template.category],
    [formLabel, template.name],
  ];

  const guidanceGrid = transposeData([...templateFields, ...keyAndOptionsRow]);

  const guidanceText =
    'Data in this tab will not be imported. The below is provided as a guide for filling in the first tab.';
  const guidanceText2 = `<Category> and <${formLabel}> need to be repeated for every row. ${locale.label.formAssignedTo} is optional. 
    <Multiselect> are | separated, eg Savings|Loan. 
    <Date> accepts format d-mmm-yy, eg 1-May-21. 
    <Number> contains no decimal places.`;

  const includeAction = selectedExportQuestions.some(
    (key) => key.id === 'Action',
  );

  const guidanceText3 = includeAction
    ? [
        `To create an action against the ${formLabel.toLowerCase()}, add an Action Description and optionally an Action Due Date. If no due date entered, the default will be used.`,
      ]
    : undefined;

  return [
    [guidanceText],
    [guidanceText2],
    guidanceText3,
    [''],
    ...guidanceGrid,
  ].filter(Boolean);
};

const getDisplayKey = (key: ExportableQuestion) =>
  DROPDOWN_RESERVED_IDS.includes(key.id) ? key.label : key.id;

const transposeData = (grid: string[][]) =>
  grid
    .reduce(
      (longestRow, currentRow) =>
        longestRow.length > currentRow.length ? longestRow : currentRow,
      [],
    )
    .map((_, colIndex) => grid.map((row) => row[colIndex]));

/**
 * Sanitize a string for a file name, convert to lower case and replace spaces with a "-".
 */
const toFileName = (input = ''): string =>
  sanitize(input.replace(/\s+/g, '-').toLowerCase());
