import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/index.ts';
import { IDashboardGridTools, IDashboardToolButton } from '../types.ts';

/**
 * Attempts to parse the tools for a grid.
 */
export function parser(
  item: any,
  index: number,
): IDashboardGridTools | undefined {
  if (!item['grid-tools']) {
    return;
  }
  const parentId = item['grid-tools'].id;
  const buttons = item['grid-tools'].tools.map(
    (tool: any): IDashboardToolButton => {
      return tool['is-fullscreen-tool'] !== true
        ? {
            id: tool.id,
            parentId,
            toolId: tool['grid-id'],
            icon: tool.icon && Icons[tool.icon],
            label: tool.label,
            selectionGroup: tool.group,
            isSelected: tool['is-default'] === true,
            fallbackToolId: tool[`fallback-grid-tool-id`],
          }
        : {
            id: 'OPEN_GRID',
            dataTest: 'dashboards-grid-fullScreenToolButton',
            icon: Icons.launch,
            label: tool.label,
            parentId,
            context: { parentId },
          };
    },
  );

  return {
    componentType: 'GRID_TOOLS',
    id: item['grid-tools'].id,
    toolButtons: buttons,
    index,
    dataState: {
      isLoaded: false,
      isLoading: false,
      forceReload: false,
      dataSetId: '',
    },
    group: item['grid-tools'].group,
  };
}
