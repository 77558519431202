/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { DesignerStandardHeading } from '../DesignerStandardHeading/DesignerStandardHeading.tsx';
import { DesignerTextField } from '../DesignerTextField/DesignerTextField.tsx';
import { DesignerDatePicker } from '../DesignerDatePicker/DesignerDatePicker.tsx';
import { AddComponentList } from '../AddComponentList/AddComponentList.tsx';
import { IDesignerCommonProps } from '../types.ts';
import { DesignerSignoff } from '../DesignerSignoff/DesignerSignoff.tsx';
import { DesignerOptions } from '../DesignerOptions/DesignerOptions.tsx';
import { DesignerSpeechBlock } from '../DesignerSpeechBlock/DesignerSpeechBlock.tsx';
import { DesignerTextBlock } from '../DesignerTextBlock/DesignerTextBlock.tsx';
import { DesignerSwitch } from '../DesignerSwitch/DesignerSwitch.tsx';
import { DesignerActionPlan } from '../DesignerActionPlan/DesignerActionPlan.tsx';
import { DesignerFileUpload } from '../DesignerFileUpload/DesignerFileUpload.tsx';
import { DesignerAttendance } from '../DesignerAttendance/DesignerAttendance.tsx';
import { Form } from '@seeeverything/ui.forms/src/components/Form/Form.tsx';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { FormLineByIdExtended } from './types.ts';
import { DesignerDigitalContent } from '../DesignerDigitalContent/DesignerDigitalContent.tsx';
import { REQUIRED_FIELD_MESSAGE } from '../../common/constants.ts';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { FormDesignerContentItemContainer } from './components/FormDesignerContentItemContainer.tsx';

export interface IFormDesignerContentProps {
  isDesigning?: boolean;
  designerLines?: FormLineByIdExtended;
  lines?: FormLineById;
  designerOptions?: IDesignerFactoryOptions;
}

export interface IDesignerFactoryOptions extends Partial<IDesignerCommonProps> {
  dropdownTypes?: ISelectionListItem[];
}

/**
 * Form designer rendered form content.
 */
export const FormDesignerContent: React.FC<IFormDesignerContentProps> = ({
  designerLines = {},
  designerOptions = {},
  isDesigning = false,
  lines = {},
}) => {
  if (!isDesigning)
    return (
      <Form lines={lines} isFormsDesigner={true} canUpdateInstance={true} />
    );

  return (
    <>
      {Object.values(designerLines).map((line) => {
        if (line.type === 'section')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              style={styles.topSpace}
            >
              <DesignerSection
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                error={
                  line.validationError?.hasValidationError
                    ? REQUIRED_FIELD_MESSAGE
                    : undefined
                }
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'standardHeaders')
          return (
            <FormDesignerContentItemContainer
              key={'header-section-title'}
              dataId={'header-section-title'}
              style={styles.topSpace}
            >
              <DesignerStandardHeading
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                dropdownTypes={designerOptions.dropdownTypes}
                validationError={line.validationError}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'textField')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              parentDataId={line.parentId}
              style={styles.leftSpace}
            >
              <DesignerTextField
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'datePicker')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              parentDataId={line.parentId}
              style={styles.leftSpace}
            >
              <DesignerDatePicker
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'options')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              parentDataId={line.parentId}
              style={styles.leftSpace}
            >
              <DesignerOptions
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'speechBlock')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              parentDataId={line.parentId}
              style={styles.leftSpace}
            >
              <DesignerSpeechBlock
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'textBlock')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              parentDataId={line.parentId}
              style={styles.leftSpace}
            >
              <DesignerTextBlock
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={false}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'switchAnswer')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              parentDataId={line.parentId}
              style={styles.leftSpace}
            >
              <DesignerSwitch
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={designerOptions.canDelete}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'add')
          return (
            <FormDesignerContentItemContainer
              key={line.id}
              dataId={line.id}
              style={styles.add}
            >
              <AddComponentList
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'actionPlan')
          return (
            <FormDesignerContentItemContainer
              key={'actionPlan'}
              dataId={'actionPlan'}
              style={styles.bottomSpace}
            >
              <DesignerActionPlan
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={false}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'fileUpload')
          return (
            <FormDesignerContentItemContainer
              key={'fileUpload'}
              dataId={'fileUpload'}
              style={styles.bottomSpace}
            >
              <DesignerFileUpload
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={false}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'signoff')
          return (
            <FormDesignerContentItemContainer
              key={'signoff'}
              dataId={'signoff'}
              style={styles.bottomSpace}
            >
              <DesignerSignoff
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={false}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'attendance')
          return (
            <FormDesignerContentItemContainer
              key={'attendance'}
              dataId={'attendance'}
              style={styles.bottomSpace}
            >
              <DesignerAttendance
                isEnabled={true}
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                style={designerOptions.style}
                canDelete={false}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                {...line}
              />
            </FormDesignerContentItemContainer>
          );

        if (line.type === 'digitalContent')
          return (
            <FormDesignerContentItemContainer
              key={'digitalContent'}
              dataId={'digitalContent'}
              style={styles.bottomSpace}
            >
              <DesignerDigitalContent
                id={line.id}
                canDelete={true}
                commentsEnabled={line.commentsEnabled}
                commentsGuidance={line.commentsGuidance}
                description={line.description}
                files={line.files}
                isEnabled={line.isEnabled}
                onChange={designerOptions.onChange}
                onEventOccurred={designerOptions.onEventOccurred}
                onFileDelete={designerOptions.onFileDelete}
                onFileRejected={designerOptions.onFileRejected}
                onFileUpload={designerOptions.onFileUpload}
                rejections={line.rejections}
                showBoundaryLines={designerOptions.showBoundaryLines}
                showBullet={designerOptions.showBullet}
                style={designerOptions.style}
                title={line.title}
                validationError={line.validationError}
              />
            </FormDesignerContentItemContainer>
          );
      })}
    </>
  );
};

const styles = {
  add: css({
    padding: '8px 0 12px 23px',
  }),
  topSpace: css({
    padding: '96px 0 0 23px',
  }),
  bottomSpace: css({
    padding: '0 0 48px 23px',
  }),
  leftSpace: css({
    padding: '0 0 0 23px',
  }),
};
