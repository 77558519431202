import { StateObservable, ofType } from 'redux-observable';
import { Observable, concatAll, from, mergeMap } from 'rxjs';
import { PAGE_SIZE_FULL_GRID } from '../../common/constants.ts';
import { componentParsers } from '../../parse/index.ts';
import { IDashboardCommentsList } from '../../types.ts';
import {
  cacheTemplate,
  initializeChartTools,
  initializeComponent,
  initializeGridTools,
  loadDashboardComponent,
  loadHeader,
} from '../actions.ts';
import {
  GlobalDashboardsState,
  IDashboardActionTemplateLoaded,
} from '../types.ts';

export function initComponentsEpic(
  action$: Observable<IDashboardActionTemplateLoaded>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/TEMPLATE_LOADED'),
    mergeMap(async (action) => {
      const { template, loadedSingleComponentId, clickThroughGrid } =
        action.payload;

      const module = state$.value.tenantState.tenant.module;

      const components = componentParsers(
        template.definition,
        loadedSingleComponentId,
      );

      const loadAll = !clickThroughGrid && !loadedSingleComponentId;

      const singleComponent =
        !clickThroughGrid && loadedSingleComponentId
          ? (components.find(
              ({ componentType }) => componentType === 'COMMENTS_LIST',
            ) as IDashboardCommentsList)
          : undefined;

      const initComponentsActions = components.map((component) =>
        initializeComponent(component, template.id),
      );

      const initGridToolsAction = loadAll ? [initializeGridTools(module)] : [];
      const initChartToolsAction = loadAll
        ? [initializeChartTools(module)]
        : [];
      const cacheTemplateAction = loadAll ? [cacheTemplate(template)] : [];
      const loadHeaderAction = loadAll ? [loadHeader()] : [];

      const loadSingleComponentAction =
        loadedSingleComponentId && singleComponent
          ? [
              loadDashboardComponent(
                singleComponent.componentType,
                singleComponent.id,
                singleComponent.orderBy,
                false,
                PAGE_SIZE_FULL_GRID,
              ),
            ]
          : [];

      return from([
        ...cacheTemplateAction,
        ...initComponentsActions,
        ...initChartToolsAction,
        ...initGridToolsAction,
        ...loadHeaderAction,
        ...loadSingleComponentAction,
      ]);
    }),
    concatAll(),
  );
}
