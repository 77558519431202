import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SheetScheduleType =
  | 'INSTANCE_TIME_SCHEDULES'
  | 'GOAL_SCHEDULES'
  | 'DISTRIBUTION_LIST';

export type SheetSchedulesState = {
  selectedScheduleType: SheetScheduleType;
};

const DEFAULT_STATE: SheetSchedulesState = {
  selectedScheduleType: 'INSTANCE_TIME_SCHEDULES',
};

const slice = createSlice({
  name: 'libs/shell/sheetSchedules',
  initialState: DEFAULT_STATE,
  reducers: {
    selectScheduleType(state, action: PayloadAction<SheetScheduleType>) {
      state.selectedScheduleType = action.payload;
    },
  },
});

export const { selectScheduleType } = slice.actions;
export const reducer = slice.reducer;
export type State = SheetSchedulesState;
