import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import {
  queryBuilder,
  IChipData,
  QueryFocusTarget,
  IQuery,
  IQuerySelection,
} from '../../api/index.ts';
import {
  IQueryFocusAction,
  IQueryBlurAction,
  IQueryEnableAction,
  IQueryDisableAction,
  IQueryModuleChangedFromSheetAction,
  INextQueryAction,
  IQueryChipAddedExternally,
  IQueryChipRemovedExternally,
  IQueryChipStartedEditingAction,
  IQueryChipStoppedEditingAction,
  IQueryReplaceChipsAction,
  IQueryModuleChangedFromUrlAction,
} from './types.ts';

export * from './actions.dropdown.ts';

export function focus(target: QueryFocusTarget = 'INPUT'): IQueryFocusAction {
  return {
    type: 'ui.shell/query/FOCUS',
    payload: { target },
  };
}

export function blur(): IQueryBlurAction {
  return {
    type: 'ui.shell/query/BLUR',
    payload: {},
  };
}

export function enable(): IQueryEnableAction {
  return {
    type: 'ui.shell/query/ENABLE',
    payload: {},
  };
}

export function disable(): IQueryDisableAction {
  return {
    type: 'ui.shell/query/DISABLE',
    payload: {},
  };
}

export const queryChangedFromSheet = (
  query: IQuery,
  module: ModuleType,
  source: IQueryModuleChangedFromSheetAction['payload']['source'],
): IQueryModuleChangedFromSheetAction => ({
  type: 'ui.shell/query/MODULE_CHANGED_FROM_SHEET',
  payload: { query, module, source },
});

export function next(
  changeSource: INextQueryAction['payload']['changeSource'],
  query: IQuery,
  selection?: IQuerySelection,
  forceRefresh?: boolean,
  eventName?: string,
): INextQueryAction {
  return {
    type: 'ui.shell/query/NEXT',
    payload: {
      query,
      selection,
      url: queryBuilder.toUrl(query),
      changeSource: changeSource || 'CODE',
      forceRefresh,
      eventName,
    },
  };
}

export function chipAddedExternally(
  chipData: IChipData,
  shouldRunQuery?: boolean,
  eventName?: string,
): IQueryChipAddedExternally {
  return {
    type: 'ui.shell/query/ADD_CHIP',
    payload: {
      newChip: { ...chipData },
      shouldRunQuery,
      eventName,
    },
  };
}

export function chipRemovedExternally(
  shouldRunQuery?: boolean,
  eventName?: string,
): IQueryChipRemovedExternally {
  return {
    type: 'ui.shell/query/REMOVE_CHIP',
    payload: { shouldRunQuery, eventName },
  };
}

export function chipStartedEditing(
  index: number,
  chip: IChipData,
): IQueryChipStartedEditingAction {
  return {
    type: 'ui.shell/query/CHIP_STARTED_EDITING',
    payload: { index, chip },
  };
}
export function chipStoppedEditing(
  index: number,
  chip: IChipData,
): IQueryChipStoppedEditingAction {
  return {
    type: 'ui.shell/query/CHIP_STOPPED_EDITING',
    payload: { index, chip },
  };
}

export const replaceChips = (
  chips: IChipData[],
  replaceModuleChip = true,
): IQueryReplaceChipsAction => ({
  type: 'ui.shell/query/REPLACE_CHIPS',
  payload: { chips, replaceModuleChip },
});

export const moduleChangedFromUrl = (
  query: IQuery,
  module: ModuleType,
): IQueryModuleChangedFromUrlAction => ({
  type: 'ui.shell/query/MODULE_CHANGED_FROM_URL',
  payload: { query, module, source: 'URL' },
});
