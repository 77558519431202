import { from, Observable, map, filter, concatAll } from 'rxjs';
import { IShellAction, ShellGlobalState } from '../types.ts';
import { logout } from '../app/actions.ts';
import {
  next,
  chipRemovedExternally,
  queryChangedFromSheet,
} from './actions.ts';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import {
  IQueryChipAddedExternally,
  IQueryChipRemovedExternally,
  INextQueryAction,
  IQueryReplaceChipsAction,
} from './types.ts';
import {
  ISheetChangeModuleAction,
  IRemoveSheetAction,
} from '../sheets/types.ts';

export const epics = combineEpics<IShellAction, IShellAction, ShellGlobalState>(
  nextQueryEpic,
  queryOnChipsReplacedEpic,
  changeModuleChipFromSheetEpic,
  removeChipOnRemoveSheetEpic,
  logoutEpic,
);

/**
 * When the query builder is updated externally, issue a `next` to
 * load/unload sheets to reflect the externally updated query builder.
 */
function nextQueryEpic(
  action$: Observable<IQueryChipAddedExternally | IQueryChipRemovedExternally>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/ADD_CHIP', 'ui.shell/query/REMOVE_CHIP'),
    filter((action) => action.payload.shouldRunQuery === true),
    map(({ payload: { eventName } }) => {
      const query = state$.value.query.query;
      return next('CODE', query, undefined, undefined, eventName);
    }),
  );
}

function queryOnChipsReplacedEpic(
  action$: Observable<IQueryReplaceChipsAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/REPLACE_CHIPS'),
    map(() => {
      const query = state$.value.query.query;
      return next('CODE', query, undefined, undefined);
    }),
  );
}
/**
 * Changes the module in the query builder when the change is launched from within a sheet.
 */
function changeModuleChipFromSheetEpic(
  action$: Observable<ISheetChangeModuleAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/CHANGE_MODULE'),
    map((action) =>
      from([
        next('CODE', state$.value.query.query),
        queryChangedFromSheet(
          state$.value.query.query,
          action.payload.to,
          action.payload.source,
        ),
      ]),
    ),
    concatAll(),
  );
}

function removeChipOnRemoveSheetEpic(action$: Observable<IRemoveSheetAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/REMOVE_SHEET'),
    map(() => chipRemovedExternally(true)),
  );
}

function logoutEpic(action$: Observable<INextQueryAction>) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    filter((action) =>
      Boolean(
        (action.payload.query.chips || []).find(
          (chip) => chip.type === 'logout',
        ),
      ),
    ),
    map(logout),
  );
}
