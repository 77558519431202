import {
  ISheetAuditLogEntry,
  ISheetAuditLogEntryAggregate,
} from '@seeeverything/ui.shell/src/redux/audit/auditSlice.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  FormatString,
  TenantLocale,
} from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import gql from 'graphql-tag';
import moment from 'moment';
import { init, isNil, last } from 'ramda';
import {
  formatDateString,
  formatPercentageString,
  formatTimeString,
} from './util.ts';

type Node = {
  actionType: AuditEventTypes;
  actionedAt: string;
  actionedBy: { id: string; name: string };
  payload: { key: string; value: string }[];
};

type KeyValueArray = Array<{ key: string; value?: string }>;

export async function formInstanceAuditLog(
  client: IGraphQLClient,
  instanceId: string,
  locale: TenantLocale,
) {
  const response = await client.query<{
    forms: { formInstanceAuditHistory: { nodes: Node[] } };
  }>({
    query: gql`
      query FormInstanceAuditLog($instanceId: ID!) {
        forms {
          formInstanceAuditHistory(id: $instanceId) {
            nodes {
              actionType
              actionedAt
              actionedBy {
                id
                name
              }
              payload {
                key
                value
              }
            }
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
    variables: { instanceId },
  });

  const auditRecords = response.data.forms.formInstanceAuditHistory.nodes;

  /**
   * Aggregation of 'AnswerCreated' actions.
   *
   * All actions are returned in arrays, but only arrays for groups will have multiple elements.
   * Grouped actions are consecutive 'AnswerCreated' with the same user.
   */
  const nodeGroups = auditRecords.reduce((acc, node) => {
    const prevGroup = last(acc) || [];
    const prevNode = last(prevGroup);

    const includeInAggregate =
      prevNode &&
      isAnswerAction(prevNode.actionType) &&
      isAnswerAction(node.actionType) &&
      prevNode.actionedBy.id === node.actionedBy.id &&
      moment(prevNode.actionedAt).isSame(node.actionedAt, 'day');

    return includeInAggregate
      ? [...init(acc), [...prevGroup, node]]
      : [...acc, [node]];
  }, [] as Node[][]);

  const buildMessage = (
    actionType: AuditEventTypes,
    properties: KeyValueArray = [],
  ) => {
    const formatter = locale.auditing.forms.instance[actionType];
    const args = toFormatArgs(locale, properties);
    return (
      (formatter && tryStringFormat(formatter, actionType, args)) ||
      str.humanize(actionType)
    );
  };

  return nodeGroups.map((group) =>
    group.length === 1 && !isAnswerAction(group[0].actionType)
      ? formatLogEntry(group[0], buildMessage)
      : formatAggregateLogEntry(group, buildMessage),
  );
}

const isAnswerAction = (actionType: string) =>
  [
    'AnswerCreated',
    'AnswerValueChanged',
    'AnswerIssueCreated',
    'AnswerIssueClassificationsChanged',
    'AnswerIssueNotesChanged',
    'AnswerIssueArchived',
    'AnswerIssueCoachingConversationUpdated',
    'AnswerIssueFutureCoachingUpdated',
    'AnswerIssueCauseUpserted',
    'AnswerIssueCauseDeleted',
  ].includes(actionType);

const isMarkdownAction = (actionType: string) =>
  actionType === 'InstanceAppealResponseSet';

const formatLogEntry = (
  node: Node,
  buildMessage: MessageBuilder,
): ISheetAuditLogEntry => ({
  ...node,
  kind: isMarkdownAction(node.actionType) ? 'markdown' : 'entry',
  action: buildMessage(node.actionType, payloadWithLogEntryProperties(node)),
});

const formatAggregateLogEntry = (
  nodes: Node[],
  buildMessage: MessageBuilder,
): ISheetAuditLogEntryAggregate => ({
  kind: 'aggregate',
  action: buildMessage('FormUpdated'),
  actionedBy: nodes[0].actionedBy,
  actionedAt: {
    end: nodes[0].actionedAt,
    start: last(nodes).actionedAt,
  },
  entries: nodes.map((node) => formatLogEntry(node, buildMessage)),
});

type AuditEventTypes = keyof TenantLocale['auditing']['forms']['instance'];

type MessageBuilder = (
  actionType: AuditEventTypes,
  properties?: KeyValueArray,
) => string;

const tryStringFormat = (
  formatter: FormatString,
  actionType: string,
  args: any,
) => {
  try {
    const format = typeof formatter === 'string' ? formatter : formatter(args);
    return str.format(format, args);
  } catch (error) {
    log.error(
      `Unable to format Audit Log message of type '${actionType}'.`,
      error,
    );
  }
};

const toFormatArgs = (locale: TenantLocale, properties: KeyValueArray) =>
  properties.reduce(
    (acc, { key, value }) =>
      isNil(value) ? acc : { ...acc, [key]: toDisplayValue(key, value) },
    { locale } as { [key: string]: string | {} },
  );

// Adds common log-entry properties to payload.
const payloadWithLogEntryProperties = (node: Node) => [
  ...node.payload,
  { key: 'actionedByName', value: node.actionedBy.name },
  { key: 'actionedAtTime', value: node.actionedAt },
  {
    key: 'computedValue',
    value:
      node.payload.find(({ key }) => key === 'displayValue')?.value ||
      node.payload.find(({ key }) => key === 'value')?.value,
  },
];

const toDisplayValue = (property: string, value: string) => {
  if (property === 'dueBy') return formatDateString(value, false);
  if (property === 'actionedAtTime') return formatTimeString(value);
  if (property === 'score') {
    const scoreNumber = Number(value);
    if (isNaN(scoreNumber)) return value;
    return formatPercentageString(scoreNumber / 100, 2);
  }

  return value;
};
