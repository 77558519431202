import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { GraphQLFormattedError } from 'graphql';
import { IDesignerDraft } from '../model/types.ts';

type DeletedTemplatePayload = {
  id: string;
  name: string;
};

type DeleteTemplatePayload = {
  id: string;
  deletePublishedTemplate: boolean;
};

type DeleteTemplateFailedPayload = {
  id: string;
  name: string;
  graphQLErrors?: GraphQLFormattedError[];
};

type OpenDraftPayload = {
  id: string;
  name: string;
};

type DesignerTemplate = {
  id: string;
  name: string;
  category: string;
  status: 'Draft' | 'Published';
  publishedTemplateId?: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
};

export type FormsDesignerReduxViewTemplatesState = {
  templates: DesignerTemplate[];
  templateLoadError?: string;
  isLoading?: boolean;
  popup?: string;
  orderBy: OrderBy;
};

const DEFAULT_STATE: FormsDesignerReduxViewTemplatesState = {
  templates: [],
  orderBy: {
    fieldName: 'updatedAt',
    direction: 'Descending',
  },
};

const slice = createSlice({
  name: 'libs/formsDesigner/templates/view',
  initialState: DEFAULT_STATE,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteTemplate(state, action: PayloadAction<DeleteTemplatePayload>) {
      state.popup = undefined;
    },
    deleteTemplateFailed(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<DeleteTemplateFailedPayload>,
    ) {
      return state;
    },
    deletedTemplate(state, action: PayloadAction<DeletedTemplatePayload>) {
      state.templates = state.templates.filter(
        (template) => template.id !== action.payload.id,
      );
    },
    load(state) {
      state.templateLoadError = undefined;
      state.isLoading = true;
      state.popup = undefined;
    },
    loadFailed(state, action: PayloadAction<string>) {
      state.templateLoadError = action.payload;
      state.isLoading = false;
      state.templates = [];
    },
    loaded(state, action: PayloadAction<IDesignerDraft[]>) {
      state.templateLoadError = undefined;
      state.isLoading = false;
      state.popup = undefined;
      state.templates = action.payload;
    },
    hideTemplateRowPopup(state) {
      state.popup = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openDraft(state, action: PayloadAction<OpenDraftPayload>) {
      state.popup = undefined;
    },
    showTemplateRowPopup(state, action: PayloadAction<string>) {
      state.popup = action.payload;
    },
    sortTemplates(state, action: PayloadAction<OrderBy>) {
      state.orderBy = action.payload;
    },
  },
});

export const {
  deletedTemplate,
  deleteTemplate,
  deleteTemplateFailed,
  hideTemplateRowPopup,
  load,
  loaded,
  loadFailed,
  openDraft,
  showTemplateRowPopup,
  sortTemplates,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormsDesignerReduxViewTemplatesState;
