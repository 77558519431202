import { combineEpics, ofType } from 'redux-observable';
import { Observable, map } from 'rxjs';
import { IAppShowChangePasswordDialog } from '@seeeverything/ui.shell/src/redux/app/types.ts';
import {
  IShellAction,
  IShellState,
} from '@seeeverything/ui.shell/src/redux/types.ts';
import { showModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { ChangePasswordInAppContainer } from '@seeeverything/ui.shell/src/components/ChangePasswordInApp/ChangePasswordInAppContainer.tsx';

export const epics = combineEpics<IShellAction, IShellAction, IShellState>(
  showChangePasswordInAppDialog,
);

function showChangePasswordInAppDialog(
  action$: Observable<IAppShowChangePasswordDialog>,
) {
  return action$.pipe(
    ofType('ui.shell/app/SHOW_CHANGE_PASSWORD_DIALOG'),
    map(() =>
      showModalDialog({
        content: <ChangePasswordInAppContainer />,
        width: 450,
      }),
    ),
  );
}
