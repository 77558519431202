/** @jsxImportSource @emotion/react */
import React from 'react';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { DesignerDropdown } from '../DesignerDropdown/DesignerDropdown.tsx';
import { DesignerDatePicker } from '../DesignerDatePicker/DesignerDatePicker.tsx';
import {
  DesignerFormParticipant,
  IDesignerCommonProps,
  IFormDesignerValidationError,
} from '../types.ts';
import { getValidationError } from '../util.ts';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { css } from '@emotion/react';

export interface IDesignerStandardHeadingProps extends IDesignerCommonProps {
  reportingDateLabel?: string;
  sectionTitle?: string;
  dueDateLabel?: string;
  subject?: DesignerFormParticipant;
  reviewer?: DesignerFormParticipant;
  dropdownTypes?: ISelectionListItem[];
  validationError?: IFormDesignerValidationError;
}

export class DesignerStandardHeading extends React.Component<
  IDesignerStandardHeadingProps,
  {}
> {
  public render() {
    const defaultSubject: DesignerFormParticipant = {
      kind: 'people',
      label: 'Coachee',
      canChangeKind: true,
    };

    const defaultReviewer: DesignerFormParticipant = {
      kind: 'people',
      label: 'Coach',
      canChangeKind: false,
    };

    const {
      sectionTitle = 'Getting Started',
      reportingDateLabel = 'Review Date',
      dueDateLabel = 'Due Date',
      reviewer = defaultReviewer,
      subject = defaultSubject,
      dropdownTypes = [],
      showBoundaryLines,
      showBullet = true,
      onChange,
      onEventOccurred,
      validationError,
    } = this.props;

    return (
      <div css={styles.base}>
        <DesignerSection
          id={'header-section-title'}
          title={sectionTitle}
          style={styles.fullWidth}
          canDelete={false}
          showBoundaryLines={showBoundaryLines}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          showBullet={showBullet}
          error={getValidationError(validationError?.fields, 'sectionTitle')}
        />
        <DesignerDatePicker
          id={'reportingDate'}
          title={reportingDateLabel}
          titleLabel={'Reporting Date Label'}
          style={styles.halfWidth}
          canDelete={false}
          showBoundaryLines={showBoundaryLines}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          showBullet={showBullet}
          error={getValidationError(
            validationError?.fields,
            'reportingDateLabel',
          )}
        />
        <DesignerDatePicker
          id={'dueDate'}
          title={dueDateLabel}
          titleLabel={'Due Date Label'}
          style={styles.halfWidth}
          canDelete={false}
          showBoundaryLines={showBoundaryLines}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          showBullet={false}
          error={getValidationError(validationError?.fields, 'dueDateLabel')}
        />
        <DesignerDropdown
          id={'assignedTo'}
          dropdownLabel={'Reviewer Label'}
          inputProps={reviewer}
          style={styles.halfWidth}
          canDelete={false}
          showBoundaryLines={showBoundaryLines}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          showBullet={showBullet}
          error={getValidationError(validationError?.fields, 'reviewer')}
        />
        <DesignerDropdown
          id={'subject'}
          dropdownLabel={'Subject Label'}
          inputProps={subject}
          style={styles.halfWidth}
          canDelete={false}
          dropdownTypes={dropdownTypes}
          showBoundaryLines={showBoundaryLines}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          showBullet={false}
          error={getValidationError(validationError?.fields, 'subject')}
        />
      </div>
    );
  }
}

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }),
  fullWidth: css({
    flex: '0 0 100%',
  }),
  halfWidth: css({
    flex: '0 0 49.5%',
  }),
};
