export const NotFoundErrorKind = 'ENTITY_NOT_FOUND';
export type NotFoundErrorEntityType =
  | 'people'
  | 'person'
  | 'team'
  | 'schedules';
/**
 * Represents an error where an entity cannot be loaded, either because no such
 * entity exists or the requester does not have permission to retrieve it.
 */
export class NotFoundError extends Error {
  public readonly kind = NotFoundErrorKind;

  public entity: NotFoundErrorEntityType;

  constructor(entityType: NotFoundErrorEntityType, entityId?: string) {
    super(message(entityType, entityId));
    this.entity = entityType;
  }
}

const message = (entityType: string, entityId?: string) => {
  return entityId
    ? `Unable to load entity of type '${entityType}' with id '${entityId}.`
    : `Unable to load entity of type '${entityType}'.`;
};
