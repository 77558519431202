/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormsBulkUploadFileValidationResult,
  FormsBulkUploadValidateFileUploadRow,
  IFormsBulkUploadImportFileValidationState,
  RowStatus,
} from '@seeeverything/ui.forms/src/redux/form-bulk-upload/import-file-validation/types.ts';
import {
  IFormFile,
  IFormRejectedFile,
} from '@seeeverything/ui.forms/src/redux/form-file/types.ts';
import { RadioGroupEventHandler } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/index.ts';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { MessageBar } from '@seeeverything/ui.primitives/src/components/MessageBar/MessageBar.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { TabStrip } from '@seeeverything/ui.primitives/src/components/TabStrip/TabStrip.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useMemo } from 'react';
import {
  bulkUploadImportRowHasStatus,
  toFormatArgs,
  tryStringFormat,
} from '../../utils/utils.ts';
import { BulkUploadFileResults } from '../BulkUploadFileResults/BulkUploadFileResults.tsx';
import { UploadTab } from './components/UploadTab.tsx';
import { WorkflowTab } from './components/WorkflowTab.tsx';

export interface IBulkUploadFileImportDialogProps {
  file?: IFormFile;
  filteredRows?: FormsBulkUploadValidateFileUploadRow[];
  isSpinning: boolean;
  locale: TenantLocale;
  isValidationResultLoaded?: boolean;
  onFileRejected?: (files: File[], maxSize?: number) => void;
  onFileUpload: (files?: File[]) => void;
  onWorkflowChange?: RadioGroupEventHandler;
  rejections?: IFormRejectedFile[];
  selectedTabId: IFormsBulkUploadImportFileValidationState['selectedTabId'];
  statusFilters: RowStatus[];
  validationResult?: FormsBulkUploadFileValidationResult;
  onCreateAutomatedActionsChange: (to: boolean) => void;
  createAutomatedActions: boolean;
  includeAutomatedActions: boolean;
}

/**
 * The dialog content component for uploading and validating a Bulk Forms Processor file
 */
export const BulkUploadFileImportDialog: React.FC<
  IBulkUploadFileImportDialogProps
> = ({
  file,
  filteredRows,
  isSpinning,
  locale,
  onFileRejected,
  onFileUpload,
  rejections,
  selectedTabId,
  statusFilters,
  validationResult,
  onWorkflowChange,
  isValidationResultLoaded,
  onCreateAutomatedActionsChange,
  createAutomatedActions,
  includeAutomatedActions,
}) => {
  const tabs = [
    {
      id: 'UPLOAD',
      label: 'Upload',
      isEnabled: !file,
    },
    {
      id: 'WORKFLOW',
      label: 'Workflow',
      isEnabled: selectedTabId === 'WORKFLOW',
    },
    {
      id: 'REVIEW',
      label: 'Review',
      isEnabled: selectedTabId === 'REVIEW',
    },
  ];

  const elError =
    selectedTabId === 'REVIEW' &&
    getValidationMessages(locale, file, validationResult);

  const statusCounts = useMemo(() => {
    const rows = validationResult?.rows ?? [];

    return {
      error: rows.filter((row) => row.status === 'Error').length,
      success: rows.filter((row) => row.status === 'Success').length,
      warning: rows.filter((row) => row.status === 'Warning').length,
    };
  }, [validationResult]);

  const elUploadTab = selectedTabId === 'UPLOAD' && (
    <UploadTab
      files={file ? [file] : []}
      onFileRejected={onFileRejected}
      onFileUpload={onFileUpload}
      rejections={rejections}
      locale={locale}
    />
  );

  const elWorkflowTab = selectedTabId === 'WORKFLOW' && (
    <WorkflowTab
      locale={locale}
      onChange={onWorkflowChange}
      onCreateAutomatedActionsChange={onCreateAutomatedActionsChange}
      createAutomatedActions={createAutomatedActions}
      includeAutomatedActions={includeAutomatedActions}
    />
  );

  const elReviewTab = selectedTabId === 'REVIEW' && (
    <div>
      {!isValidationResultLoaded && (
        <div css={styles.loadingContainer}>
          <ContentPlaceholder kind={'LIST'} emptyText={''} hintText={''} />
        </div>
      )}
      {validationResult && (
        <BulkUploadFileResults
          locale={locale}
          rows={filteredRows}
          showFilters={true}
          statusCounts={statusCounts}
          statusFilters={statusFilters}
        />
      )}
    </div>
  );

  return (
    <div css={styles.base}>
      {isSpinning && (
        <div css={styles.loadingMask}>
          <Spinner center={true} />
        </div>
      )}
      <div css={isSpinning ? styles.loadingFade : undefined}>
        <div css={styles.header}>
          <div css={styles.title}>
            <Text
              size={48}
              color={color.format(-0.2)}
              weight={FontWeight.light}
            >
              {`Upload ${str.plural(locale.label.form)}`}
            </Text>
          </div>
        </div>
        <div css={styles.body}>
          <div css={styles.tabContent}>
            <div css={styles.tabsContainer}>
              <TabStrip tabs={tabs} selectedId={selectedTabId} />
            </div>
            {elError}
            {elUploadTab}
            {elWorkflowTab}
            {elReviewTab}
          </div>
        </div>
      </div>
    </div>
  );
};

const getValidationMessages = (
  locale: TenantLocale,
  file?: IFormFile,
  validationResult?: FormsBulkUploadFileValidationResult,
) => {
  if (!file || !validationResult) return undefined;

  const rowCount = validationResult.rows.length;

  if (
    !validationResult.errors.length &&
    !hasErrors(validationResult) &&
    !hasWarnings(validationResult.rows)
  ) {
    const formLabel = str.plural(locale.label.form, rowCount)?.toLowerCase();
    return (
      <div css={styles.messageBarBase}>
        <MessageBar
          type={'Success'}
          message={`Success: File can be loaded successfully. Click 'Import' to continue, and ${rowCount} ${formLabel} will be created.`}
        />
      </div>
    );
  }

  const elDuplicateRowMessage = getDuplicateRowMessages(validationResult.rows);
  const elHiddenRowMessage = getHiddenFieldsMessages(validationResult.rows);

  return (
    <div css={styles.messageBarBase}>
      <MessageBar
        type={isFailedUpload(validationResult) ? 'Error' : 'Warning'}
        message={getMessageBarText(locale, validationResult)}
      />
      {elDuplicateRowMessage}
      {elHiddenRowMessage}
    </div>
  );
};

const hasErrors = (
  validationResult: FormsBulkUploadFileValidationResult,
): boolean => {
  const rowErrors = (row: FormsBulkUploadValidateFileUploadRow) =>
    row.errors.length > 0;

  return (
    validationResult.errors.length > 0 ||
    (validationResult.rows && validationResult.rows.some(rowErrors))
  );
};

const hasWarnings = (rows: FormsBulkUploadValidateFileUploadRow[]): boolean => {
  const rowWarnings = (row: FormsBulkUploadValidateFileUploadRow) =>
    row.status === 'Warning';

  return rows && rows.some(rowWarnings);
};

const isFailedUpload = (
  validationResult: FormsBulkUploadFileValidationResult,
): boolean =>
  validationResult.errors.length > 0 ||
  validationResult?.rows.every((row) =>
    bulkUploadImportRowHasStatus(row, 'Error'),
  );

const getMessageBarText = (
  locale: TenantLocale,
  validationResult: FormsBulkUploadFileValidationResult,
): string => {
  if (isFailedUpload(validationResult))
    return `File Failure: ${toErrorText(locale, validationResult)}`;

  return 'Partial Success: File can be loaded, however there are errors. Please review the results below.';
};

const getDuplicateRowMessages = (
  rows: FormsBulkUploadValidateFileUploadRow[],
) => {
  const warningCount = rows.filter((row) =>
    row.errors.some((error) => error.code === 'DUPLICATED_PREVIOUS_IMPORT'),
  ).length;

  if (warningCount === 0) return undefined;

  return (
    <div css={styles.messageBarWarning}>
      <MessageBar
        type={'Warning'}
        message={`Warning: ${warningCount} out of ${rows.length} rows appear to be a duplicate of a previously loaded file.`}
      />
    </div>
  );
};

const getHiddenFieldsMessages = (
  rows: FormsBulkUploadValidateFileUploadRow[],
) => {
  const hiddenRows = rows.filter((row) =>
    row.errors.some((error) => error.code === 'ANSWER_QUESTION_HIDDEN'),
  );

  if (hiddenRows.length === 0) return undefined;

  return (
    <div css={styles.messageBarWarning}>
      <MessageBar
        type={'Warning'}
        message={`Warning: ${hiddenRows.length} out of ${rows.length} rows appear to be skipped.`}
      />
    </div>
  );
};

const toErrorText = (
  locale: TenantLocale,
  validationResult: FormsBulkUploadFileValidationResult,
): string => {
  if (
    !validationResult.errors.length &&
    validationResult?.rows.every((row) =>
      bulkUploadImportRowHasStatus(row, 'Error'),
    )
  ) {
    return 'All rows have errors.';
  }

  const { errors } = validationResult;

  const errorOutcomes = errors.map((error) => {
    const localizedOutcome = locale.forms.bulkUpload[error.code];
    const args = toFormatArgs(locale, error.payload);

    return (
      (localizedOutcome &&
        tryStringFormat(error.code, localizedOutcome, args)) ??
      str.humanize(error.code)
    );
  });

  return errorOutcomes.length ? errorOutcomes.join(' ') : '';
};

const styles = {
  base: css({
    overflow: 'hidden',
    width: '100%',
    flex: '1 1 auto',
    position: 'relative',
  }),
  loadingMask: css({
    position: 'absolute',
    display: 'flex',
    inset: 0,
    zIndex: 1,
  }),
  loadingFade: css({
    opacity: 0.5,
    pointerEvents: 'none',
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    padding: '0 25px 25px 25px',
  }),
  header: css({
    height: 96,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  }),
  messageBarBase: css({
    padding: '15px 0',
  }),
  messageBarWarning: css({
    paddingTop: '10px',
  }),
  tabContent: css({
    flex: '1 1 auto',
    maxWidth: '1085px',
    height: '100%',
  }),
  tabsContainer: css({
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    background: color.format(1),
    width: '100%',
    left: 0,
    padding: '0 20px',
  }),
  title: css({
    flex: '1 1 auto',
    margin: '20px 0 0 34px',
  }),
  loadingContainer: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 100,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
};
