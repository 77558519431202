import { combineEpics, StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { IShellAction, IShellState, ShellGlobalState } from '../types.ts';
import { IAppReturnHome } from './types.ts';
import { next } from '../query/actions.ts';

export const epics = combineEpics<IShellAction, IShellAction, IShellState>(
  returnHomeEpic,
);

function returnHomeEpic(
  action$: Observable<IAppReturnHome>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/app/RETURN_HOME'),
    filter(() => {
      const authenticatedUser =
        state$.value.tenantState.tenant.authenticatedUser;
      const configuration = state$.value.tenantState.tenant?.configuration;
      const module = state$.value.tenantState.tenant?.module;

      return Boolean(authenticatedUser && configuration?.modules?.[module]);
    }),
    map(() => {
      const { id, firstName, lastName } =
        state$.value.tenantState.tenant.authenticatedUser;

      return next('CODE', {
        chips: [
          { type: 'people', value: id, label: `${firstName} ${lastName}` },
        ],
        filter: '',
      });
    }),
  );
}
