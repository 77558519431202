/** @jsxImportSource @emotion/react */
import { FormTemplates } from '@seeeverything/ui.forms.designer/src/components/FormTemplates/FormTemplates.tsx';
import { formsDesignerViewTemplatesSlice } from '@seeeverything/ui.forms.designer/src/redux/index.ts';
import { DataGridFilter } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export const SheetFormTemplatesContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const isSpinning = useShellSelector(
    (state) => state.formsDesignerViewTemplates.isLoading,
  );

  const templates = useShellSelector(
    (state) => state.formsDesignerViewTemplates.templates,
  );

  const menuPopupTemplateId = useShellSelector(
    (state) => state.formsDesignerViewTemplates.popup,
  );

  const orderBy = useShellSelector(
    (state) => state.formsDesignerViewTemplates.orderBy,
  );

  const filters = useMemo<DataGridFilter[]>(
    () => [
      {
        columnId: orderBy.fieldName,
        sort: orderBy.direction === 'Ascending' ? 'ASC' : 'DESC',
      },
    ],
    [orderBy],
  );

  const openDesignerTemplate = useCallback(
    (id: string) => {
      const template = templates.find((t) => t.id === id);
      if (!template) return;
      if (template.status !== 'Draft') return;
      dispatch(
        formsDesignerViewTemplatesSlice.openDraft({ id, name: template.name }),
      );
    },
    [dispatch, templates],
  );

  const deleteTemplate = useCallback(
    (id: string) =>
      dispatch(
        formsDesignerViewTemplatesSlice.deleteTemplate({
          id,
          deletePublishedTemplate: true,
        }),
      ),
    [dispatch],
  );

  const showPopup = useCallback(
    (id: string) =>
      dispatch(formsDesignerViewTemplatesSlice.showTemplateRowPopup(id)),
    [dispatch],
  );

  const hidePopup = useCallback(
    () => dispatch(formsDesignerViewTemplatesSlice.hideTemplateRowPopup()),
    [dispatch],
  );

  const reloadWithSort = useCallback(
    (columnId: string, sort: 'ASC' | 'DESC') =>
      dispatch(
        formsDesignerViewTemplatesSlice.sortTemplates({
          fieldName: columnId,
          direction: sort === 'ASC' ? 'Ascending' : 'Descending',
        }),
      ),
    [dispatch],
  );

  const title = useShellSelector(
    (state) => `${state.tenantState.tenant.locale.label.form} Designer`,
  );

  return (
    <SheetLayout
      isSpinning={isSpinning}
      title={title}
      leftTools={[
        {
          id: 'CREATE_FORM_TEMPLATE',
          icon: Icons.addBox,
          label: 'Create Template',
        },
        {
          id: 'IMPORT_FORM_TEMPLATE',
          icon: Icons.cloudUpload,
          label: 'Import Template',
        },
      ]}
    >
      <FormTemplates
        templates={templates}
        onOpenTemplate={openDesignerTemplate}
        onDeleteTemplate={deleteTemplate}
        onShowTemplateMenuPopup={showPopup}
        onHideTemplateMenuPopup={hidePopup}
        onColumnSort={reloadWithSort}
        menuPopupTemplateId={menuPopupTemplateId}
        filters={filters}
      />
    </SheetLayout>
  );
};
