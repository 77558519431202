import { combineEpics, StateObservable } from 'redux-observable';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import {
  GlobalFormsDesignerState,
  GlobalFormsDesignerEpicDependencies,
} from './store.ts';
import * as formsDesignerImportTemplatesSlice from './formsDesignerImportTemplatesSlice.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { Observable, filter, mergeMap } from 'rxjs';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';

export const importTemplatesEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsDesignerState
>(importNewTemplateEpic, importToOverwriteExistingTemplateEpic);

function importNewTemplateEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = { forms: { createFormTemplate: { ok: boolean } } };

  return action$.pipe(
    filter(formsDesignerImportTemplatesSlice.importTemplate.match),
    filter(
      () => state$.value.formsDesignerImportTemplates.selectedMethod === 'NEW',
    ),
    mergeMap(async () => {
      const id = uuid.generate();
      const { name, category, definitionJson } =
        state$.value.formsDesignerImportTemplates.createNewInputs;

      try {
        const response = await client.mutate<Response>({
          mutation: gql`
            mutation CreateFormTemplate(
              $id: ID!
              $definition: String!
              $name: String!
              $categoryId: ID!
            ) {
              forms {
                createFormTemplate(
                  input: {
                    formTemplateId: $id
                    name: $name
                    categoryId: $categoryId
                    definition: $definition
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: {
            id,
            name,
            categoryId: category.id.toString(),
            definition: definitionJson,
          },
        });

        if (response.data.forms.createFormTemplate.ok)
          return formsDesignerImportTemplatesSlice.importedTemplate();
      } catch (error) {
        log.error(
          new Error(
            `Error occurred while trying to import template through designer with name ${name} and id ${id} - ${error.message}`,
          ),
        );
      }

      return formsDesignerImportTemplatesSlice.importTemplateFailed(
        'Something went wrong. Please try again later.',
      );
    }),
  );
}

function importToOverwriteExistingTemplateEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = { forms: { updateFormTemplateDefinition: { ok: boolean } } };

  return action$.pipe(
    filter(formsDesignerImportTemplatesSlice.importTemplate.match),
    filter(
      () =>
        state$.value.formsDesignerImportTemplates.selectedMethod ===
        'OVERWRITE_EXISTING',
    ),
    mergeMap(async () => {
      const { existingTemplate, definitionJson } =
        state$.value.formsDesignerImportTemplates.overwriteExistingInputs;

      const id = existingTemplate.id;

      try {
        const response = await client.mutate<Response>({
          mutation: gql`
            mutation UpdateFormTemplate($id: ID!, $definition: String!) {
              forms {
                updateFormTemplateDefinition(
                  input: { formTemplateId: $id, definition: $definition }
                ) {
                  ok
                }
              }
            }
          `,
          variables: { id: existingTemplate.id, definition: definitionJson },
        });

        if (response.data.forms.updateFormTemplateDefinition.ok)
          return formsDesignerImportTemplatesSlice.importedTemplate();
      } catch (error) {
        log.error(
          new Error(
            `Error occurred while trying to import template (overwrite) through designer with id ${id} - ${error.message}`,
          ),
        );
      }

      return formsDesignerImportTemplatesSlice.importTemplateFailed(
        'Something went wrong. Please try again later.',
      );
    }),
  );
}
