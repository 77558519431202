import * as types from './types.ts';
import { reducer as dropdownReducer } from './reducer.dropdown.ts';
import { reducer as nextQueryReducer } from './reducer.nextQuery.ts';
import { IChipData } from '../../api/api.queryBuilder/types.ts';

const DEFAULT_STATE: types.IQueryState = {
  url: '/',
  isEnabled: true,
  focus: 'INPUT', // NB: Query-builder takes focus by default.
  query: {
    chips: [],
    filter: '',
  },
};

export function reducer(
  state: types.IQueryState = DEFAULT_STATE,
  action: types.IQueryAction,
): types.IQueryState {
  switch (action.type) {
    case 'ui.shell/query/DROPDOWN_SHOW':
    case 'ui.shell/query/DROPDOWN_HIDE':
    case 'ui.shell/query/DROPDOWN_LOADED':
    case 'ui.shell/query/DROPDOWN_INLINE_FILTER_CLICKED':
      return dropdownReducer(state, action);

    case 'ui.shell/query/NEXT':
      return nextQueryReducer(state, action);

    case 'ui.shell/query/FOCUS':
      return {
        ...state,
        focus:
          action.payload.target === 'INPUT'
            ? 'DROPDOWN'
            : action.payload.target,
      };

    case 'ui.shell/query/BLUR':
      return { ...state, focus: undefined };

    case 'ui.shell/query/ENABLE':
      return { ...state, isEnabled: true };

    case 'ui.shell/query/DISABLE':
      return { ...state, isEnabled: false };

    case 'ui.shell/query/ADD_CHIP': {
      const { newChip } = action.payload;
      const nextChips = [...state.query.chips, newChip];
      const nextState: types.IQueryState = {
        ...state,
        query: {
          ...state.query,
          chips: nextChips,
        },
        focus: undefined,
        url: chipsToUrl(nextChips),
        changeSource: 'CODE',
      };
      delete nextState.dropdown;

      return nextState;
    }

    case 'ui.shell/query/REPLACE_CHIPS': {
      const { chips } = action.payload;

      return {
        ...state,
        changeSource: 'CODE',
        query: {
          chips,
          filter: '',
        },
        focus: undefined,
        url: chipsToUrl(chips),
      };
    }

    case 'ui.shell/query/MODULE_CHANGED_FROM_URL': {
      const {
        query: { chips, filter },
      } = action.payload;
      return {
        ...state,
        changeSource: 'CODE',
        query: {
          chips,
          filter,
        },
        focus: undefined,
        url: chipsToUrl(chips),
        dropdown: undefined,
      };
    }

    case 'ui.shell/query/REMOVE_CHIP':
      if (state.query.chips.length > 0) {
        return {
          ...state,
          query: {
            ...state.query,
            chips: state.query.chips.slice(0, state.query.chips.length - 1),
          },
          url: state.url.substring(0, state.url.lastIndexOf('/')),
          changeSource: 'CODE',
        };
      }
      return state;

    default:
      return state;
  }
}

const chipsToUrl = (chips: IChipData[]) =>
  chips.reduce(
    (acc, chip) =>
      chip.value !== '' ? acc + `/${chip.type}:${chip.value}` : `/${chip.type}`,
    '',
  );
